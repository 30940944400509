import React, {FC} from 'react'
import { ManifestView } from './ManifestView'

const ManifestViewPage: FC = () => {
  return (
    <>
    <ManifestView className='mb-5 mb-xl-8' />
    </>
  )
}

export {ManifestViewPage}
