/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { NumericFormat } from 'react-number-format'
import { ContactDetails } from '../../../../model/models'

type Props = {
    name: string
    address: string
    contact_no: string
    searchable: boolean
    clickSearchable: (e: React.ChangeEvent<HTMLInputElement>) => void
    consigneeUpdateEventChange: (contact: ContactDetails) => void
}

const Consignee: React.FC<Props> = ({
    name,
    address,
    contact_no,
    searchable,
    clickSearchable,
    consigneeUpdateEventChange }) => {
    const [consigneeName, setConsigneeName] = useState(name)
    const [consigneeAddress, setConsigneeAddress] = useState(address)
    const [consigneeContact, setConsigneeContact] = useState(contact_no)
    const [save, setSave] = useState(searchable)

    const changeConsigneeName = (e: React.ChangeEvent<HTMLInputElement>) => {
        setConsigneeName(e.currentTarget.value);
    }

    const changeConsigneeAddress = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setConsigneeAddress(e.currentTarget.value);
    }

    const changeConsigneeContact = (e: React.ChangeEvent<HTMLInputElement>) => {
        setConsigneeContact(e.currentTarget.value);
    }

    return (
        <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true'>
            <div className='separator border-gray-200'></div>
            <div className='px-7 py-5'>
                <div className='mb-10'>
                    <label className='form-label fw-bold'>Name</label>
                    <input value={consigneeName}
                        type='text'
                        maxLength={60}
                        className='form-control form-control-solid'
                        placeholder="Consignee's name"
                        onChange={changeConsigneeName}
                    />
                </div>
                <div className='mb-10'>
                    <label className='form-label fw-bold'>Address</label>
                    <textarea
                        value={consigneeAddress}
                        maxLength={180}
                        className='form-control form-control-solid'
                        placeholder="Consignee's address"
                        onChange={changeConsigneeAddress}
                    />
                </div>
                <div className='mb-10'>
                    <label className='form-label fw-bold'>Contact #</label>
                    <NumericFormat
                        maxLength={12}
                        placeholder='Contact #'
                        value={consigneeContact}
                        valueIsNumericString
                        className='form-control form-control-solid'
                        onChange={changeConsigneeContact}
                    />
                </div>

                <div className='d-flex justify-content-end'>
                    <div className='form-switch form-switch-sm form-check-custom form-check-solid'>
                        <input
                            className='form-check-input'
                            type='checkbox'
                            value=''
                            name='notifications'
                            defaultChecked={save}
                            onChange={clickSearchable}
                        />
                        <label className='form-check-label'>Searchable? </label>
                    </div>
                    <button
                        type='button'
                        className='btn btn-sm btn-light btn-active-light-primary me-1 mx-1'
                        data-kt-menu-dismiss='true'
                    >
                        Cancel
                    </button>

                    <button type='reset' onClick={() => {
                        consigneeUpdateEventChange(
                            { id: 0, 
                              name: consigneeName, 
                              address: consigneeAddress, 
                              contact_no: consigneeContact, 
                              value: 0, 
                              label: '' })
                            }}
                        className='btn btn-sm btn-primary' data-kt-menu-dismiss='true'>
                        Apply
                    </button>
                </div>
            </div>
        </div>
    )
}

export { Consignee }