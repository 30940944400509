import moment from 'moment'
import * as Yup from 'yup'
import {AccountModel} from '../../../model/models'

export interface ICreateSOA extends AccountModel {}

const createSOASchemas = [
  Yup.object({
    account_no: Yup.number().min(1, 'The SOA # is required').required('The SOA # is required'),
    customer_id: Yup.number()
      .min(1, 'Please select customer for this statement')
      .required('Please select customer for this statement')
  }),
]

const inits: ICreateSOA = {
  account_no: Number(localStorage.getItem('soa_no_last')?.toString()!) + 1,
  description: '',
  note: '',
  statement_date: moment().toDate(),
  due_date: moment().toDate(),
  deleted: false,
  total_amount: 0,
  total_vat: 0,
  date_paid: undefined,
  total_paid: 0,
  status: 0,
  account_id: 0,
  createdby_id: 0,
  updatedby_id: 0,
  customer_id: 0,
  branch: ''
}

export {createSOASchemas, inits}
