import React, {FC} from 'react'
import { ManifestDetails } from './ManifestDetails'

const ManifestDetailsPage: FC = () => {
  return (
    <>
      <ManifestDetails className='mb-5 mb-xl-8' />
    </>
  )
}

export {ManifestDetailsPage}
