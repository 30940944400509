import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {WaybillView } from '../../../model/type'
import {WaybillItemsResponseData, WaybillResponseData, Waybills} from '../../../model/models' 

const initialWaybillViewState: WaybillView = {
  listLoading: false,
  actionsLoading: false,
  data: undefined,
  items: undefined
}

export const callTypes = {
  list: 'list',
  action: 'action',
}

export const waybillSlice = createSlice({
  name: 'waybills',
  initialState: initialWaybillViewState,
  reducers: {
    catchError: (state: WaybillView, action: PayloadAction<WaybillView>) => {
      state.error = `${action.type}: ${action.payload.error}`
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false
      } else {
        state.actionsLoading = false
      }
    },
    startCall: (state: WaybillView, action: PayloadAction<WaybillView>) => {
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true
      } else {
        state.actionsLoading = true
      }
    },
    fetched: (state: WaybillView, action: PayloadAction<Waybills>) => {
      state.listLoading = false
      state.actionsLoading = false
      state.data = action.payload
    },
    storeItems: (state: WaybillView, action: PayloadAction<WaybillItemsResponseData>) => {
      state.listLoading = false
      state.actionsLoading = false
      state.items = action.payload
    }
  },
})
  
export const {
  catchError,
  startCall,
  fetched,
  storeItems
} = waybillSlice.actions


export default waybillSlice.reducer
