/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import { KTCard, KTCardBody, KTSVG } from '../../../../_metronic/helpers'
import { ContactDetails, ManifestData, WaybillItemModel, WaybillModel } from '../../../model/models';
import { Formik, Form, Field, ErrorMessage, useFormikContext, FieldArray, FormikHelpers } from 'formik'
import DatePicker from "react-datepicker";
import { Shipper } from './dropdowns/Shipper'
import { Consignee } from './dropdowns/Consignee'
import { NumberFormatValues, NumericFormat, SourceInfo } from 'react-number-format'
import { WaybillItems } from './WaybillItems';
import { deleteWaybillItem, getWaybillById, getWaybillByNo, getWaybillItems, saveWaybillItem, updateWaybill, updateWaybillItems } from '../redux/WaybillsApi';
import Swal from 'sweetalert2'
import { useNavigate, useParams } from "react-router-dom";
import { detailsWaybillSchemas, waybill, waybillItemModel } from './helpers/WaybillDetailsHelper';
import clsx from 'clsx';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../../../setup';
import { UserModel } from '../../auth';
import { getManifestByManifestId } from '../../manifests/redux/ManifestApi';
import moment from 'moment';
import { utcToZonedTime, format, zonedTimeToUtc, formatInTimeZone, getTimezoneOffset } from 'date-fns-tz'

type Props = {
  className: string
}

const WaybillDetails: React.FC<Props> = ({ className }) => {
  const { id } = useParams() as { id: string }
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const [currentSchema] = useState(detailsWaybillSchemas[0])
  const [waybillData, setWaybillData] = useState<WaybillModel>(waybill)
  const [waybillItems, setWaybillItems] = useState<WaybillItemModel[]>(waybill.items)
  const [waybillItemsDelete, setWaybillItemsDelete] = useState<WaybillItemModel[]>()
  const [isWaybillValid, setWaybillValid] = useState(false)
  const [saveShipper, setSaveShipper] = useState<boolean>(false)
  const [saveConsignee, setSaveConsignee] = useState<boolean>(false)
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
  const branch = useSelector<RootState>(({ configs }) => configs.branchId, shallowEqual) as string
  const submit = async (values: WaybillModel, { setStatus, setSubmitting }: FormikHelpers<WaybillModel>) => {
    setLoading(true)

    values.updatedby_id = user.id
    values.item_description = values.items?.map(i => i.description).join(' / ')
    values.total_quantity = values.items?.reduce((a, v) => a = a + (v.quantity === undefined ? 0 : v.quantity), 0)
    updateWaybill(values.id, values)
      .then((response) => {
        if (response.status === 200) {
          if(waybillItemsDelete !== undefined &&  waybillItemsDelete?.length > 0) {
              waybillItemsDelete?.forEach((item) => {
                deleteWaybillItem(item.id)
              })
          }
          waybillItems.forEach((item) => {
            if (item.id > 0) {
              updateWaybillItems(item.id, item)
            } else {
              saveWaybillItem(item)
            }
          })
          setTimeout(() => {
            setLoading(false)
            Swal.fire({
              title: 'Successs!',
              text: `Waybill # ${values.waybill_no} has been successfully updated!`,
              icon: 'success',
              confirmButtonText: 'Print',
              cancelButtonText: 'Views',
              showCancelButton: true,
              customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-primary'
              }
            }).then((result) => {
              if (result.isConfirmed) {
                navigate(`/section/waybills/print/${response.data.data.id}`);
              } else if (result.dismiss === Swal.DismissReason.cancel) {
                navigate('/section/waybills/view');
              }
            })
          }, 2000)
        }
      })
      .catch((error) => {
        setLoading(false)
        setSubmitting(false)
        setStatus('Problem occured when saving your waybill.')
        Swal.fire({
          title: 'Error!',
          text: 'Problem occured when creating your waybill',
          icon: 'error',
          confirmButtonText: 'OK',
          iconColor: 'primary'
        })
        console.error(error)
      })

  }

  const [manifest, setManifest] = useState<ManifestData>()

  useEffect(() => {
    const loadWaybill = () => {
      getWaybillById(Number(id)).then((response) => {
        const waybill = response.data.data.attributes as WaybillModel;
        waybill.id = response.data.data.id;
        setTimeout(() => {
          getWaybillItems(Number(response.data.data.attributes.waybill_id)).then((result) => {
            const items = result.data.data.map((item, index) => {
              item.attributes.id = item.id
              item.attributes.line = item.attributes.line === null ? index : item.attributes.line
              return item.attributes
            })
            setWaybillItems(items);
            waybill.items = items
            setWaybillData(waybill)
          })
        }, 1000)

        if (waybill.manifest_id > 0) {
          getManifestByManifestId(waybill.manifest_id).then((result) => {
            if (result.status === 200) {
              setManifest(result.data.data[0])
            }
          })
        }
        console.log(waybill)
      })
    }
    loadWaybill()
  }, [id])

  const setDateNowChange = (e: Date) => {
    const data = { ...waybillData };
    data.date = e
    setWaybillData(data);
  }
  const setRecievedDateChange = (e: Date) => {
    const data = { ...waybillData };
    data.received_date = e;
    setWaybillData(data);
  }
  const shipperUpdateEvent = (contact: ContactDetails) => {
    const data = { ...waybillData };
    data.shipper_name = contact.name;
    data.shipper_address = contact.address;
    data.shipper_contact_no = contact.contact_no;
    setWaybillData(data);
  }
  const consigneeUpdateEvent = (contact: ContactDetails) => {
    const data = { ...waybillData };
    data.consignee = contact.name;
    data.address = contact.address;
    data.mobile = contact.contact_no;
    setWaybillData(data);
  }
  const handleStringValueUpdate = (lineNumber: number, e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const data = { ...waybillData }
    const dataItems = [...waybillItems]
    const updateItems = dataItems.map((item, index) => {
      if (item.line === lineNumber) {
        return { ...item, description: value }
      }
      return item;
    });
    const updateData = { ...data, items: updateItems }
    setWaybillItems(updateItems)
    setWaybillData(updateData)
  }
  const handleNumericValueUpdate = (line: number, values: NumberFormatValues, event: SourceInfo) => {
    const data = { ...waybillData };
    const dataItems = [...waybillItems]
    const updatedItems = dataItems.map((item, index) => {
      if (item.line === line) {
        if (event.event?.currentTarget.name === 'quantity') {
          return { ...item, quantity: values.floatValue }
        } else if (event.event?.currentTarget.name === 'weight') {
          return { ...item, weight: values.floatValue }
        } else {
          return { ...item, freight_amount: values.floatValue }
        }
      }
      return item;
    })
    setWaybillItems(updatedItems)
    const updatedData = { ...data, items: updatedItems };
    updatedData.total_freight_amount = updatedData.items.reduce((a, v) => a = a + (v.freight_amount === undefined ? 0 : v.freight_amount), 0)
    updatedData.total_weight = updatedData.items.reduce((a, v) => a = a + (v.weight === undefined ? 0 : v.weight), 0)
    updatedData.total_freight_vat = updatedData.total_freight_amount * .12;
    setWaybillData(updatedData);
  }
  const addNewItemClickHandler = () => {
    const data = { ...waybillData }
    const itemModel = { ...waybillItemModel }
    itemModel.line = waybillItems.length
    itemModel.waybill_id = data.waybill_id
    const updatedItems = [...waybillData.items, itemModel]
    const updatedData = { ...data, items: updatedItems }
    setWaybillItems(updatedItems)
    console.log(updatedData)
    setWaybillData(updatedData)
  }
  const deleteItemClickHandler = (value: number) => {
    const data = { ...waybillData }
    const dataItems = [...data.items]
    const filteredItems = dataItems.filter(item => item.line !== value)
    const itemsToDelete = dataItems.filter(item => item.line === value)
    const updatedData = { ...data, items: filteredItems }
    updatedData.total_freight_amount = filteredItems.reduce((a, v) => a = a + (v.freight_amount === undefined ? 0 : v.freight_amount), 0)
    updatedData.total_weight = filteredItems.reduce((a, v) => a = a + (v.weight === undefined ? 0 : v.weight), 0)
    updatedData.total_freight_vat = updatedData.total_freight_amount * .12
    setWaybillItemsDelete(itemsToDelete)
    setWaybillItems(filteredItems)
    setWaybillData(updatedData)
  }
  const onFreightAmountChange = (values: NumberFormatValues) => {
    const data = { ...waybillData }
    data.total_freight_amount = values.floatValue!;
    setWaybillData(data)
  }
  const onDiscountChange = (values: NumberFormatValues) => {
    const data = { ...waybillData }
    data.scpwd_discount = values.floatValue!;
    setWaybillData(data)
  }
  const onTotalFreightPaidAmount = (values: NumberFormatValues) => {
    const data = { ...waybillData }
    data.total_freight_paid = values.floatValue!
    const total = data.total_freight_amount + data.scpwd_discount;
    if (data.total_freight_paid >= total) {
      data.paid = true;
    }
    setWaybillData(data)
  }
  const onSalesInvoiceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    const data = { ...waybillData, invoice_no: value }
    setWaybillData(data)
  }
  const onReferenceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    const data = { ...waybillData, reference: value }
    setWaybillData(data)
  }
  const onReceivedByChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    const data = { ...waybillData, received_by: value }
    setWaybillData(data)
  }
  const onStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.currentTarget;
    const data = { ...waybillData }
    data.status = Number(value)
    setWaybillData(data)
  }
  const onChargeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.currentTarget;
    const data = { ...waybillData }
    data.charge_to = Number(value)
    setWaybillData(data)
  }
  const onWaybillNoChange = (e: NumberFormatValues) => {
    const data = { ...waybillData }
    data.waybill_no = e.floatValue!
    setWaybillData(data);
    getWaybillByNo(Number(e.value), branch).then((response) => {
      if (response.data.data.length > 0) {
        setWaybillValid(!(response.data.data[0].id === data.waybill_id))
      } else {
        setWaybillValid(response.data.meta.pagination.total > 0)
      }
    })
  }
  const onClickConsigneeSearchable = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSaveConsignee(e.currentTarget.checked)
  }
  const onClickShipperSearchable = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSaveShipper(e.currentTarget.checked)
  }


  return (
    <>
      {
        manifest &&
        <div className="alert alert-primary d-flex align-items-center p-5">
          <i className="fas fa-truck-fast fs-2x mx-5"></i>
          <div className="d-flex flex-column">
            <h4 className="mb-1 text-danger">Added in the Manifest # <a href={`/section/manifests/details/${manifest.id}`} className='link-primary'>{manifest?.attributes.manifest_no}</a></h4>
            <span>Departure Date: {moment(manifest?.attributes.departure_date).format('DD MMM YYYY')}</span>
          </div>
        </div>
      }
      <Formik validationSchema={currentSchema}
        initialValues={waybillData}
        onSubmit={submit}
        enableReinitialize={true}
        validateOnChange={true}>
        {(prop) => (
          <div className={`card ${className}`}>
            <SaveWaybill loading={loading} waybill_no={waybillData.waybill_no} valid={isWaybillValid} />
            <div className='car-body'>
              <Form className='row sg-4 p-10' id='kt_create_waybill_form'>
                <div className="row">
                  <div className='col-md-3'>
                    <label className='d-flex align-items-center form-label-sm fw-bold'>
                      <span className='required'>Waybill #</span>
                    </label>

                    <NumericFormat
                      maxLength={10}
                      name='waybill_no'
                      placeholder='Waybill #'
                      value={prop.values.waybill_no}
                      onValueChange={(values) => {
                        onWaybillNoChange(values);
                        prop.setFieldValue('waybill_no', values.value)
                      }}

                      className={clsx(
                        'form-control form-control-solid fw-bold',
                        { 'is-invalid': isWaybillValid },
                        {
                          'is-valid': !isWaybillValid,
                        }
                      )} />

                    <div className='text-danger mtc-2'>
                      <ErrorMessage name='waybill_no' />
                    </div>

                    <div className='form-text'>
                      Waybill # found in the waybill receipt
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <label className='d-flex align-items-center form-label-sm fw-bold'>
                      <span className='required'>Date</span>
                    </label>
                    <DatePicker name='date'
                      showTimeSelect
                      required
                      selected={moment(waybillData.date).local().toDate()}
                      onChange={(e: Date) => {
                        if (e === null) { e = new Date() }
                        setDateNowChange(e)
                        prop.setFieldValue('date', e)
                      }}
                      dateFormat={'MM/dd/yyyy hh:mm aa'}
                      className='form-control form-control-solid fw-bold'
                      />

                    <div className='text-danger mt-2'>
                      <ErrorMessage name='date' />
                    </div>

                    <div className='form-text'>
                      Waybill Date
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <label className='d-flex align-items-center form-label-sm fw-bold'>
                      <span className='required'>Status</span>
                    </label>
                    <Field
                      as='select'
                      name='status'
                      value={waybillData.status}
                      onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                        onStatusChange(e)
                        prop.setFieldValue(e.currentTarget.name, Number(e.currentTarget.value))
                      }}
                      className='form-select form-control-solid form-select-solid fw-bold'>
                      <option value='1'>Warehouse</option>
                      <option value='5'>Loaded</option>
                      <option value='2'>In Transit</option>
                      <option value='3'>Arrived</option>
                      <option value='4'>Delivered</option>
                    </Field>
                    <div className='form-text'>
                      Status of the shipment
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <label className='d-flex align-items-center form-label-sm fw-bold'>
                      <span className='required'>Charge To</span>
                    </label>

                    <Field
                      as='select'
                      name='charge_to'
                      value={waybillData.charge_to}
                      onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                        onChargeChange(e)
                        prop.setFieldValue(e.currentTarget.name, Number(e.currentTarget.value))
                      }}
                      className='form-select form-control-solid form-select-solid fw-bold' >
                      <option value='1'>Collect</option>
                      <option value='2'>Prepaid</option>
                      <option value='3'>Account</option>
                    </Field>
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='charge_to' />
                    </div>

                    <div className='form-text'>
                      Client payment options
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className='col-md-3 mt-5'>
                    <KTCard className='shadow-sm mt-5'>
                      <div className='card-header min-h-10px'>
                        <h3 className='card-title align-items-start flex-column'>
                          <span className='card-label fw-bold fs-base fw-bold mb-1 required'>Shipper</span>

                        </h3>
                        <div className='card-toolbar'>
                          <button
                            type='button'
                            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                            data-kt-menu-trigger='click'
                            data-kt-menu-placement='bottom-end'
                            data-kt-menu-flip='top-end'
                          >
                            <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-2' />
                          </button>
                          <Shipper
                            name={prop.values.shipper_name}
                            contact_no={waybillData.shipper_contact_no}
                            address={waybillData.shipper_address}
                            searchable={saveShipper}
                            clickSearchable={onClickShipperSearchable}
                            shipperUpdateEventChange={shipperUpdateEvent}
                            onChange={(contact: ContactDetails) => {
                              prop.setFieldValue('shipper_name', contact.name)
                              prop.setFieldValue('shipper_address', contact.address)
                              prop.setFieldValue('shipper_contact_no', contact.contact_no)
                            }}
                          />
                        </div>
                      </div>
                      <KTCardBody className='py-4'>
                        {(prop.errors.shipper_name
                          || !waybillData.shipper_name)
                          &&
                          <label className='d-flex align-items-center form-label-sm fw-bold'>
                            <KTSVG
                              path='/media/icons/duotune/general/gen044.svg'
                              className='svg-icon-4 me-1 svg-icon-danger' />
                            <span className='px-2 py-2'>Shipper's name is required</span>
                          </label>
                        }
                        <Field
                          type='text'
                          name='shipper_name'
                          className='d-none'
                          values={prop.values.shipper_name}
                          required onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            prop.setFieldValue(e.currentTarget.name, e.currentTarget.value)
                          }} />
                        {waybillData.shipper_name &&
                          <label className='d-flex align-items-center form-label-sm fw-bold'>
                            <KTSVG
                              path='/media/icons/duotune/communication/com006.svg'
                              className='svg-icon-4 me-1 svg-icon-danger' />
                            <span className='px-2 py-2'>{prop.values.shipper_name}</span>
                          </label>
                        }
                        {waybillData.shipper_contact_no &&
                          <label className='d-flex align-items-center form-label-sm fw-bold'>
                            <KTSVG
                              path='/media/icons/duotune/electronics/elc003.svg'
                              className='svg-icon-4 me-1 svg-icon-primary'
                            />
                            <span className='px-2 py-2'>{prop.values.shipper_contact_no}</span>

                          </label>
                        }
                        {waybillData.shipper_address &&
                          <label className='d-flex align-items-center form-label-sm fw-bold'>
                            <KTSVG
                              path='/media/icons/duotune/general/gen018.svg'
                              className='svg-icon-4 me-1 svg-icon-success'
                            />
                            <span className='px-2 form-text'>{prop.values.shipper_address}</span>

                          </label>
                        }
                      </KTCardBody>
                    </KTCard>
                  </div>
                  <div className='col-md-3 mt-5'>
                    <KTCard className='shadow-sm mt-5'>
                      <div className='card-header min-h-10px'>
                        <h3 className='card-title align-items-start flex-column'>
                          <span className='card-label fw-bold fs-base fw-bold mb-1 required'>Consignee</span>

                        </h3>
                        <div className='card-toolbar'>
                          <button
                            type='button'
                            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                            data-kt-menu-trigger='click'
                            data-kt-menu-placement='bottom-end'
                            data-kt-menu-flip='top-end'
                          >
                            <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-2' />
                          </button>
                          <Consignee
                            name={waybillData.consignee}
                            contact_no={waybillData.mobile}
                            address={waybillData.address}
                            searchable={saveConsignee}
                            clickSearchable={onClickConsigneeSearchable}
                            consigneeUpdateEventChange={(contact: ContactDetails) => {
                              consigneeUpdateEvent(contact)
                              prop.setFieldValue('consignee', contact.name)
                              prop.setFieldValue('address', contact.address)
                              prop.setFieldValue('mobile', contact.contact_no)
                            }} />
                        </div>
                      </div>
                      <KTCardBody className='py-4'>
                        {prop.errors.consignee &&
                          <label className='d-flex align-items-center form-label-sm fw-bold'>
                            <KTSVG
                              path='/media/icons/duotune/general/gen044.svg'
                              className='svg-icon-4 me-1 svg-icon-danger' />
                            <span className='px-2 py-2'>{prop.errors.consignee}</span>
                          </label>
                        }
                        {prop.errors.address &&
                          <label className='d-flex align-items-center form-label-sm fw-bold'>
                            <KTSVG
                              path='/media/icons/duotune/general/gen044.svg'
                              className='svg-icon-4 me-1 svg-icon-danger' />
                            <span className='px-2 py-2'>{prop.errors.address}</span>
                          </label>
                        }
                        {prop.errors.mobile &&
                          <label className='d-flex align-items-center form-label-sm fw-bold'>
                            <KTSVG
                              path='/media/icons/duotune/general/gen044.svg'
                              className='svg-icon-4 me-1 svg-icon-danger' />
                            <span className='px-2 py-2'>{prop.errors.mobile}</span>
                          </label>
                        }
                        {waybillData.consignee &&
                          <label className='d-flex align-items-center form-label-sm fw-bold'>
                            <KTSVG
                              path='/media/icons/duotune/communication/com006.svg'
                              className='svg-icon-4 me-1 svg-icon-info' />
                            <span className='px-2 py-2'>{waybillData.consignee}</span>

                          </label>
                        }
                        {waybillData.mobile &&
                          <label className='d-flex align-items-center form-label-sm fw-bold'>
                            <KTSVG
                              path='/media/icons/duotune/electronics/elc003.svg'
                              className='svg-icon-4 me-1 svg-icon-primary'
                            />
                            <span className='px-2 py-2'>{waybillData.mobile}</span>
                          </label>
                        }
                        {waybillData.address &&
                          <label className='d-flex align-items-center form-label-sm fw-bold'>
                            <KTSVG
                              path='/media/icons/duotune/general/gen018.svg'
                              className='svg-icon-4 me-1 svg-icon-success'
                            />
                            <span className='px-2 form-text'>{waybillData.address}</span>

                          </label>
                        }
                      </KTCardBody>
                    </KTCard>
                  </div>
                  <div className='col-md-3 mt-5'>
                    <div className=''>
                      <label className='d-flex align-items-center form-label-sm fw-bold'>
                        <span className=''>Received By</span>
                      </label>

                      <Field
                        maxLength={20}
                        type='text'
                        name='received_by'
                        value={prop.values.received_by}
                        className='form-control form-control-solid fw-bold'
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          onReceivedByChange(e)
                          prop.setFieldValue(e.currentTarget.name, e.currentTarget.value)
                        }}
                      />
                      <div className='text-danger mtc-2'>
                        <ErrorMessage name='received_by' />
                      </div>

                      <div className='form-text'>
                        Received by
                      </div>

                    </div>
                    <div className=''>
                      <label className='d-flex align-items-center form-label-sm fw-bold mt-5'>
                        <span className=''>Received Date</span>
                      </label>

                      <DatePicker name='received_date'
                        showTimeSelect
                        selected={new Date(waybillData.received_date)}
                        dateFormat={'MM/dd/yyyy'}
                        onChange={(e: Date) => {
                          if (e === null) { e = new Date() }
                          setRecievedDateChange(e)
                          prop.setFieldValue('received_date', e)
                        }}
                        className='form-control form-control-solid fw-bold' />

                      <div className='text-danger mtc-2'>
                        <ErrorMessage name='received_date' />
                      </div>

                      <div className='form-text'>
                        Date received the items
                      </div>
                    </div>
                  </div>
                  <div className='col-md-3 mt-5'>
                    <div className='row'>
                      <label className='d-flex align-items-center form-label-sm fw-bold'>
                        <span className='required'>Total Freight Amount</span>
                      </label>
                      <NumericFormat
                        maxLength={12}
                        name='total_freight_amount'
                        placeholder='Waybill #'
                        value={waybillData.total_freight_amount}
                        className='form-control form-control-solid fw-bold'
                        thousandSeparator={','}
                        decimalScale={2}
                        onValueChange={(values) => onFreightAmountChange(values)}
                      />
                      <div className='text-danger mtc-2'>
                        <ErrorMessage name='total_freight_amount' />
                      </div>

                      <div className='form-text'>
                        Total Freight Amount from items
                      </div>
                    </div>
                    <div className='row mt-5'>
                      <label className='d-flex align-items-center form-label-sm fw-bold'>
                        <span className='required'>Total Freight VAT</span>
                      </label>
                      <NumericFormat
                        maxLength={10}
                        name='total_freight_vat'
                        placeholder='Waybill #'
                        value={waybillData.total_freight_amount * .12}
                        className='form-control form-control-solid fw-bold'
                        thousandSeparator={','}
                        allowLeadingZeros={false}
                        decimalScale={2}
                      />
                      <div className='text-danger mtc-2'>
                        <ErrorMessage name='total_freight_vat' />
                      </div>

                      <div className='form-text'>
                        Total Freight VAT 12%
                      </div>
                    </div>
                  </div>
                </div>
                <div className='d-flex flex-row mt-5'>
                  <div className='col-md-9'>
                    <FieldArray name='items'
                      render={
                        arrayHelpers => (
                          prop.errors.items !== undefined && prop.errors.items.length > 0 &&
                          (
                            <label className='d-flex align-items-center form-label-sm fw-bold mx-5'>
                              <KTSVG
                                path='/media/icons/duotune/general/gen044.svg'
                                className='svg-icon-4 me-1 svg-icon-danger' />
                              <span className='px-2 py-2'>Item description is required</span>
                            </label>
                          )
                        )
                      }
                    />
                    <WaybillItems
                      deleteItemClick={deleteItemClickHandler}
                      addNewItemClick={addNewItemClickHandler}
                      onStringUpdate={handleStringValueUpdate}
                      onNumericUpdate={handleNumericValueUpdate}
                      items={waybillItems}
                      className='card-custom shadow mb-5 mb-xl-8 me-5' />


                  </div>
                  <div className='col-md-3'>

                    <div className='row'>
                      <label className='d-flex align-items-center form-label-sm fw-bold'>
                        <span className='required'>SC PWD Discount</span>
                      </label>
                      <NumericFormat
                        maxLength={10}
                        name='total_freight_vat'
                        placeholder='Enter discount'
                        value={waybillData.scpwd_discount}
                        className='form-control form-control-solid fw-bold'
                        thousandSeparator={','}
                        allowLeadingZeros={false}
                        decimalScale={2}
                        onValueChange={(values) => onDiscountChange(values)}
                      />
                      <div className='text-danger mtc-2'>
                        <ErrorMessage name='total_freight_vat' />
                      </div>

                      <div className='form-text'>
                        Senior Citizen or PWD Discount
                      </div>
                    </div>
                    <div className='row mt-5'>
                      <label className='d-flex align-items-center form-label-sm fw-bold'>
                        <span className='required'>Total Freight Paid</span>
                      </label>
                      <NumericFormat
                        maxLength={10}
                        name='total_freight_paid'
                        placeholder='Total Payment'
                        value={waybillData.total_freight_paid}
                        className='form-control form-control-solid fw-bold'
                        thousandSeparator={','}
                        allowLeadingZeros={false}
                        decimalScale={2}
                        onValueChange={(values) => onTotalFreightPaidAmount(values)}
                      />
                      <div className='text-danger mtc-2'>
                        <ErrorMessage name='total_freight_paid' />
                      </div>

                      <div className='form-text'>
                        Total Payment
                      </div>
                    </div>
                    <div className='row mt-5'>
                      <label className='d-flex align-items-center form-label-sm fw-bold'>
                        <span className=''>Sales Invoice No</span>
                      </label>

                      <Field
                        maxLength={12}
                        type='text'
                        name='invoice_no'
                        value={waybillData.invoice_no}
                        className='form-control form-control-solid fw-bold'
                        onChange={onSalesInvoiceChange}
                      />
                      <div className='text-danger mtc-2'>
                        <ErrorMessage name='invoice_no' />
                      </div>

                      <div className='form-text'>
                        Sales Invoice #
                      </div>
                    </div>
                    <div className='row mt-5'>
                      <label className='d-flex align-items-center form-label-sm fw-bold'>
                        <span className=''>Reference No</span>
                      </label>

                      <Field
                        maxLength={20}
                        type='text'
                        name='reference'
                        value={waybillData.reference}
                        className='form-control form-control-solid fw-bold'
                        onChange={onReferenceChange}
                      />
                      <div className='text-danger mtc-2'>
                        <ErrorMessage name='reference' />
                      </div>

                      <div className='form-text'>
                        Reference details
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </div >
          </div >
        )}
      </Formik >
    </>

  )
}

export { WaybillDetails }

type SaveProps = {
  loading: boolean
  waybill_no: number
  valid: boolean
}

const SaveWaybill: React.FC<SaveProps> = ({ loading, waybill_no, valid }) => {
  const { id } = useParams() as { id: string }
  const { values, submitForm, validateForm } = useFormikContext();
  const handleSave = () => {
    console.log(values)
    validateForm().then((result) => {
      console.log(result);
    })
    submitForm();
  }
  return (
    <div className='card-header border-0 pt-5'>
      <h3 className='card-title align-items-start flex-column'>
        <span className='card-label fw-bold fs-3 mb-1'>Waybill # {waybill_no}</span>
        <span className='text-muted mt-1 fw-semibold fs-7'>Help?</span>
      </h3>
      <div className='card-toolbar' style={{ width: 200 }}>
        <div className='d-flex flex-row' style={{ justifyContent: 'space-between' }}>
          <button type='submit' disabled={valid} onClick={() => handleSave()} style={{ marginRight: 10 }}
            className='btn btn-sm btn-success '>
            <i className="bi bi-save"> </i>
            {!loading && <span className='indicator-label'></span>}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
            Save
          </button>

          <a href={`/section/waybills/print/${id}`} className='btn btn-light-primary btn-sm'>
            <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-3' />
            Print
          </a>
        </div>
      </div>
    </div>
  )
}