/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import { CustomerData, WaybillIdNo, WaybillModel } from '../../../model/models';
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../setup';
import { Formik, Form, Field, ErrorMessage, useFormikContext, FormikHelpers } from 'formik'
import DatePicker from "react-datepicker";
import { NumberFormatValues, NumericFormat } from 'react-number-format'
import { useNavigate, useParams } from "react-router-dom";
import clsx from 'clsx';
import { detailsSOASchemas, IDetailsSOA, inits } from './SOADetailsHelper';
import Select, { ActionMeta, InputActionMeta, SingleValue } from 'react-select'
import Swal from 'sweetalert2';
import { UserModel } from '../../auth/core/_models';
import { getWaybillById, getWaybillItems, getWaybillsByAccountId, getWaybillsAllStatusByStartsWithNo, searchWaybillContainsAllStatus, updateWaybill } from '../../waybills/redux/WaybillsApi';
import DataTable, { TableColumn } from 'react-data-table-component';
import moment from 'moment';
import { KTSVG } from '../../../../_metronic/helpers';
import { getAccountById, getAccountByNo, updateSOA } from '../redux/AccountsApi';
import { SelectComponent } from '../../manifests/components/SelectComponent';


type Props = {
  className: string
}

const customStyles = {
  headCells: {
    style: {
      fontWeight: 'bold'
    },
  }
};

const SOADetails: React.FC<Props> = ({ className }) => {
  const { id } = useParams() as { id: string }
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [currentSchema] = useState(detailsSOASchemas[0])
  const [initValues, setInitValues] = useState<IDetailsSOA>(inits)
  const [invalidReference, setInvalidReference] = useState(true)
  const [waybillData, setWaybillData] = useState<WaybillIdNo[]>()
  const [waybills, setWaybills] = useState<WaybillModel[]>([])
  const [deletedWaybills, setDeletedWaybills] = useState<WaybillModel[]>([])
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
  const customerData: CustomerData[] = useSelector<RootState>(({ customers }) => customers.data?.data, shallowEqual) as CustomerData[]

  useEffect(() => {
    const getWaybillsByAccount = (account_id: number) => {
      if (waybills.length === 0) {
        getWaybillsByAccountId(account_id).then((result) => {
          const selectedWaybills = result.data.data.map(item => {
            const waybill_id = item.id
            item.attributes.id = waybill_id
            return item.attributes
          })
          setWaybills(selectedWaybills)
        })
      }
    }
    const loadSOA = () => {
      getAccountById(Number(id)).then((response) => {
        const soa = response.data.data.attributes
        if(moment(soa.date_paid).isValid()) {
          soa.date_paid = moment(soa.date_paid).toDate()
        }
        soa.due_date = new Date(soa.due_date)
        soa.statement_date = new Date(soa.statement_date)
        soa.description = soa.description === null ? '' : soa.description
        soa.note = soa.note === null ? '' : soa.note
        setInitValues(soa)
        setTimeout(() => {
          getWaybillsByAccount(soa.account_id)
        }, 2000)
      })
    }
    loadSOA()

    console.log(initValues)
  }, [invalidReference]);

  const submit = async (values: IDetailsSOA, { setStatus, setSubmitting }: FormikHelpers<IDetailsSOA>) => {
    values.updatedby_id = user.id
    setLoading(true)
    setSubmitting(true)
    console.log(values)
    localStorage.setItem('soa_no_last', values.account_no.toString())
    setTimeout(() => {
      updateSOA(Number(id), values)
        .then((response) => {
          if (response.status === 200) {
            deletedWaybills.forEach(item => {
              updateWaybill(item.id, { account_id: null })
            })

            waybills.forEach((item) => {
              if (item.item_description === '' || item.item_description === null) {
                getWaybillItems(item.waybill_id).then((itemResponse) => {
                  const description = itemResponse.data.data.map(i => i.attributes.description).join(' / ')
                  const qty = itemResponse.data.data.reduce((a, v) => a = a + (v.attributes.quantity === undefined ? 0 : v.attributes.quantity), 0)
                  updateWaybill(item.id, {
                    account_id: values.account_id,
                    item_description: description,
                    total_quantity: qty
                  }
                  )
                })
              } else {
                updateWaybill(item.id,
                  {
                    account_id: values.account_id
                  })
              }
            })

            setTimeout(() => {
              Swal.fire({
                title: 'Successs!',
                text: `SOA # ${values.account_no} has been successfully save!`,
                icon: 'success',
                confirmButtonText: 'Print',
                cancelButtonText: 'Views',
                showCancelButton: true,
                customClass: {
                  confirmButton: 'btn btn-success',
                  cancelButton: 'btn btn-primary'
                }
              }).then((result) => {
                setLoading(false)
                if (result.isConfirmed) {
                  navigate(`/section/accounts/print/${response.data.data.id}`);
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                  navigate('/section/accounts/view');
                }
              })
            }, 5000)
          }
        })
        .catch((error) => {
          setLoading(false)
          setSubmitting(false)
          setStatus('Problem occured when saving your statement.')
          Swal.fire({
            title: 'Error!',
            text: 'Problem occured when creating your statement',
            icon: 'error',
            confirmButtonText: 'OK',
            iconColor: 'primary'
          })
          console.error(error)
        })
    }, 2000)
  }

  const onAccountNoChange = (e: NumberFormatValues) => {
    if (e.value === '') {
      setInvalidReference(false)
    } else {
      const data = { ...initValues }
      data.account_no = e.floatValue!
      setInitValues(data);
      checkAccountNo(data.account_no)
    }
  }

  const checkAccountNo = (accountNo: number) => {
    getAccountByNo(accountNo).then((response) => {
      setInvalidReference(response.data.meta.pagination.total === 0)
    })
  }

  const onStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.currentTarget;
    const data = { ...initValues }
    data.status = Number(value)
    setInitValues(data)
  }

  const onCustomerChange = (value: SingleValue<CustomerData>, a: ActionMeta<CustomerData>) => {
    const data = { ...initValues }
    data.customer_id = value !== null ? value.attributes.customer_id : 0
    setInitValues(data)
  }

  const onTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    if (e.currentTarget.name === 'note') {
      const note = { ...initValues, note: value }
      setInitValues(note)
    } else if (e.currentTarget.name === 'description') {
      const description = { ...initValues, description: value }
      setInitValues(description)
    }
  }

  const onNumericChange = (e: NumberFormatValues, field: string) => {
    if (field === 'total_amount') {
      const dataTotalAmout = { ...initValues, total_amount: e.floatValue! }
      setInitValues(dataTotalAmout)
    } else if (field === 'total_vat') {
      const dataTotalVat = { ...initValues, total_vat: e.floatValue! }
      setInitValues(dataTotalVat)
    } else {
      const dataTotalPaid = { ...initValues, total_paid: e.floatValue! }
      setInitValues(dataTotalPaid)
    }
  }

  const onSelectedWaybill = (input: number, a: ActionMeta<WaybillIdNo>) => {
    if (a.action === 'select-option') {
      getWaybillById(input).then((response) => {
        if (waybills !== undefined) {
          const account = { ...initValues }
          const waybill = response.data.data.attributes;
          const data = [...waybills]
          if (!waybills.find(item => item.waybill_id === waybill.waybill_id)) {
            waybill.id = input
            data.push(waybill)
            setWaybills(data)
          }
          account.total_amount = data.reduce((a, v) => a = a + (v.total_freight_amount === undefined ? 0 : v.total_freight_amount), 0)
          account.total_vat = data.reduce((a, v) => a = a + (v.total_freight_vat === undefined ? 0 : v.total_freight_vat), 0)
          setInitValues(account)
        }
      })
    }
  }

  const loadWaybills = (input: string, a: InputActionMeta) => {
    if (a.action === 'input-change') {
      if (input.length > 2) {
        getWaybills(input)
      }
    }
  }

  const getWaybills = (input: string) => {
    if (!Number.isNaN(Number(input))) {
      getWaybillsAllStatusByStartsWithNo(Number(input)).then((response) => {
        const results = response.data.data.map((item) => {
          const label = `${item.attributes.waybill_no} > ${item.attributes.consignee}`;
          return { value: item.id, label: label }
        })
        setWaybillData(results)
      })
    } else {
      searchWaybillContainsAllStatus(input).then((result) => {
        const searchResults = result.data.data.map((item) => {
          const label = `${item.attributes.waybill_no} > ${item.attributes.consignee}`;
          return { value: item.id, label: label }
        })
        setWaybillData(searchResults)
      })
    }
  }

  const deleteWaybill = (id: number) => {
    const filtered = waybills.filter(item => item.id !== id)
    const deleted = waybills.filter(item => item.id === id);
    const mergedDeleted = ([...deletedWaybills, ...deleted])
    setDeletedWaybills(mergedDeleted)
    setWaybills(filtered)
  }

  const onDatePaid = (e: Date) => {
    const data = { ...initValues }
    data.date_paid = e
    setInitValues(data);
  }

  const onDueDate = (e: Date) => {
    const data = { ...initValues }
    data.due_date = e
    setInitValues(data);
  }

  const columns: TableColumn<WaybillModel>[] = [
    {
      name: '#',
      cell: (row, index) => index + 1,
      width: '60px'
    },
    {
      name: 'WAYBILL#',
      cell: row => (
        <a href={`/section/waybills/details/${row.id}`}>{row.waybill_no}</a>
      ),
      sortable: true,
      width: "120px"
    },
    {
      name: 'SHIPPER',
      selector: row => row.shipper_name,
      cell: row => {
        if (row.shipper_name === "0") {
          return "";
        } else {
          return row.shipper_name
        }
      }
    },
    {
      name: 'CONSIGNEE',
      selector: row => row.consignee,
      cell: row => row.consignee,
      sortable: true
    },
    {
      name: 'DESTINATION',
      selector: row => row.address,
      cell: row => row.address
    },
    {
      name: 'STATUS',
      width: "120px",
      selector: row => row.status,
      cell: row => {
        if (row.status === 1) {
          return (
            <span className='badge badge-light-primary fs-8 fw-bold my-2'>Warehouse</span>
          )
        } else if (row.status === 2) {
          return (
            <span className='badge badge-light-warning fs-8 fw-bold my-2'>InTransit</span>
          )
        } else if (row.status === 3) {
          return <span className='badge badge-light-info fs-8 fw-bold my-2'>Arrived</span>
        } else if (row.status === 5) {
          return <span className='badge badge-light-danger fs-8 fw-bold my-2'>Loaded</span>
        } else {
          return <span className='badge badge-light-success fs-8 fw-bold my-2'>Delivered</span>
        }
      }
    },
    {
      name: 'DATE',
      selector: row => moment(row.date).format('MM/DD/YYYY'),
      sortable: true,
      sortField: 'date',
      width: "120px"
    },
    {
      name: 'AMOUNT',
      selector: row => row.total_freight_amount,
      format: row => row.total_freight_amount.toLocaleString('en-US', { minimumFractionDigits: 2 }),
      sortable: true,
      width: "120px"
    },
    {
      name: 'TERMS',
      width: "120px",
      selector: row => row.charge_to,
      cell: row => {
        if (row.charge_to === 1) {
          return (
            <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-7'>Collect</span>
          )
        } else if (row.charge_to === 2) {
          return (
            <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-7'>Prepaid</span>
          )
        } else {
          return (
            <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-7'>Account</span>
          )
        }
      }
    },
    {
      name: 'REMOVE',
      width: '80px',
      button: true,
      cell: row => (
        <a onClick={() => deleteWaybill(row.id)} href='#'
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'>
          <KTSVG
            path='/media/icons/duotune/general/gen027.svg'
            className='svg-icon-3'
          />
        </a>
      )
    }
  ];

  return (
    <>
      <Formik validationSchema={currentSchema}
        initialValues={initValues}
        onSubmit={submit}
        enableReinitialize={true}
        validateOnChange={true}>
        {(prop) => (
          <div className={`card ${className}`}>
            <SaveSOA loading={loading} valid={invalidReference} client={customerData.filter(customer => customer.attributes.customer_id === initValues.customer_id)[0]?.attributes.name} />
            <div className='car-body'>
              <Form className='row sg-4 p-10' id='kt_details_soa_form'>
                <div className="row">
                  <div className='col-md-3'>
                    <label className='d-flex align-items-center form-label-sm fw-bold'>
                      <span className='required'>Reference No</span>
                    </label>
                    <NumericFormat
                      maxLength={10}
                      name='account_no'
                      placeholder='Reference #'
                      value={prop.values.account_no}
                      onValueChange={(values) => {
                        onAccountNoChange(values);
                        prop.setFieldValue('account_no', values.value)
                      }}
                      className={clsx(
                        'form-control form-control-solid fw-bold',
                        { 'is-invalid': !invalidReference },
                        {
                          'is-valid': invalidReference,
                        }
                      )} />
                    <div className='text-danger mtc-2'>
                      <ErrorMessage name='account_no' />
                    </div>
                    <div className='form-text'>
                      Reference # of account
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <label className='d-flex align-items-center form-label-sm fw-bold'>
                      <span className='required'>Statement Date</span>
                    </label>
                    <DatePicker name='statement_date'
                      required
                      value={moment(prop.values.statement_date).format('MM/DD/YYYY')}
                      minDate={new Date()}
                      selected={prop.values.statement_date}
                      onChange={(e: Date) => {
                        if (e === null) { e = new Date() }
                        prop.setFieldValue('statement_date', e)
                      }}
                      dateFormat={'MM/dd/yyy'}
                      className='form-control form-control-solid fw-bold' />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='statement_date' />
                    </div>

                    <div className='form-text'>
                      Statement of Account Date
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <label className='d-flex align-items-center form-label-sm fw-bold'>
                      <span className='required'>Due Date</span>
                    </label>
                    <DatePicker name='due_date'
                      required
                      value={moment(initValues.due_date).format('MM/DD/YYYY')}
                      minDate={new Date()}
                      selected={initValues.due_date}
                      onChange={(e: Date) => {
                        if (e === null) { e = new Date() }
                        onDueDate(e)
                        prop.setFieldValue('due_date', e)
                      }}
                      dateFormat={'MM/dd/yyy'}
                      className='form-control form-control-solid fw-bold' />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='due_date' />
                    </div>

                    <div className='form-text'>
                      Due Date
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <label className='d-flex align-items-center form-label-sm fw-bold'>
                      <span className='required'>Date Paid</span>
                    </label>
                    <DatePicker name='date_paid'
                      required
                      value={moment(prop.values.date_paid).isValid() ? moment(prop.values.date_paid).format('MM/DD/YYYY') : ''}
                      selected={prop.values.date_paid}
                      onChange={(e: Date) => {
                        onDatePaid(e)
                        prop.setFieldValue('date_paid', e)
                      }}
                      dateFormat={'MM/dd/yyy'}
                      className='form-control form-control-solid fw-bold' />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='date_paid' />
                    </div>

                    <div className='form-text'>
                      Date Paid
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className='col-md-3 mt-7'>
                    <label className='d-flex align-items-center form-label-sm fw-bold'>
                      <span className='required'>Customer Account To</span>
                    </label>
                    <Select
                      name='customer_id'
                      isClearable
                      className='fw-bold'
                      isSearchable
                      onChange={(e, a) => {
                        onCustomerChange(e, a)
                        prop.setFieldValue('customer_id', e !== null ? e.attributes.customer_id : 0)
                      }}
                      value={customerData.filter(customer => customer.attributes.customer_id === prop.values.customer_id)[0]}
                      options={customerData}
                      getOptionValue={(option) => { return `${option.attributes.customer_id}` }}
                      getOptionLabel={(option) => { return `${option.attributes.name}` }}
                    />
                    <div className='text-danger mtc-2'>
                      <ErrorMessage name='customer_id' />
                    </div>
                    <div className='form-text'>
                      Select a customer for this statement
                    </div>
                  </div>
                  <div className='col-md-3 mt-5'>
                    <label className='d-flex align-items-center form-label-sm fw-bold'>
                      <span className='required'>Status</span>
                    </label>
                    <Field
                      as='select'
                      name='status'
                      value={prop.values.status}
                      onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                        onStatusChange(e)
                        prop.setFieldValue('status', Number(e.currentTarget.value))
                      }}
                      className='form-select fw-bold'>
                      <option value='0'>Unpaid</option>
                      <option value='1'>Acknowledged</option>
                      <option value='2'>Full Payment</option>
                      <option value='3'>Installment</option>
                      <option value='4'>OverDue</option>
                    </Field>
                    <div className='form-text'>
                      Status of the soa
                    </div>
                  </div>
                  <div className='col-md-3 mt-5'>
                    <div className=''>
                      <label className='d-flex align-items-center form-label-sm fw-bold'>
                        <span className=''>Note</span>
                      </label>
                      <Field
                        required
                        maxLength={240}
                        type='text'
                        name='note'
                        value={prop.values.note}
                        className='form-control fw-bold'
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          onTextChange(e)
                          prop.setFieldValue('note', e.currentTarget.value)
                        }}
                      />
                      <div className='text-danger mtc-2'>
                        <ErrorMessage name='note' />
                      </div>

                      <div className='form-text'>
                        Note
                      </div>

                    </div>
                  </div>
                  <div className='col-md-3 mt-5'>
                    <div className=''>
                      <label className='d-flex align-items-center form-label-sm fw-bold'>
                        <span className=''>Description</span>
                      </label>
                      <Field
                        maxLength={120}
                        type='text'
                        name='description'
                        value={prop.values.description}
                        className='form-control fw-bold'
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          onTextChange(e)
                          prop.setFieldValue('description', e.currentTarget.value)
                        }}
                      />
                      <div className='text-danger mtc-2'>
                        <ErrorMessage name='description' />
                      </div>

                      <div className='form-text'>
                        Description
                      </div>

                    </div>
                  </div>


                </div>
                <div className="row">
                  <div className='col-md-3 mt-5'>
                    <label className='d-flex align-items-center form-label-sm fw-bold'>
                      <span className='required'>Total Billing Amount</span>
                    </label>
                    <NumericFormat
                      maxLength={10}
                      name='total_amount'
                      placeholder='Total Amount'
                      value={waybills.reduce((a, v) => a = a + (v.total_freight_amount === undefined ? 0 : v.total_freight_amount), 0)}
                      className='form-control form-control-solid fw-bold'
                      thousandSeparator={','}
                      allowLeadingZeros={false}
                      decimalScale={2}
                      onValueChange={(values) => {
                        onNumericChange(values, 'total_amount');
                        prop.setFieldValue('total_amount', values.floatValue)
                      }}
                    />
                    <div className='text-danger mtc-2'>
                      <ErrorMessage name='total_amount' />
                    </div>

                    <div className='form-text'>
                      Total Billing Amount
                    </div>
                  </div>
                  <div className='col-md-3 mt-5'>
                    <label className='d-flex align-items-center form-label-sm fw-bold'>
                      <span className='required'>Total VAT Amount</span>
                    </label>
                    <NumericFormat
                      maxLength={10}
                      name='total_vat'
                      placeholder='Total Vat Amount'
                      value={waybills.reduce((a, v) => a = a + (v.total_freight_amount === undefined ? 0 : v.total_freight_amount), 0)*.12}
                      className='form-control form-control-solid fw-bold'
                      thousandSeparator={','}
                      allowLeadingZeros={false}
                      decimalScale={2}
                      onValueChange={(values) => {
                        onNumericChange(values, 'total_vat')
                        prop.setFieldValue('total_vat', values.floatValue)
                      }}
                    />
                    <div className='text-danger mtc-2'>
                      <ErrorMessage name='total_vat' />
                    </div>

                    <div className='form-text'>
                      Total VAT Amount
                    </div>
                  </div>
                  <div className='col-md-3 mt-5'>
                    <label className='d-flex align-items-center form-label-sm fw-bold'>
                      <span className='required'>Total Amount Paid</span>
                    </label>
                    <NumericFormat
                      maxLength={10}
                      name='total_paid'
                      placeholder='Total Amount Paid'
                      value={prop.values.total_paid}
                      className='form-control form-control-solid fw-bold'
                      thousandSeparator={','}
                      allowLeadingZeros={false}
                      decimalScale={2}
                      onValueChange={(values) => {
                        onNumericChange(values, 'total_paid');
                        prop.setFieldValue('total_paid', values.floatValue)
                      }}
                    />
                    <div className='text-danger mtc-2'>
                      <ErrorMessage name='total_paid' />
                    </div>

                    <div className='form-text'>
                      Total Amount Paid
                    </div>
                  </div>

                </div>
              </Form>
            </div >
          </div >
        )}
      </Formik >
      <DataTable columns={columns}
        data={waybills}
        striped
        selectableRowsHighlight
        pointerOnHover
        progressPending={loading}
        customStyles={customStyles}
        subHeader
        subHeaderComponent={<SelectComponent waybill={waybillData} load={loadWaybills} select={onSelectedWaybill} />}
        className='table-responsive' />
    </>
  )
}

export { SOADetails }

type SaveProps = {
  loading: boolean
  valid: boolean
  client: string
}

const SaveSOA: React.FC<SaveProps> = ({ loading, valid, client }) => {
  const { id } = useParams() as { id: string }
  const { values, submitForm, validateForm } = useFormikContext();
  const handleSave = () => {
    console.log(values)
    validateForm().then((result) => {
      console.log(result);
    })
    submitForm();
  }
  return (
    <div className='card-header border-0 pt-5'>
      <h3 className='card-title align-items-start flex-column'>
        <span className='card-label fw-bold fs-3 mb-1'>Customer Account to {client}</span>
        <span className='text-muted mt-1 fw-semibold fs-7'>Help?</span>
      </h3>
      <div className='card-toolbar' style={{ width: 200 }}>
        <div className='d-flex flex-row' style={{ justifyContent: 'space-between' }}>
          <button type='submit' disabled={!valid} onClick={() => handleSave()}
            className='btn btn-success' style={{ marginRight: 10 }}>
            <i className="bi bi-save"> </i>
            {!loading && <span className='indicator-label'>Save</span>}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <a href={`/section/accounts/print/${id}`} className='btn btn-light-primary'>
            <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-3' />
            Print
          </a>
        </div>
      </div>
    </div>
  )
}

