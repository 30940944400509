import {all} from 'redux-saga/effects'
import {combineReducers} from 'redux'

import * as auth from '../../app/modules/auth'
import { waybillSlice } from '../../app/modules/waybills/redux/WaybillSlice'
import { waybillCreateSlice } from '../../app/modules/waybills/redux/WaybillCreateSlice'
import { manifestSlice } from '../../app/modules/manifests/redux/ManifestSlice'
import { truckDataSlice } from '../../app/modules/manifests/redux/TruckDataSlice'
import { customerSlice } from '../../app/modules/soa/redux/CustomerSlice'
import { userConfigSlice } from '../../app/config/redux/UserConfigCreateSlice'

export const rootReducer = combineReducers({
  auth: auth.reducer,
  waybills: waybillSlice.reducer,
  createWaybill: waybillCreateSlice.reducer,
  manifest: manifestSlice.reducer,
  truckData: truckDataSlice.reducer, 
  customers: customerSlice.reducer,
  configs: userConfigSlice.reducer

})

export type RootState = ReturnType<typeof rootReducer>

export function* rootSaga() {
  yield all([auth.saga()])
}
