import React, {FC} from 'react'
import { WaybillCreate } from './WaybillCreate'

const WaybillCreatePage: FC = () => {
  return (
    <>
      <WaybillCreate className='mb-5 mb-xl-8' />
    </>
  )
}

export {WaybillCreatePage}
