import React, {FC} from 'react'
import { SOACreate } from './SOACreate'

const SOACreatePage: FC = () => {
  return (
    <>
      <SOACreate className='mb-5 mb-xl-8' />
    </>
  )
}

export {SOACreatePage}
