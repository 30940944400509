import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {ManifestView } from '../../../model/type'
import {ManifestData, ManifestResponseData, TruckResponseData, Waybills} from '../../../model/models' 

const initialManifestState: ManifestView = {
  listLoading: false,
  actionsLoading: false,
  data: undefined,
  trucks: undefined
}

export const callTypes = {
  list: 'list',
  action: 'action',
}

export const manifestSlice = createSlice({
  name: 'manifests',
  initialState: initialManifestState,
  reducers: {
    catchError: (state: ManifestView, action: PayloadAction<ManifestView>) => {
      state.error = `${action.type}: ${action.payload.error}`
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false
      } else {
        state.actionsLoading = false
      }
    },
    startCall: (state: ManifestView, action: PayloadAction<ManifestView>) => {
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true
      } else {
        state.actionsLoading = true
      }
    },
    fetched: (state: ManifestView, action: PayloadAction<ManifestResponseData>) => {
      state.listLoading = false
      state.actionsLoading = false
      state.data = action.payload
    },
    fetchTrucks: (state: ManifestView, action: PayloadAction<TruckResponseData>) => {
      state.listLoading = false
      state.actionsLoading = false
      state.trucks = action.payload
    }
  },
})
  
export const {
  catchError,
  startCall,
  fetched,
  fetchTrucks
} = manifestSlice.actions


export default manifestSlice.reducer
