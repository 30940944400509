import { Page, Document, Font, View, Image, Text } from '@react-pdf/renderer';
import { useEffect, useState } from 'react';
import { ManifestData, TruckData, Waybills } from '../../../model/models';
import { getManifestById, getTruckDetails } from '../redux/ManifestApi';
import { getWaybillItems, getWaybillsByManifestId } from '../../waybills/redux/WaybillsApi';
import moment from 'moment';
import React from 'react';

Font.register({
    family: 'Libre',
    src: 'https://fonts.gstatic.com/s/librebaskerville/v4/pR0sBQVcY0JZc_ciXjFsKyyZRYCSvpCzQKuMWnP5NDY.ttf'
});


type Props = {
    id: number
}


const ManifestPrint: React.FC<Props> = ({ id }) => {
    const [manifestData, setManifestData] = useState<ManifestData>()
    const [waybills, setWaybills] = useState<Waybills>()
    const [truck, setTruck] = useState<TruckData>()
    const [totalCollect, setTotalCollect] = useState(0)
    const [totalAccount, setTotalAccount] = useState(0)
    const [totalPrepaid, setTotalPrepaid] = useState(0)
    const [totalFreightAmount, setTotalFreightAmount] = useState(0)

    const loadTrucksData = (id: number) => {
        getTruckDetails().then((response) => {
            const trucks = response.data.data.filter((t) => t.attributes.truck_id === id)
            setTruck(trucks[0])
            console.log(trucks)
        });
    }

    useEffect(() => {
        const loadManifest = () => {
            getManifestById(id).then((response) => {
                const manifest = response.data.data;
                setManifestData(manifest)
                loadTrucksData(manifest.attributes.truck_id)
                console.log(response.data.data)
                loadWaybills(manifest.attributes.manifest_id)
            })
        }
        const loadWaybills = (manifestId: number) => {
            getWaybillsByManifestId(manifestId).then((result) => {
                const waybillsResult = result.data as Waybills;
                waybillsResult.data.forEach((waybill) => {
                    getWaybillItems(waybill.attributes.waybill_id).then((response) => {
                        const waybillItems = response.data.data.map((item) => {
                            return item.attributes
                        })
                        waybill.attributes.item_description = waybillItems.map(item => item.description).join(' / ')
                        waybill.attributes.items = waybillItems
                    })

                })

                setTotalFreightAmount(waybillsResult.data.reduce((a, v) => a = a + (v.attributes.total_freight_amount === undefined ? 0 : v.attributes.total_freight_amount), 0))

                const collectWaybills = waybillsResult.data.filter(items => items.attributes.charge_to === 1);
                setTotalCollect(collectWaybills.reduce((a, v) => a = a + (v.attributes.total_freight_amount === undefined ? 0 : v.attributes.total_freight_amount), 0))

                const prepaidWaybills = waybillsResult.data.filter(items => items.attributes.charge_to === 2);
                setTotalPrepaid(prepaidWaybills.reduce((a, v) => a = a + (v.attributes.total_freight_amount === undefined ? 0 : v.attributes.total_freight_amount), 0))

                const accountWaybills = waybillsResult.data.filter(items => items.attributes.charge_to === 3);
                setTotalAccount(accountWaybills.reduce((a, v) => a = a + (v.attributes.total_freight_amount === undefined ? 0 : v.attributes.total_freight_amount), 0))

                setWaybills(waybillsResult)
                console.log(waybillsResult)
            })
        }
        loadManifest()
    }, [id])

    return (

        <Document>
            <Page size={[612.0, 936.0]} orientation='landscape'>
                <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                    <Image style={{ width: 200, height: 50, marginTop: 10 }} src='/media/logos/Aguileon_Logo.png' />
                </View>
                <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                    <Text style={{ fontFamily: 'Libre', fontSize: 7 }}>1426 Geliños St. Brgy. 342, Zone 34, District III, Sta. Cruz, Manila</Text>
                </View>
                <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                    <Text style={{ fontFamily: 'Libre', fontSize: 10, marginTop: 10 }}>Trucking Manifest # {manifestData && manifestData?.attributes.manifest_no}</Text>
                </View>
                <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                    <Text style={{ fontFamily: 'Libre', fontSize: 8, marginTop: 10 }}>{manifestData?.attributes.description}</Text>
                </View>

                <View style={{ flexDirection: 'row', justifyContent: 'space-evenly', marginTop: 5, fontFamily: 'Libre', borderTop: .5 }}>
                    <View style={{ flexDirection: 'row', width: 200, margin: 5, paddingTop: 5 }}>
                        <Text style={{ fontSize: 8 }}>Transport by: </Text>
                        <Text style={{ fontSize: 8, fontWeight: 'ultrabold' }}>{manifestData?.attributes.driver} | {truck?.attributes.plate_no}</Text>
                    </View>
                    <View style={{ flexDirection: 'row', width: 200, margin: 5, fontFamily: 'Libre', paddingTop: 5 }}>
                        <Text style={{ fontSize: 8 }}>Destination: </Text>
                        <Text style={{ fontSize: 8, fontWeight: 'ultrabold' }}>{manifestData?.attributes.destination}</Text>
                    </View>
                    <View style={{ flexDirection: 'row', width: 200, margin: 5, fontFamily: 'Libre', paddingTop: 5 }}>
                        <Text style={{ fontSize: 8 }}>Departure: </Text>
                        <Text style={{ fontSize: 8, fontWeight: 'ultrabold' }}>{moment(manifestData?.attributes.departure_date).format('DD MMM yyyy')}</Text>
                    </View>
                    <View style={{ flexDirection: 'row', width: 200, margin: 5, fontFamily: 'Libre', paddingTop: 5 }}>
                        <Text style={{ fontSize: 8 }}>Arrival: </Text>
                        <Text style={{ fontSize: 8, fontWeight: 'ultrabold' }}>{moment(manifestData?.attributes.arrival_date).format('DD MMM yyyy')}</Text>
                    </View>
                </View>
                <View style={{ flexDirection: 'row', justifyContent: 'space-evenly', marginTop: 5, fontFamily: 'Libre', borderTop: .5 }}>
                    <View style={{ flexDirection: 'row', width: 200, margin: 5, paddingTop: 5 }}>
                        <Text style={{ fontSize: 8 }}>Total Collect: PHP  </Text>
                        <Text style={{ fontSize: 8, fontWeight: 'ultrabold' }}>{totalCollect.toLocaleString()}</Text>
                    </View>
                    <View style={{ flexDirection: 'row', width: 200, margin: 5, fontFamily: 'Libre', paddingTop: 5 }}>
                        <Text style={{ fontSize: 8 }}>Total Account: PHP  </Text>
                        <Text style={{ fontSize: 8, fontWeight: 'ultrabold' }}>{totalAccount.toLocaleString()}</Text>
                    </View>
                    <View style={{ flexDirection: 'row', width: 200, margin: 5, fontFamily: 'Libre', paddingTop: 5 }}>
                        <Text style={{ fontSize: 8 }}>Total Prepaid: PHP  </Text>
                        <Text style={{ fontSize: 8, fontWeight: 'ultrabold' }}>{totalPrepaid.toLocaleString()}</Text>
                    </View>
                    <View style={{ flexDirection: 'row', width: 200, margin: 5, fontFamily: 'Libre', paddingTop: 5 }}>
                        <Text style={{ fontSize: 8 }}>Total Freight: PHP  </Text>
                        <Text style={{ fontSize: 8, fontWeight: 'ultrabold' }}>{totalFreightAmount.toLocaleString()}</Text>
                    </View>
                </View>
                <View style={{ flexDirection: 'row', justifyContent: 'space-evenly', marginTop: 5, fontFamily: 'Libre', borderTop: .5 }}>
                    <View style={{ flexDirection: 'row', width: 200, margin: 5, paddingTop: 5 }}>
                        <Text style={{ fontSize: 8 }}>Payable To:  </Text>
                        <Text style={{ fontSize: 8, fontWeight: 'ultrabold' }}>ACEC</Text>
                    </View>
                    <View style={{ flexDirection: 'row', width: 200, margin: 5, fontFamily: 'Libre', paddingTop: 5 }}>
                        <Text style={{ fontSize: 8 }}>Percentage Amount: PHP  </Text>
                        <Text style={{ fontSize: 8, fontWeight: 'ultrabold' }}>{manifestData?.attributes.percentage !== undefined ? (totalFreightAmount * (manifestData?.attributes.percentage / 100)).toLocaleString() : 0}</Text>
                    </View>
                    <View style={{ flexDirection: 'row', width: 200, margin: 5, fontFamily: 'Libre', paddingTop: 5 }}>
                        <Text style={{ fontSize: 8 }}>Percentage Rate: </Text>
                        <Text style={{ fontSize: 8, fontWeight: 'ultrabold' }}>.{manifestData?.attributes.percentage.toLocaleString()}</Text>
                    </View>
                    <View style={{ flexDirection: 'row', width: 200, margin: 5, fontFamily: 'Libre', paddingTop: 5 }}>
                        <Text style={{ fontSize: 8 }}>Net Income:  </Text>
                        <Text style={{ fontSize: 8, fontWeight: 'ultrabold' }}>{((manifestData?.attributes.percentage !== undefined ? (totalFreightAmount * (manifestData?.attributes.percentage / 100)) : 0) - (totalAccount + totalPrepaid)).toFixed(2)}</Text>
                    </View>
                </View>
                <View style={{ flexDirection: 'row', justifyContent: 'space-evenly', marginTop: 5, fontFamily: 'Libre', marginLeft: 10 }}>
                    <View style={{ flexDirection: 'row', width: 80, margin: 5, paddingTop: 5 }}>
                        <Text style={{ fontSize: 8 }}>Waybill#  </Text>
                    </View>
                    <View style={{ flexDirection: 'row', width: 140, margin: 5, fontFamily: 'Libre', paddingTop: 5 }}>
                        <Text style={{ fontSize: 8 }}>Shipper </Text>
                    </View>
                    <View style={{ flexDirection: 'row', width: 140, margin: 5, fontFamily: 'Libre', paddingTop: 5 }}>
                        <Text style={{ fontSize: 8 }}>Consignee </Text>
                    </View>
                    <View style={{ flexDirection: 'row', width: 80, margin: 5, fontFamily: 'Libre', paddingTop: 5 }}>
                        <Text style={{ fontSize: 8 }}>Date </Text>
                    </View>
                    <View style={{ flexDirection: 'row', width: 280, margin: 5, fontFamily: 'Libre', paddingTop: 5 }}>
                        <Text style={{ fontSize: 8 }}>Items  </Text>
                    </View>
                    <View style={{ flexDirection: 'row', width: 80, margin: 5, fontFamily: 'Libre', paddingTop: 5 }}>
                        <Text style={{ fontSize: 8 }}>Total Qty</Text>
                    </View>
                    <View style={{ flexDirection: 'row', width: 100, margin: 5, fontFamily: 'Libre', paddingTop: 5 }}>
                        <Text style={{ fontSize: 8 }}>Total Freight (PHP) </Text>
                    </View>
                    <View style={{ flexDirection: 'row', width: 100, margin: 5, fontFamily: 'Libre', paddingTop: 5 }}>
                        <Text style={{ fontSize: 8 }}>Terms</Text>
                    </View>
                </View>
                {
                    waybills !== undefined && waybills?.data.length > 0 &&
                    waybills?.data.map((waybill, index) => {
                        return <View fixed key={index} style={{ flexDirection: 'row', justifyContent: 'flex-start', marginTop: 5, fontFamily: 'Libre', marginLeft: 10, borderBottom: .5 }}>
                            <View style={{ flexDirection: 'row', width: 80, paddingLeft: 5, paddingTop: 1 }}>
                                <Text style={{ fontSize: 7 }}>{index + 1}.    {waybill.attributes.waybill_no}</Text>
                            </View>
                            <View style={{ flexDirection: 'row', width: 120, fontFamily: 'Libre', paddingTop: 1 }}>
                                <Text style={{ fontSize: 7 }}>{waybill.attributes.shipper_name}</Text>
                            </View>
                            <View style={{ flexDirection: 'row', width: 120, fontFamily: 'Libre', paddingTop: 1 }}>
                                <Text style={{ fontSize: 7 }}>{waybill.attributes.consignee}</Text>
                            </View>
                            <View style={{ flexDirection: 'row', justifyContent: 'center', width: 80, fontFamily: 'Libre', paddingTop: 1 }}>
                                <Text style={{ fontSize: 7 }}>{moment(waybill.attributes.date).format('MM/DD/YYYY')}</Text>
                            </View>
                            <View wrap style={{ flexDirection: 'row', width: 280, marginHorizontal: 5, fontFamily: 'Libre', paddingTop: 1 }}>
                                <Text style={{ fontSize: 7 }}>{waybill.attributes.item_description} </Text>
                            </View>
                            <View style={{ flexDirection: 'row', width: 60, marginHorizontal: 5, fontFamily: 'Libre', paddingTop: 1 }}>
                                <Text style={{ fontSize: 7 }}>{waybill.attributes.total_quantity?.toLocaleString()}</Text>
                            </View>
                            <View style={{
                                flexDirection: 'row', justifyContent: 'flex-end',
                                width: 80, marginHorizontal: 5, fontFamily: 'Libre', paddingTop: 1, paddingRight: 25, textAlign: 'right'
                            }}>
                                <Text style={{ fontSize: 7, textAlign: 'right' }}>{waybill.attributes.total_freight_amount?.toLocaleString()} </Text>
                            </View>
                            <View style={{ flexDirection: 'row', width: 80, marginHorizontal: 5, fontFamily: 'Libre', paddingTop: 1 }}>
                                {
                                    (waybill.attributes.charge_to === 1 && <Text style={{ fontSize: 6 }}>COLLECT</Text>)
                                    ||
                                    (waybill.attributes.charge_to === 2 && <Text style={{ fontSize: 6 }}>PREPAID</Text>)
                                    ||
                                    (waybill.attributes.charge_to === 3 && <Text style={{ fontSize: 6 }}>ACCOUNT</Text>)
                                }
                            </View>
                        </View>
                    })
                }
                <View style={{ flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 15, marginTop: 15, fontFamily: 'Libre' }}>
                    <View style={{ flexDirection: 'row', width: 160, margin: 5, paddingTop: 5 }}>
                        <Text style={{ fontSize: 7 }}>Prepared by: </Text>
                        <Text style={{ fontSize: 7, fontWeight: 'ultrabold' }}>____________________</Text>
                    </View>
                    <View style={{ flexDirection: 'row', width: 160, margin: 5, fontFamily: 'Libre', paddingTop: 5 }}>
                        <Text style={{ fontSize: 7 }}>Completed by: </Text>
                        <Text style={{ fontSize: 7, fontWeight: 'ultrabold' }}>____________________</Text>
                    </View>
                    <View style={{ flexDirection: 'row', width: 160, margin: 5, fontFamily: 'Libre', paddingTop: 5 }}>
                        <Text style={{ fontSize: 7 }}>Lacking: </Text>
                        <Text style={{ fontSize: 7, fontWeight: 'ultrabold' }}>____________________</Text>
                    </View>
                 
                </View>
            </Page>
        </Document>
    )
}
export { ManifestPrint }