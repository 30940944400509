import React, {FC} from 'react'
import { StatementsView } from './StatementsView'

const StatementsViewPage: FC = () => {
  return (
    <>
    <StatementsView className='mb-5 mb-xl-8' />
    </>
  )
}

export {StatementsViewPage}
