/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { KTSVG } from '../../../../_metronic/helpers'
import DataTable, { TableColumn, createTheme } from 'react-data-table-component'
import { ManifestData, TruckData } from '../../../model/models';
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../setup';
import moment from 'moment';
import { filterManifestByTruck, getManifests, getTruckDetails, searchManifestByNo } from '../redux/ManifestApi';
import { fetched } from '../redux/ManifestSlice';
import { fetchTrucksData } from '../redux/TruckDataSlice';
import Select, { ActionMeta, InputActionMeta } from 'react-select'

type Props = {
  className: string
}

const sortingArrivalDate = (rowA: ManifestData, rowB: ManifestData) => {
  const a = rowA.attributes.arrival_date
  const b = rowB.attributes.arrival_date
  if (a > b) {
    return 1;
  }

  if (b > a) {
    return -1;
  }

  return 0;
};

const sortingDepartureDate = (rowA: ManifestData, rowB: ManifestData) => {
  const a = rowA.attributes.departure_date
  const b = rowB.attributes.departure_date
  if (a > b) {
    return 1;
  }

  if (b > a) {
    return -1;
  }

  return 0;
};

const columns: TableColumn<ManifestData>[] = [
  {
    name: 'MANIFEST #',
    selector: row => row.attributes.manifest_no,
    cell: row => (
      <div className='d-flex align-items-center'>
        <div className='ms-3'>
          <a href={`/section/manifests/details/${row.id}`} className=''>{row.attributes.manifest_no}</a>
        </div>
      </div>
    ),
    sortable: true,
    width: "120px"
  },
  {
    name: 'STATUS',
    selector: row => row.attributes.status_id,
    cell: row => {
      if (row.attributes.status_id === 1) {
        return (
          <span className='badge badge-light-primary fs-8 fw-bold my-2'>Warehouse</span>
        )
      } else if (row.attributes.status_id === 2) {
        return (
          <span className='badge badge-light-warning fs-8 fw-bold my-2'>InTransit</span>
        )
      } else if (row.attributes.status_id === 3) {
        return <span className='badge badge-light-info fs-8 fw-bold my-2'>Arrived</span>
      } else if (row.attributes.status_id === 4) {
        return <span className='badge badge-light-success fs-8 fw-bold my-2'>Delivered</span>
      } else { return <span className='badge badge-light-danger fs-8 fw-bold my-2'>Loaded</span> }
    }
  },
  {
    name: 'DESTINATION',
    selector: row => row.attributes.destination,
    cell: row => row.attributes.destination,
    sortable: true,
  },
  {
    name: 'ROUTE',
    selector: row => row.attributes.description,
    cell: row => row.attributes.description,
  },
  {
    name: 'DEPARTURE',
    width: "130px",
    selector: row => moment(row.attributes.departure_date).format('MM/DD/YYYY'),
    sortable: true,
    sortField: 'departure_date',
    sortFunction: sortingDepartureDate
  },
  {
    name: 'ARRIVAL',
    selector: row => moment(row.attributes.arrival_date).format('MM/DD/YYYY'),
    sortable: true,
    sortField: 'arrival_date',
    width: '120px',
    sortFunction: sortingArrivalDate
  },
  {
    name: 'TRUCK',
    selector: row => row.attributes.truck_id,
    cell: row => <TruckView truckId={row.attributes.truck_id} description='truck' />
  },
  {
    name: 'GROUP',
    width: "120px",
    selector: row => row.attributes.truck_id,
    cell: row => <TruckView truckId={row.attributes.truck_id} description='group' />
  },
  {
    name: 'ACTION',
    width: '80px',
    button: true,
    cell: row => (
      <div>
        <a
          href={`/section/manifests/details/${row.id}`}
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          title='View details'
        >
          <KTSVG path='/media/icons/duotune/general/gen059.svg' className='svg-icon-3' />
        </a>
        <a href={`/section/manifests/print/${row.id}`} className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          title='Print manifest'>
          <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-3' />
        </a>
      </div>
    )
  }
];

createTheme('solarized', {
  text: {
    primary: '#268bd2',
    secondary: '#2aa198',
  },
  background: {
    default: '#002b36',
  },
  context: {
    background: '#cb4b16',
    text: '#FFFFFF',
  },
  divider: {
    default: '#073642',
  },
  action: {
    button: 'rgba(0,0,0,.54)',
    hover: 'rgba(0,0,0,.08)',
    disabled: 'rgba(0,0,0,.12)',
  },
}, 'dark');

const customStyles = {
  headCells: {
    style: {
      fontWeight: 'bold'
    },
  }
};


const ManifestView: React.FC<Props> = ({ className }) => {
  const manifestData: ManifestData[] = useSelector<RootState>(({ manifest }) => manifest.data?.data, shallowEqual) as ManifestData[]
  const branch = useSelector<RootState>(({ configs }) => configs.branchId, shallowEqual) as string
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [totalRows, setTotalRows] = useState(0)
  const [pageSize, setPageSize] = useState(25)
  const [searchText, setSearchText] = useState('')
  const [truckOptions, setTruckOptions] = useState<{ value: number, label: string }[]>([])
  const truckData: TruckData[] = useSelector<RootState>(({ truckData }) => truckData.data?.data, shallowEqual) as TruckData[]

  const loadManifest = React.useCallback((page: number, pageSize: number, branch_id: any) => {
    setTimeout(() => {
      getManifests(page, pageSize, branch_id).then((response) => {
        setTotalRows(response.data.meta.pagination.total)
        setPageSize(response.data.meta.pagination.pageSize)
        dispatch(fetched(response.data))
        setLoading(false)
      });
    }, 2000)
  }, [dispatch])

  useEffect(() => {
    const loadTrucksData = () => {
      getTruckDetails().then((response) => {
        const trucks = response.data.data.map((item) => {
          return { value: item.attributes.truck_id, label: item.attributes.plate_no }
        })
        setTruckOptions(trucks)
        dispatch(fetchTrucksData(response.data))
      })
    }
    setLoading(true)
    loadManifest(1, pageSize, branch)
    loadTrucksData()
  }, [loadManifest, pageSize, branch])

  const handlePageChange = (page: number) => {
    loadManifest(page, pageSize, branch);
  }

  const handlePerRowsChange = async (page: number, pageSize: number) => {
    setLoading(true);
    loadManifest(page, pageSize, branch);
    setPageSize(pageSize);
    setLoading(false);
  }

  const searchManifest = async (searchText: string) => {
    setSearchText(searchText)
    if (searchText.length > 2) {
      setLoading(true)
      searchManifestByNo(searchText, branch).then((response) => {
        setTotalRows(response.data.meta.pagination.total)
        setPageSize(response.data.meta.pagination.pageSize)
        dispatch(fetched(response.data))
        setLoading(false)
      })
    }
  }

  const onTruckSelected = (truck_id: number) => {
    setLoading(true)
    if(truck_id === undefined) {
      loadManifest(1, pageSize, branch)
    } else {
      filterManifestByTruck(truck_id, branch).then((response) => {
        setTotalRows(response.data.meta.pagination.total)
        setPageSize(response.data.meta.pagination.pageSize)
        dispatch(fetched(response.data))
        setLoading(false)
      })
    }
  }

  const clearSearch = async () => {
    setSearchText('')
    setLoading(true)
    loadManifest(1, pageSize, branch)
  }

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Recent Manifests</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>Total of {totalRows.toLocaleString()} manifests</span>
        </h3>
        <div className='card-toolbar d-flex flex-row justify-content-between'>
          <div className='col-md-6 px-5'>
            <Select
              name='truck_id'
              isClearable
              className='fw-bold'
              isSearchable
              onChange={(e) => {
                onTruckSelected(e?.value!)
              }}
              //onFocus={onFocusTruckList}
              options={truckOptions} />
          </div>
          <div className=' col-md-6'>
            <div className='input-group'>
              <input type='text' className='form-control' value={searchText} placeholder={`Manifest #`} autoComplete='off' autoFocus
                onChange={(e) => { searchManifest(e.currentTarget.value) }} />
              <a className="btn btn-light-primary outline" type="button" id="button-addon2" onClick={clearSearch}>Clear</a>
            </div>
          </div>


        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <DataTable columns={columns}
            data={manifestData}
            striped
            selectableRows
            selectableRowsHighlight
            pointerOnHover
            progressPending={loading}
            pagination
            paginationPerPage={pageSize}
            paginationServer
            paginationTotalRows={totalRows}
            customStyles={customStyles}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
          />
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export { ManifestView }

type TruckViewProps = {
  truckId: number
  description: string
}

const TruckView: React.FC<TruckViewProps> = ({ truckId, description }) => {
  const truckData: TruckData[] = useSelector<RootState>(({ truckData }) => truckData.data?.data, shallowEqual) as TruckData[]
  const [truck, setTruck] = useState<TruckData>();
  useEffect(() => {
    let resultTruck = truckData.find(t => t.attributes.truck_id === truckId)
    if (resultTruck === undefined) {
      resultTruck = truckData.find(t => t.id === truckId)
    }
    setTruck(resultTruck)
  }, [truckId, truckData])
  return (
    <>
      {
        (description === 'truck' && truck?.attributes.plate_no) ||
        (description === 'group' && truck?.attributes.group)
      }
    </>
  )
}