/* eslint-disable jsx-a11y/anchor-is-valid */
import { Field } from 'formik'
import React, { useState } from 'react'
import { NumericFormat } from 'react-number-format'
import { ContactDetails } from '../../../../model/models'

type Props = {
    name: string,
    address: string,
    contact_no: string,
    searchable: boolean
    clickSearchable: (e: React.ChangeEvent<HTMLInputElement>) => void
    shipperUpdateEventChange: (contact: ContactDetails) => void,
    onChange: (contact: ContactDetails) => void
}

const Shipper: React.FC<Props> = ({
    name,
    address,
    contact_no,
    searchable,
    clickSearchable,
    shipperUpdateEventChange,
    onChange }) => {
    const [shipperName, setShipperName] = useState(name)
    const [shipperAddress, setShipperAddress] = useState(address)
    const [shipperContact, setShipperContact] = useState(contact_no)
    const [save, setSave] = useState(searchable)
    
    const changeShipperName = (e: React.ChangeEvent<HTMLInputElement>) => {
        setShipperName(e.currentTarget.value)
    }

    const changeShipperAddress = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setShipperAddress(e.currentTarget.value)
    }

    const changeShipperContact = (e: React.ChangeEvent<HTMLInputElement>) => {
        setShipperContact(e.currentTarget.value)
    }

    return (
        <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true'>
            <div className='separator border-gray-200'></div>
            <div className='px-7 py-5'>
                <div className='mb-10'>
                    <label className='form-label fw-bold'>Name</label>
                    <Field
                        name='shipper_name'
                        type='text'
                        value={shipperName}
                        maxLength={60}
                        required
                        className='form-control form-control-solid'
                        placeholder="Shipper's name"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            changeShipperName(e);
                        }}
                    />
                </div>
                <div className='mb-10'>
                    <label className='form-label fw-bold'>Address</label>
                    <textarea
                        value={shipperAddress}
                        maxLength={180}
                        className='form-control form-control-solid'
                        placeholder="Shipper's address"
                        onChange={changeShipperAddress}
                    />
                </div>
                <div className='mb-10'>
                    <label className='form-label fw-bold'>Contact #</label>
                    <NumericFormat
                        maxLength={12}
                        placeholder='Contact #'
                        value={shipperContact}
                        valueIsNumericString
                        className='form-control form-control-solid'
                        onChange={changeShipperContact}
                    />
                </div>

                <div className='d-flex justify-content-end'>
                    <div className='form-switch form-switch-sm form-check-custom form-check-solid'>
                        <input
                            className='form-check-input'
                            type='checkbox'
                            value=''
                            name='notifications'
                            defaultChecked={save}
                            onChange={clickSearchable}
                        />
                        <label className='form-check-label'>Searchable? </label>
                    </div>
                    <button
                        type='button'
                        className='btn btn-sm btn-light btn-active-light-primary me-1 mx-1'
                        data-kt-menu-dismiss='true'
                    >
                        Cancel
                    </button>

                    <button type='reset' onClick={() => {
                        shipperUpdateEventChange({ name: shipperName, address: shipperAddress, contact_no: shipperContact, id: 0, value: 0, label: '' })
                        onChange({ name: shipperName, address: shipperAddress, contact_no: shipperContact, id: 0, value: 0, label: '' })
                    }} className='btn btn-sm btn-primary' data-kt-menu-dismiss='true'>
                        Apply
                    </button>
                </div>
            </div>
        </div>
    )
}

export { Shipper }