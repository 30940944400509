/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { NumberFormatValues, SourceInfo } from 'react-number-format'
import { KTSVG } from '../../../../_metronic/helpers'
import { WaybillItemModel } from '../../../model/models'
import { TableItems } from './dropdowns/TableItems'

type Props = {
  className: string,
  items: Array<WaybillItemModel>,
  onStringUpdate: (id: number, event: React.ChangeEvent<HTMLInputElement>) => void,
  onNumericUpdate: (line: number, values: NumberFormatValues, event: SourceInfo) => void
  addNewItemClick: () => void;
  deleteItemClick: (value: number) => void;
}

const WaybillItems: React.FC<Props> = ({ className, items, 
    onStringUpdate,  
    onNumericUpdate, 
    addNewItemClick,
    deleteItemClick}) => {
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-10 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-base mb-1 required'>Items</span>
          <span className='text-muted mt-1 fw-semibold fs-7'># of items</span>
        </h3>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a item'
        >
          <a
            onClick={addNewItemClick}
            href='#'
            className='btn btn-sm btn-light-primary'
          // data-bs-toggle='modal'
          // data-bs-target='#kt_modal_invite_friends'
          >
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
            Add New Item
          </a>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='w-25px'>
                  #
                </th>
                <th className='w-60px'>Quantity</th>
                <th className='w-200px required'>Description</th>
                <th className='w-80px text-end'>Weight (Kg)</th>
                <th className='w-80px text-end'>Freight Charge (Php)</th>
                <th className='w-25px text-end'></th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}

            <TableItems
              initItems={items}
              deleteItem={deleteItemClick}
              onStringUpdate={onStringUpdate}
              onNumericUpdate={onNumericUpdate} />

            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export { WaybillItems }
