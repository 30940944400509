import React from 'react'
import {Navigate, Route, Routes, Outlet} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {WaybillViewPage} from './components/WaybillViewPage'
import {WaybillCreatePage} from './components/WaybillCreatePage'
import {WaybillDetailsPage} from './components/WaybillDetailsPage'
import { WaybillPrintPage } from './components/WaybillPrintPage'
import { WaybillLoad } from './components/WaybillLoad'

const waybillBreadCrumbs: Array<PageLink> = [
  {
    title: 'Waybills',
    path: '/section/waybills/view',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '/section/waybills/view',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '/section/waybills/details/:id',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const WaybillPage: React.FC = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='view'
        
          element={
            <>
              <PageTitle breadcrumbs={waybillBreadCrumbs}>View</PageTitle>
              <WaybillViewPage />
            </>
          }
        />
        <Route
          path='create'
          element={
            <>
              <PageTitle breadcrumbs={waybillBreadCrumbs}>Create</PageTitle>
              <WaybillCreatePage />
            </>
          }
        />
         <Route
          path='load'
          element={
            <>
              <PageTitle breadcrumbs={waybillBreadCrumbs}>Load</PageTitle>
              <WaybillLoad />
            </>
          }
        />
        {/* <Route index element={<Navigate to='/section/waybills/create' />} /> */}
        <Route
          path='details/:id'
          element={
            <>
              <PageTitle breadcrumbs={waybillBreadCrumbs}>View Waybill Details</PageTitle>
              <WaybillDetailsPage />
            </>
          }
        />
        <Route index element={<Navigate to='/section/waybills/details' />} />
        <Route
          path='print/:id'
        
          element={
            <>
              <PageTitle breadcrumbs={waybillBreadCrumbs}>Print</PageTitle>
              <WaybillPrintPage />
            </>
          }
        />
      </Route>
    </Routes>
  )
}
export default WaybillPage
