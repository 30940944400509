import React, {FC} from 'react'
import { SOADetails } from './SOADetails'

const SOADetailsPage: FC = () => {
  return (
    <>
      <SOADetails className='mb-5 mb-xl-8' />
    </>
  )
}

export {SOADetailsPage}
