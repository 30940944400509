import {Action} from '@reduxjs/toolkit'
import React from 'react'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, takeLatest} from 'redux-saga/effects'
import {UserModel} from '../core/_models'
import {getUserByToken} from './AuthCRUD'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  Login: '[Login] Action',
  Logout: '[Logout] Action',
  Register: '[Register] Action',
  UserRequested: '[Request User] Action',
  UserLoaded: '[Load User] Auth API',
  SetUser: '[Set User] Action'
}

export interface IAuthState {
  user?: UserModel 
  jwt?: string 
  refreshToken?: string 
}

const initAuthState: IAuthState = {
  user: undefined,
  jwt: undefined,
  refreshToken: undefined
}


export const reducer = persistReducer(
  {storage, key: 'auth', whitelist: ['user', 'jwt', 'refreshToken']},
  (state: IAuthState = initAuthState, action: ActionWithPayload<IAuthState>) => {
    switch (action.type) {
      case actionTypes.Login: {
        const jwt = action.payload?.jwt
        const user = action.payload?.user
        return {jwt, user}
      }

      case actionTypes.Register: {
        const jwt = action.payload?.jwt
        const user = action.payload?.user
        return {jwt, user}
      }

      case actionTypes.Logout: {
        return initAuthState
      }

      case actionTypes.UserRequested: {
        return {...state, user: undefined}
      }

      case actionTypes.UserLoaded: {
        const user = action.payload?.user
        return {...state, user}
      }

      case actionTypes.SetUser: {
        const user = action.payload?.user
        return {...state, user}
      }
      default:
        return state
    }
  }
)

export const actions = {
  login: (jwt: string, user: UserModel) => ({type: actionTypes.Login, payload: {jwt, user}}),
  register: (jwt: string) => ({
    type: actionTypes.Register,
    payload: {jwt},
  }),
  logout: () => ({type: actionTypes.Logout}),
  requestUser: () => ({
    type: actionTypes.UserRequested,
  }),
  fulfillUser: (user: UserModel) => ({type: actionTypes.UserLoaded, payload: {user}}),
  setUser: (user: UserModel) => ({type: actionTypes.SetUser, payload: {user}}),
  children: React.Children
}

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser())
  })

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser())
  })

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const {data: user} = yield getUserByToken()
    yield put(actions.fulfillUser(user))
  })
}
