import Select, { ActionMeta, InputActionMeta } from 'react-select'
import { WaybillIdNo } from "../../../model/models"

type SelectProps = {
    waybill: WaybillIdNo[] | undefined
    load: (input: string, a: InputActionMeta) => void
    select: (input: number, a: ActionMeta<WaybillIdNo>) => void
}

const SelectComponent: React.FC<SelectProps> = ({ waybill, load, select }) => {
    return (
        <Select
            className={'w-25'}
            isClearable
            isSearchable
            options={waybill}
            placeholder={'Enter waybill # or consignee'}
            onInputChange={(e, a) => load(e, a)}
            controlShouldRenderValue
            onChange={(e, a) => select(e?.value!, a)}
        />
    )
}

export {SelectComponent}