import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {WaybillCreate } from '../../../model/type'
import { inits } from '../components/helpers/WaybillCreateHelper'


const initWaybillNewState: WaybillCreate = {
  listLoading: false,
  actionsLoading: false,
  waybill: inits
}

export const callTypes = {
  list: 'list',
  action: 'action',
  update: 'update',
  save: 'save'
}

export const waybillCreateSlice = createSlice({
  name: 'waybills',
  initialState: initWaybillNewState,
  reducers: {
    initial: (state: WaybillCreate, action: PayloadAction<WaybillCreate>) => {
      state.listLoading = false
      state.actionsLoading = false
      state.waybill = action.payload.waybill
    },
    update: (state: WaybillCreate, action: PayloadAction<WaybillCreate>) => {
      state.actionsLoading = false
      state.listLoading = false
      const waybill = action.payload.waybill
      return {...state, waybill}
    }
  },
})
  
export const {
  initial,
  update
} = waybillCreateSlice.actions


export default waybillCreateSlice.reducer
