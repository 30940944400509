import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { PageTitle } from '../../../_metronic/layout/core'


const DashboardPage = () => (
  <>
    <div className='d-flex flex-center'>
      <img src={toAbsoluteUrl('/media/logos/AguileonLogoBlack.jpg')} alt='' className='img-fluid'  />
    </div>

  </>
)

const DashboardWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <DashboardPage />
    </>
  )
}

export { DashboardWrapper }
