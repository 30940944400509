/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { KTSVG } from '../../../../../_metronic/helpers'
import { WaybillItemModel } from '../../../../../app/model/models'
import { NumberFormatValues, NumericFormat, SourceInfo } from 'react-number-format'

type Props = {
    initItems: Array<WaybillItemModel>
    deleteItem: (value: number) => void
    onStringUpdate: (key: number, event: React.ChangeEvent<HTMLInputElement>) => void
    onNumericUpdate: (line: number, value: NumberFormatValues, event: SourceInfo) => void
}

const TableItems: React.FC<Props> = ({ initItems, deleteItem, onStringUpdate, onNumericUpdate }) => {
    return (
        <tbody>
            {
                initItems.map((data, index) => {
                    return (
                        <tr key={data.line}>
                            <td>
                                <label className='pt-4'>
                                    {index + 1}
                                </label>
                            </td>
                            <td>
                                <NumericFormat
                                    maxLength={5}
                                    name='quantity'
                                    placeholder='Quantity'
                                    defaultValue={data.quantity}
                                    valueIsNumericString
                                    className='form-control form-control-solid'
                                    allowLeadingZeros={false}
                                    onValueChange={(values, event) => onNumericUpdate(data.line, values, event)}
                                />
                            </td>
                            <td>
                                <input defaultValue={data.description}
                                    type='text'
                                    name='description'
                                    maxLength={120}
                                    className='form-control form-control-solid'
                                    placeholder="Item description"
                                    onChange={(event) => {
                                        onStringUpdate(data.line, event)
                                    }}
                                />
                            </td>
                            <td className='text-end'>
                                <NumericFormat
                                    maxLength={8}
                                    placeholder='Weight'
                                    name='weight'
                                    defaultValue={data.weight}
                                    valueIsNumericString
                                    className='form-control form-control-solid text-end'
                                    suffix=' kg'
                                    thousandSeparator={','}
                                    decimalScale={2}
                                    allowLeadingZeros={false}
                                    onValueChange={(values, event) => onNumericUpdate(data.line, values, event)}
                                />
                            </td>
                            <td className='text-end'>
                                <NumericFormat
                                    maxLength={10}
                                    placeholder='Amount'
                                    name='amount'
                                    defaultValue={data.freight_amount}
                                    valueIsNumericString
                                    className='form-control form-control-solid text-end'
                                    thousandSeparator={','}
                                    decimalScale={2}
                                    allowLeadingZeros={false}
                                    onValueChange={(values, event) => onNumericUpdate(data.line, values, event)}

                                />
                            </td>
                            <td>
                                <div className='d-flex justify-content-end flex-shrink-0'>
                                    <a onClick={() => deleteItem(data.line)} href='#'
                                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'>
                                        <KTSVG
                                            path='/media/icons/duotune/general/gen027.svg'
                                            className='svg-icon-3'
                                        />
                                    </a>
                                </div>
                            </td>
                        </tr>
                    )

                })
            }

            <tr>
                <td>Total</td>
                <td className='ps-5 fw-bold'>{initItems?.reduce((a, v) => a = a + (v.quantity === undefined ? 0 : v.quantity), 0)}</td>
                <td>

                </td>
                <td className='text-end px-7 fw-bold'>{initItems?.reduce((a, v) => a = a + (v.weight === undefined ? 0 : v.weight), 0)} kg</td>
                <td></td>
                <td></td>
            </tr>

        </tbody>

    )
}

export { TableItems }

