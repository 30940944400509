import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {CustomerDataView } from '../../../model/type'
import {Customers} from '../../../model/models' 

const initialData: CustomerDataView = {
  listLoading: false,
  actionsLoading: false,
  data: undefined
}

export const callTypes = {
  list: 'list',
  action: 'action',
}

export const customerSlice = createSlice({
  name: 'accounts',
  initialState: initialData,
  reducers: {
    catchError: (state: CustomerDataView, action: PayloadAction<CustomerDataView>) => {
      state.error = `${action.type}: ${action.payload.error}`
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false
      } else {
        state.actionsLoading = false
      }
    },
    startCall: (state: CustomerDataView, action: PayloadAction<CustomerDataView>) => {
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true
      } else {
        state.actionsLoading = true
      }
    },
    fetched: (state: CustomerDataView, action: PayloadAction<Customers>) => {
      state.listLoading = false
      state.actionsLoading = false
      state.data = action.payload
    }
  },
})
  
export const {
  catchError,
  startCall,
  fetched,
} = customerSlice.actions


export default customerSlice.reducer
