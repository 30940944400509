import React, {FC} from 'react'
import { ManifestCreate } from './ManifestCreate'

const ManifestCreatePage: FC = () => {
  return (
    <>
      <ManifestCreate className='mb-5 mb-xl-8' />
    </>
  )
}

export {ManifestCreatePage}
