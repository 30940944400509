import React, { FC, useEffect, useState } from 'react'
import { PDFViewer, StyleSheet } from '@react-pdf/renderer';
import { WaybillPrint } from './WaybillPrint'
import { useParams } from 'react-router-dom';
import Delayed from './helpers/Delayed';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../../../setup';
import { WaybillPrintCalapan } from './WaybillPrintCalapan';

const styles = StyleSheet.create({
  viewer: {
    width: '100%',
    height: '100%'
  }
});

const WaybillPrintPage: FC = () => {
  const { id } = useParams() as { id: string }
  const branch = useSelector<RootState>(({ configs }) => configs.branchId, shallowEqual) as string
  const isManila = branch === "1"
  return (
    <>
      <a href={`/section/waybills/details/${id}`} className='btn btn-light-primary my-2 '>{'< Back'}</a>
      {
        isManila ? (
          <>
            <PDFViewer style={styles.viewer}>
              <WaybillPrint id={Number(id)} />
            </PDFViewer>
          </>
        ) : (
          <>
            <PDFViewer style={styles.viewer}>
              <WaybillPrintCalapan id={Number(id)} />
            </PDFViewer>
          </>
        )}
    </>
  )
}

export { WaybillPrintPage }
