import axios from 'axios'
import {
  ContactDetails,
  ContactDetailsData,
  Meta,
  WaybillCreateResponse,
  WaybillItemModel,
  WaybillItemsResponseData,
  WaybillModel,
  WaybillResponseData,
  Waybills,
} from '../../../model/models'

const API_URL = process.env.REACT_APP_API_URL

export function getWaybills(page: number, pageSize: number, branch: any) {
  return axios.get<Waybills>(
    `${API_URL}/waybills?populate=*&pagination[page]=${page}&pagination[pageSize]=${pageSize}&filters[branch][$eq]=${branch}&sort[0]=createdAt:desc`
  )
}

export function getWaybillById(waybillId: number) {
  return axios.get<{data: WaybillResponseData; meta: Meta}>(`${API_URL}/waybills/${waybillId}`)
}

export function getWaybillsByStartsWithNo(waybillNo: number, status: number, branch: any) {
  return axios.get<{data: WaybillResponseData[]; meta: Meta}>(
    `${API_URL}/waybills/?filters[status][$eq]=${status}&filters[waybill_no][$startsWith]=${waybillNo}&filters[branch][$eq]=${branch}`
  )
}

export function getWaybillsAllStatusByStartsWithNo(waybillNo: number) {
  return axios.get<{data: WaybillResponseData[]; meta: Meta}>(
    `${API_URL}/waybills/?filters[$or][0][account_id][$null]=true&filters[$or][1][account_id][$eq]=0&filters[waybill_no][$startsWith]=${waybillNo}`
  )
}

export function getWaybillsFilterByNo(waybillNo: number, branch: any) {
  return axios.get<{data: WaybillResponseData[]; meta: Meta}>(
    `${API_URL}/waybills/?filters[$or][0][account_id][$null]=true&filters[$or][1][account_id][$eq]=0&filters[waybill_no][$startsWith]=${waybillNo}&filters[branch][$eq]=${branch}`
  )
}

export function searchWaybillContains(searchText: string, status: number, branch: any) {
  return axios.get<{data: WaybillResponseData[]; meta: Meta}>(
    `${API_URL}/waybills/?filters[$or][0][account_id][$null]=true&filters[$or][1][account_id][$eq]=0&filters[status][$eq]=${status}&filters[consignee][$contains]=${searchText}&filters[branch][$eq]=${branch}`
  )
}

export function searchWaybillContainsAllStatus(searchText: string) {
  return axios.get<{data: WaybillResponseData[]; meta: Meta}>(
    `${API_URL}/waybills/?filters[$or][0][account_id][$null]=true&filters[$or][1][account_id][$eq]=0&filters[consignee][$contains]=${searchText}`
  )
}

export function searchWaybillByConsignee(searchText: string, branch: any) {
  return axios.get<{data: WaybillResponseData[]; meta: Meta}>(
    `${API_URL}/waybills/?filters[$or][0][account_id][$null]=true&filters[$or][1][account_id][$eq]=0&filters[consignee][$contains]=${searchText}&filters[branch][$eq]=${branch}`
  )
}

export function searchWaybillList(field: string, searchText: string, page: number, pageSize: number, branch: any) {
  return axios.get<Waybills>(
    `${API_URL}/waybills/?sort[0]=createdAt:desc&filters[${field}][$contains]=${searchText}&filters[branch][$eq]=${branch}&pagination[page]=${page}&pagination[pageSize]=${pageSize}`
  )
}

export function getWaybillsByStatus(status: number, page: number, pageSize: number, branch: any) {
  return axios.get<{data: WaybillResponseData[]; meta: Meta}>(
    `${API_URL}/waybills/?filters[status][$eq]=${status}&pagination[page]=${page}&pagination[pageSize]=${pageSize}&sort[0]=createdAt:desc&filters[branch][$eq]=${branch}`
  )
}

export function getWaybillsByDateRange(start: string, end: string, branch: any) {
  return axios.get<Waybills>(
    `${API_URL}/waybills/?filters[$and][0][date][$gte]=${start}&filters[$and][1][date][$lte]=${end}&pagination[pageSize]=300&filters[branch][$eq]=${branch}`
  )
}

export function getWaybillsByManifestId(manifestId: number) {
  return axios.get<Waybills>(`${API_URL}/waybills/?filters[manifest_id][$eq]=${manifestId}&sort[0]=manifest_sequence:asc`)
}

export function getWaybillsByAccountId(accountId: number) {
  return axios.get<Waybills>(`${API_URL}/waybills/?filters[account_id][$eq]=${accountId}&pagination[pageSize]=150`)
}

export function getWaybillByNo(waybillNo: number, branch: any) {
  return axios.get<{data: WaybillResponseData[]; meta: Meta}>(
    `${API_URL}/waybills/?filters[waybill_no][$eq]=${waybillNo}&filters[branch][$eq]=${branch}`
  )
}

export function getWaybillByNoAndStatus(waybillNo: number, status: number, branch: any) {
  return axios.get<{data: WaybillResponseData[]; meta: Meta}>(
    `${API_URL}/waybills/?filters[status][$eq]=${status}&filters[waybill_no][$eq]=${waybillNo}&filters[branch][$eq]=${branch}`
  )
}

export function getWaybillItems(waybillId: number) {
  return axios.get<WaybillItemsResponseData>(
    `${API_URL}/waybill-items/?filters[waybill_id][$eq]=${waybillId}&sort[0]=line:asc`
  )
}

export function saveWaybill(waybill: WaybillModel) {
  return axios.post<WaybillCreateResponse>(`${API_URL}/waybills`, {data: waybill})
}

export function saveWaybillItem(item: WaybillItemModel) {
  return axios.post(`${API_URL}/waybill-items`, {data: item})
}

export function updateWaybill(id: number, dataToUpdate: any) {
  return axios.put(`${API_URL}/waybills/${id}`, {data: dataToUpdate})
}

export function updateWaybillItems(id: number, dataToUpdate: WaybillItemModel) {
  return axios.put(`${API_URL}/waybill-items/${id}`, {data: dataToUpdate})
}

export function deleteWaybillItem(id: number) {
  return axios.delete(`${API_URL}/waybill-items/${id}`)
}

export function searchContact(input: string, type: string) {
  if (type === 'consignee') {
    return axios.get<{data: ContactDetailsData[]; meta: Meta}>(
      `${API_URL}/consignees/?filters[name][$contains]=${input}`
    )
  } else {
    return axios.get<{data: ContactDetailsData[]; meta: Meta}>(
      `${API_URL}/shippers/?filters[name][$contains]=${input}`
    )
  }
}


export function saveContact(type: string, contactData: ContactDetails) {
  if (type === 'consignee') {
    return axios.post<any>(`${API_URL}/consignees`, {data: contactData})
  } else {
    return axios.post<any>(`${API_URL}/shippers`, {data: contactData})
  }
}
