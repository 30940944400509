import { FC } from 'react'
import { PDFViewer, StyleSheet } from '@react-pdf/renderer';
import { useParams } from 'react-router-dom';
import { ManifestPrint } from './ManifestPrint';

const styles = StyleSheet.create({
  viewer: {
    width: '100%',
    height: '100%'
  }
});

const ManifestPrintPage: FC = () => {
  const { id } = useParams() as { id: string }

  return (
    <>
      <a href={`/section/manifests/details/${id}`} className='btn btn-light-primary my-2 '>{'< Back'}</a>
      <PDFViewer style={styles.viewer}>
        <ManifestPrint id={Number(id)} />
      </PDFViewer>
    </>

  )
}
export { ManifestPrintPage }
