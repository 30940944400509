/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { KTSVG } from '../../../../_metronic/helpers'
import DataTable, { TableColumn, createTheme } from 'react-data-table-component'
import { WaybillResponseData } from '../../../model/models';
import { getWaybills, searchWaybillList } from '../redux/WaybillsApi';
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { fetched } from '../redux/WaybillSlice';
import { RootState } from '../../../../setup';
import { UserModel } from '../../auth';
import moment from 'moment';


type Props = {
  className: string
}

const caseInsensitiveSort = (rowA: WaybillResponseData, rowB: WaybillResponseData) => {
  const a = rowA.attributes.consignee.toLowerCase();
  const b = rowB.attributes.consignee.toLowerCase();

  if (a > b) {
    return 1;
  }

  if (b > a) {
    return -1;
  }

  return 0;
};

const columns: TableColumn<WaybillResponseData>[] = [
  {
    name: 'WAYBILL#',
    cell: row => (
      <a href={`/section/waybills/details/${row.id}`}>{row.attributes.waybill_no}</a>
    ),
    sortable: true,
    width: "120px"
  },
  {
    name: 'SHIPPER',
    selector: row => row.attributes.shipper_name,
    cell: row => {
      if (row.attributes.shipper_name === "0") {
        return "";
      } else {
        return row.attributes.shipper_name
      }
    }
  },
  {
    name: 'CONSIGNEE',
    selector: row => row.attributes.consignee,
    cell: row => row.attributes.consignee,
    sortable: true,
    sortField: 'consignee',
    sortFunction: caseInsensitiveSort
  },
  {
    name: 'DESTINATION',
    selector: row => row.attributes.address,
    cell: row => row.attributes.address
  },
  {
    name: 'STATUS',
    width: "120px",
    selector: row => row.attributes.status,
    cell: row => {
      if (row.attributes.status === 1) {
        return (
          <span className='badge badge-light-primary fs-8 fw-bold my-2'>Warehouse</span>
        )
      } else if (row.attributes.status === 2) {
        return (
          <span className='badge badge-light-warning fs-8 fw-bold my-2'>InTransit</span>
        )
      } else if (row.attributes.status === 3) {
        return <span className='badge badge-light-info fs-8 fw-bold my-2'>Arrived</span>
      } else if (row.attributes.status === 5) {
        return <span className='badge badge-light-danger fs-8 fw-bold my-2'>Loaded</span>
      } else {
        return <span className='badge badge-light-success fs-8 fw-bold my-2'>Delivered</span>
      }
    },
    sortable: true
  },
  {
    name: 'DATE',
    selector: row => moment(row.attributes.date).format('MM/DD/YYYY'),
    sortable: true,
    //sortField: 'date',
    width: "120px"
  },
  {
    name: 'AMOUNT',
    selector: row => row.attributes.total_freight_amount,
    format: row => row.attributes.total_freight_amount.toLocaleString('en-US', { minimumFractionDigits: 2 }),
    sortable: true,
    width: "120px"
  },
  {
    name: 'CHARGE',
    width: "120px",
    selector: row => row.attributes.charge_to,
    cell: row => {
      if (row.attributes.charge_to === 1) {
        return (
          <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-7'>Collect</span>
        )
      } else if (row.attributes.charge_to === 2) {
        return (
          <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-7'>Prepaid</span>
        )
      } else {
        return (
          <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-7'>Account</span>
        )
      }
    }
  },
  {
    name: 'ACTION',
    width: '80px',
    button: true,
    cell: row => (
      <div>
        <a
          href={`/section/waybills/details/${row.id}`}
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          title='View details'
        >
          <KTSVG path='/media/icons/duotune/general/gen059.svg' className='svg-icon-3' />
        </a>
        <a href={`/section/waybills/print/${row.id}`} className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          title='Print waybill'>
          <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-3' />
        </a>
      </div>
    )
  }
];

createTheme('solarized', {
  text: {
    primary: '#268bd2',
    secondary: '#2aa198',
  },
  background: {
    default: '#002b36',
  },
  context: {
    background: '#cb4b16',
    text: '#FFFFFF',
  },
  divider: {
    default: '#073642',
  },
  action: {
    button: 'rgba(0,0,0,.54)',
    hover: 'rgba(0,0,0,.08)',
    disabled: 'rgba(0,0,0,.12)',
  },
}, 'dark');

const customStyles = {
  headCells: {
    style: {
      fontWeight: 'bold'
    },
  }
};


const WaybillView: React.FC<Props> = ({ className }) => {
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
  const waybillData: WaybillResponseData[] = useSelector<RootState>(({ waybills }) => waybills.data?.data, shallowEqual) as WaybillResponseData[]
  const branch = useSelector<RootState>(({ configs }) => configs.branchId, shallowEqual) as string
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1)
  const [totalRows, setTotalRows] = useState(0)
  const [pageSize, setPageSize] = useState(25)
  const [field, setField] = useState('waybill_no')
  const [searchLabel, setSearchLabel] = useState('waybill #')
  const [searchText, setSearchText] = useState('')

  const loadWaybills = React.useCallback((fieldSearch: string, search: string, page: number, pageSize: number) => {
    setTimeout(() => {
      if (search !== null || search  !== "") {
        searchWaybillList(fieldSearch, search, page, pageSize, branch).then((searchResponse) => {
          setTotalRows(searchResponse.data.meta.pagination.total);
          setPageSize(searchResponse.data.meta.pagination.pageSize);
          dispatch(fetched(searchResponse.data))
          setLoading(false)
        })
      } else {
        getWaybills(page, pageSize, branch).then((response) => {
          setTotalRows(response.data.meta.pagination.total);
          setPageSize(response.data.meta.pagination.pageSize);
          dispatch(fetched(response.data))
          setLoading(false)
        });
      }
    }, 1000)
  }, [dispatch, branch])

  useEffect(() => {
    setLoading(true)
    console.log(branch)
    //setBranchId(branch)
    const delayDebounce = setTimeout(() => {
      console.log(searchText)
      if (searchText.length > 3) {
        searchWaybillList(field, searchText, page, pageSize, branch).then((response) => {
          setTotalRows(response.data.meta.pagination.total);
          setPageSize(response.data.meta.pagination.pageSize);
          dispatch(fetched(response.data))
          setLoading(false)
        })
      } else {
        loadWaybills(field, searchText, page, pageSize)
      }

    }, 1000)

    return () => clearTimeout(delayDebounce)
  }, [searchText, branch])

  const handlePageChange = (page: number) => {
    setPage(page)
    loadWaybills(field, searchText, page, pageSize);
  }

  const handlePerRowsChange = async (pageSize: number, page: number) => {
    setLoading(true);
    loadWaybills(field, searchText, page, pageSize);
    setPageSize(pageSize);
    setLoading(false);
  }

  const searchOption = (e: React.ChangeEvent<HTMLInputElement>) => {
    setField(e.currentTarget.value)
    if (e.currentTarget.value === 'waybill_no') {
      setSearchLabel('waybill #')
    } else if (e.currentTarget.value === 'shipper_name') {
      setSearchLabel('shipper')
    } else if (e.currentTarget.value === 'consignee') {
      setSearchLabel('consignee')
    } else {
      setSearchLabel('destination')
    }
  }

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Recent Waybills</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>Total of {totalRows.toLocaleString()} waybills</span>
        </h3>
        <div className='card-toolbar d-flex flex-row justify-content-between'>
          <div className='form-check form-check-custom form-check-solid mx-5'>
            <input className='form-check-input' type='radio' value='waybill_no' checked={field === 'waybill_no'} name='search' onChange={searchOption} />
            <label className='form-check-label' htmlFor='flexRadioDefault'>
              Waybill #
            </label>
          </div>
          <div className='form-check form-check-custom form-check-solid mx-5'>
            <input className='form-check-input' type='radio' value='shipper_name' checked={field === 'shipper_name'} name='search' onChange={searchOption} />
            <label className='form-check-label' htmlFor='flexRadioDefault'>
              Shipper
            </label>
          </div>
          <div className='form-check form-check-custom form-check-solid mx-5' >
            <input className='form-check-input' type='radio' value='consignee' checked={field === 'consignee'} name='search' onChange={searchOption} />
            <label className='form-check-label' htmlFor='flexRadioDefault'>
              Consignee
            </label>
          </div>
          <div className='form-check form-check-custom form-check-solid mx-5'>
            <input className='form-check-input' type='radio' value='address' checked={field === 'address'} name='search' onChange={searchOption} />
            <label className='form-check-label' htmlFor='flexRadioDefault'>
              Destination
            </label>
          </div>

          <div className='col-xs-2'>
            <input type='text' className='form-control col-md-2' placeholder={`Search ${searchLabel}`} autoComplete='new-password' autoFocus value={searchText}
              onChange={(e) => { 
                setSearchText(e.currentTarget.value) 
                }} />
          </div>

        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <DataTable columns={columns}
            data={waybillData}
            striped
            selectableRows
            selectableRowsHighlight
            pointerOnHover
            progressPending={loading}
            pagination
            paginationPerPage={pageSize}
            paginationServer
            paginationTotalRows={totalRows}
            customStyles={customStyles}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            className='table-responsive' />
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export { WaybillView }