/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { KTSVG } from '../../../../_metronic/helpers'
import DataTable, { TableColumn } from 'react-data-table-component'
import { AccountData, CustomerData, Customers, ManifestData, TruckData } from '../../../model/models';
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import moment from 'moment';
import { getAccounts, getCustomers } from '../redux/AccountsApi';
import { fetched } from '../redux/CustomerSlice';
import { RootState } from '../../../../setup';
import Select, { ActionMeta, InputActionMeta, SingleValue } from 'react-select'

type Props = {
  className: string
}


const columns: TableColumn<AccountData>[] = [
  {
    name: 'REF #',
    cell: row => (
      <a href={`/section/accounts/details/${row.id}`}>{row.attributes.account_no}</a>
    ),
    sortable: true,
    width: '100px'
  },
  {
    name: 'STATUS',
    selector: row => row.attributes.status,
    width: '100px',
    cell: row => {
      if (row.attributes.status === 0) {
        return (
          <span className='badge badge-secondary fs-8 fw-bold my-2'>Unpaid</span>
        )
      } else if (row.attributes.status === 1) {
        return (
          <span className='badge badge-light-primary fs-8 fw-bold my-2'>Acknowledged</span>
        )
      } else if (row.attributes.status === 2) {
        return <span className='badge badge-light-success fs-8 fw-bold my-2'>FullPayment</span>
      } else if (row.attributes.status === 3) {
        return <span className='badge badge-light-warning fs-8 fw-bold my-2'>Installment</span>
      } else { return <span className='badge badge-light-danger fs-8 fw-bold my-2'>Loaded</span> }
    }
  },
  {
    name: 'CLIENTS',
    selector: row => row.attributes.customer_id,
    cell: row => <CustomerView customer_id={row.attributes.customer_id} />,
  },
  {
    name: 'STATEMENT DATE',
    selector: row => moment(row.attributes.statement_date).format('MM/DD/YYYY'),
    sortable: true,
    sortField: 'statement_date',
    width: '160px'
  },
  {
    name: 'DUE DATE',
    width: "150px",
    selector: row => moment(row.attributes.due_date).format('MM/DD/YYYY'),
    sortable: true,
    sortField: 'departure_date'
  },
  {
    name: 'TOTAL AMOUNT',
    selector: row => row.attributes.total_amount,
    format: row => row.attributes.total_amount.toLocaleString(),
    sortable: true,
    sortField: 'total_amount',
    width: '150px',
    //sortFunction: sortingArrivalDate
  },
  {
    name: 'TOTAL PAID',
    selector: row => row.attributes.total_paid.toLocaleString(),
    sortable: true,
    sortField: 'total_paid',
    width: '120px'
  },
  {
    name: 'ACTION',
    width: '80px',
    button: true,
    cell: row => (
      <div>
        <a
          href={`/section/accounts/details/${row.id}`}
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          title='View details'
        >
          <KTSVG path='/media/icons/duotune/general/gen059.svg' className='svg-icon-3' />
        </a>
        <a href={`/section/accounts/print/${row.id}`} className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          title='Print waybill'>
          <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-3' />
        </a>
      </div>
    )
  }
];

const customStyles = {
  headCells: {
    style: {
      fontWeight: 'bold'
    },
  }
};


const StatementsView: React.FC<Props> = ({ className }) => {
  const [accountsData, setAccounts] = useState<AccountData[]>([]);
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [totalRows, setTotalRows] = useState(0)
  const [pageSize, setPageSize] = useState(25)
  const [status, setStatus] = useState(-1)
  const [customer, setCustomer] = useState(-1)
  const customerData: CustomerData[] = useSelector<RootState>(({ customers }) => customers.data?.data, shallowEqual) as CustomerData[]
  const branch = useSelector<RootState>(({ configs }) => configs.branchId, shallowEqual) as string

  const loadAccounts = React.useCallback((customer: number, status: number, page: number, pageSize: number, branch_id: any) => {
    setTimeout(() => {
      getAccounts(customer, status, page, pageSize, branch_id).then((response) => {
        setTotalRows(response.data.meta.pagination.total);
        setPageSize(response.data.meta.pagination.pageSize);
        setAccounts(response.data.data)
        getCustomers().then((response) => {
          dispatch(fetched(response.data))
        })
        setLoading(false)
      });
    }, 2000)
  }, [])

  useEffect(() => {
    setLoading(true)
    loadAccounts(customer, status, 1, pageSize, branch)
  }, [loadAccounts, pageSize, customer, branch])

  const handlePageChange = (page: number) => {
    loadAccounts(customer, status, page, pageSize, branch);
  }

  const handlePerRowsChange = async (page: number, pageSize: number) => {
    setLoading(true);
    loadAccounts(customer, status, page, pageSize, branch);
    setPageSize(pageSize);
    setLoading(false);
  }

  const onCustomerChange = (value: SingleValue<CustomerData>, a: ActionMeta<CustomerData>) => {
    setCustomer(value?.attributes?.customer_id!)
    loadAccounts(customer, status, 1, pageSize, branch)
  }

  const onStatusChange = (value: SingleValue<any>, a: ActionMeta<any>) => {
    if(value !== null) {
      setStatus(value.value)
      loadAccounts(customer, value.value, 1, pageSize, branch)
    } else {
      setStatus(-1)
      loadAccounts(customer, -1, 1, pageSize, branch)
    }

  }

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Statement of Accounts</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>Total of {totalRows.toLocaleString()} biilings</span>
        </h3>
        <div className='card-toolbar min-w-600px d-flex flex-row justify-content-between'>
          <div className='col-md-7 px-5'>
            <Select
              name='customer_id'
              isClearable
              className='fw-bold'
              isSearchable
              onChange={(e, a) => {
                onCustomerChange(e, a)
              }}
              options={customerData}
              getOptionValue={(option) => { return `${option.attributes.customer_id}` }}
              getOptionLabel={(option) => { return `${option.attributes.name}` }}
            />
          </div>
          <div className='col-md-5 px-5'>
            <Select
              name='status_id'
              isClearable
              className='fw-bold'
              onChange={(e, a) => {
                onStatusChange(e, a)
              }}
              options={[
                {value: 0, label: 'Unpaid'},
                {value: 1, label: 'Acknowledged'},
                {value: 2, label: 'Full Payment'},
                {value: 3, label: 'Installment'},
                {value: 4, label: 'OverDue'}
              ]}
            />
          </div>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <DataTable columns={columns}
            data={accountsData}
            striped
            selectableRows
            selectableRowsHighlight
            pointerOnHover
            progressPending={loading}
            pagination
            paginationPerPage={pageSize}
            paginationServer
            paginationTotalRows={totalRows}
            customStyles={customStyles}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
          />
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export { StatementsView }

type CustomerViewProp = {
  customer_id: number
}

const CustomerView: React.FC<CustomerViewProp> = ({ customer_id }) => {
  const customerData: Customers = useSelector<RootState>(({ customers }) => customers.data, shallowEqual) as Customers
  const [selectedCustomer, setSelectedCustomer] = useState<CustomerData>();
  useEffect(() => {
    let customer = customerData.data.find(c => c.attributes.customer_id === customer_id)
    if (customer === undefined) {
      customer = customerData.data.find(c => c.id === customer_id)
    }
    setSelectedCustomer(customer)
  }, [selectedCustomer, customerData])
  return (
    <>
      {
        selectedCustomer?.attributes.name
      }
    </>
  )
}