import React from 'react'
import {Navigate, Route, Routes, Outlet} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import { ManifestCreatePage } from './components/ManifestCreatePage'
import { ManifestDetailsPage } from './components/ManifestDetailsPage'
import { ManifestPrintPage } from './components/ManifestPrintPage'
import {ManifestViewPage} from './components/ManifestViewPage'

const manifestBreadCrumbs: Array<PageLink> = [
  {
    title: 'Trucking Manifests',
    path: '/section/manifests/view',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ManifestPage: React.FC = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
  
        <Route
          path='view'
          element={
            <>
              <PageTitle breadcrumbs={manifestBreadCrumbs}>View</PageTitle>
              <ManifestViewPage />
            </>
          }
        />
        <Route
          path='create'
          element={
            <>
              <PageTitle breadcrumbs={manifestBreadCrumbs}>Create</PageTitle>
              <ManifestCreatePage />
            </>
          }
        />
          <Route
          path='details/:id'
          element={
            <>
              <PageTitle breadcrumbs={manifestBreadCrumbs}>View Manifest Details</PageTitle>
              <ManifestDetailsPage />
            </>
          }
        />
                <Route
          path='print/:id'
        
          element={
            <>
              <PageTitle breadcrumbs={manifestBreadCrumbs}>Print</PageTitle>
              <ManifestPrintPage />
            </>
          }
        />
        <Route index element={<Navigate to='/section/manifests/view' />} />
      </Route>
    </Routes>
  )
}
export default ManifestPage
