import Select, { ActionMeta, InputActionMeta } from 'react-select'
import { WaybillIdNo } from "../../../model/models"
import { NumericFormat } from 'react-number-format'
import { useState } from 'react'
import clsx from 'clsx'

type SelectProps = {
    waybill: WaybillIdNo[] | undefined
    load: (input: string, a: InputActionMeta) => void
    select: (input: number, a: ActionMeta<WaybillIdNo>) => void
    addWaybill: (no: number) => void,
    isValid: boolean
}

const SelectComponentManifest: React.FC<SelectProps> = ({ waybill, load, select, addWaybill, isValid }) => {
    const [waybillNo, setWaybillNo] = useState(0)
    const onChangeWaybillNo =(e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.currentTarget
        setWaybillNo(Number(value))
    }
    return (
        <div className='d-flex flex-row'>
            <Select
                className={'w-50 mx-10'}
                isClearable
                isSearchable
                options={waybill}
                placeholder={'Enter waybill # or consignee'}
                onInputChange={(e, a) => load(e, a)}
                controlShouldRenderValue
                onChange={(e, a) => select(e?.value!, a)}
            />

            <div className='w-50 input-group input-group-sm col-xs-2 z-index-0'>
                <NumericFormat
                    maxLength={10}
                    placeholder='Waybill #'
                    value={waybillNo}
                    onChange={onChangeWaybillNo}
                    className={clsx(
                        'form-control form-control-sm fw-bold',
                        { 'is-invalid': !isValid },
                        {
                            'is-valid': isValid,
                        }
                    )} />
                <a className="btn btn-primary" type="button" id="button-addon2" onClick={() => { addWaybill(waybillNo) }}>Add</a>
            </div>

        </div>
    )
}

export { SelectComponentManifest }