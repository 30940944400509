import moment from 'moment'
import * as Yup from 'yup'
import { Manifest } from '../../../model/models'

export interface IDetailsManifest extends Manifest {

}

const detailsManifestSchemas = [
  Yup.object({
    manifest_no: Yup.number()
      .min(1, 'The minimum # is 1')
      .required('The Manifest # is required'),
    description: Yup.string().required('Please enter manifest description'),
    destination: Yup.string().required('Please enter manifest destination'),
    driver: Yup.string().required('Please enter drivers name'),
    truck_id: Yup.number()
      .min(1, 'Please select cargo truck')
      .required('Please select cargo truck'),
    percentage: Yup.number().min(0).max(100).required('Please enter range from 0 to 100')
  }),
]


const inits: IDetailsManifest = {
  manifest_no: localStorage.getItem('manifest_no_last')?.toString()!,
  description: '',
  destination: 'Mindoro',
  departure_date: moment().toDate(),
  arrival_date: moment().add(1, 'day').toDate(),
  deleted: false,
  percentage: 34,
  createdAt: moment().toDate(),
  updatedAt: moment().toDate(),
  manifest_id: 0,
  truck_id: 0,
  status_id: 2,
  driver: '',
  user_id: 0,
  branch: ''
};

export {detailsManifestSchemas, inits}
