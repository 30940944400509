import { FC } from 'react'
import { PDFViewer, StyleSheet } from '@react-pdf/renderer';
import { useParams } from 'react-router-dom';
import { SOAPrint } from './SOAPrint';

const styles = StyleSheet.create({
  viewer: {
    width: '100%',
    height: '100%'
  }
});

const SOAPrintPage: FC = () => {
  const { id } = useParams() as { id: string }
  return (
    <>
      <a href={`/section/accounts/details/${id}`} className='btn btn-light-primary my-2 '>{'< Back'}</a>
      <PDFViewer style={styles.viewer}>
        <SOAPrint id={Number(id)} />
      </PDFViewer>
    </>
  )
}
export { SOAPrintPage }
