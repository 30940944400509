/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import { KTCard, KTCardBody, KTSVG } from '../../../../_metronic/helpers'
import { ContactDetails, ContactDetailsData, WaybillItemModel } from '../../../model/models';
import { UserModel } from '../../../../app/modules/auth/core/_models';
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../../setup';
import { Formik, Form, Field, ErrorMessage, useFormikContext, FieldArray, FormikHelpers } from 'formik'
import { createWaybillSchemas, ICreateWaybill, inits, waybillItemModel } from './helpers/WaybillCreateHelper'
import DatePicker from "react-datepicker";
import { Shipper } from './dropdowns/Shipper'
import { Consignee } from './dropdowns/Consignee'
import { NumberFormatValues, NumericFormat, SourceInfo } from 'react-number-format'
import { WaybillItems } from './WaybillItems';
import { saveWaybill, saveWaybillItem, updateWaybill, getWaybillByNo, saveContact } from '../redux/WaybillsApi';
import Swal from 'sweetalert2'
import { useNavigate } from "react-router-dom";
import clsx from 'clsx';
import moment from 'moment';
import { ContactDetailsControl } from './dropdowns/ContactDetailsControl';
import { ActionMeta, InputActionMeta, SingleValue } from 'react-select';
import { searchContact } from '../redux/WaybillsApi';

type Props = {
  className: string
}

const WaybillCreate: React.FC<Props> = ({ className }) => {
  const navigate = useNavigate();
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
  const branch = useSelector<RootState>(({ configs }) => configs.branchId, shallowEqual) as string
  const [loading, setLoading] = useState(false)
  const [currentSchema] = useState(createWaybillSchemas[0])
  const [initValues, setInitValues] = useState<ICreateWaybill>(inits)
  const [waybillNoValid, setWaybillNoValid] = useState(false)
  const [shippers, setShippers] = useState<ContactDetailsData[]>([])
  const [consignees, setConsignees] = useState<ContactDetailsData[]>([])
  const [saveShipper, setSaveShipper] = useState<boolean>(false)
  const [saveConsignee, setSaveConsignee] = useState<boolean>(false)
  const [selectedShipper, setSelectedShipper] = useState<ContactDetailsData>(
    {
      id: 0,
      attributes: {
        name: 'Search shippper',
        address: '',
        contact_no: '',
        value: 0,
        label: ''
      } as ContactDetails
    }
  )
  const [selectedConsignee, setSelectedSConsignee] = useState<ContactDetailsData>(
    {
      id: 0,
      attributes: {
        name: 'Search consignee',
        address: '',
        contact_no: '',
        value: 0,
        label: ''
      } as ContactDetails
    }
  )
  useEffect(() => {
    console.log(initValues)
    var data = { ...initValues }
    data.received_by = `${user.firstName} ${user.lastName}`
    data.date = moment().toDate()
    setInitValues(data)
    getWaybillByNo(Number(initValues.waybill_no), branch).then((response) => {
      setWaybillNoValid(response.data.meta.pagination.total > 0)
    })
  }, [waybillNoValid]);

  const submit = async (values: ICreateWaybill, { setStatus, setSubmitting }: FormikHelpers<ICreateWaybill>) => {
    setLoading(true)
    localStorage.setItem('waybill_no_last', values.waybill_no.toString())
    setTimeout(() => {
      values.guid = crypto.randomUUID()
      values.createdby_id = user.id
      values.item_description = values.items.map(i => i.description).join(' / ')
      values.total_quantity = values.items.reduce((a, v) => a = a + (v.quantity === undefined ? 0 : v.quantity), 0)
      values.branch = branch
      if (saveConsignee) {
        saveContact('consignee', {
          name: values.consignee,
          address: values.address,
          contact_no: values.mobile
        } as ContactDetails)
      }
      if (saveShipper) {
        saveContact('shipper', {
          name: values.shipper_name,
          address: values.shipper_address,
          contact_no: values.shipper_contact_no
        } as ContactDetails)
      }
      saveWaybill(values)
        .then((response) => {
          if (response.status === 200) {
            updateWaybill(response.data.data.id, { "waybill_id": response.data.data.id })
            values.items.forEach((item) => {
              item.waybill_id = response.data.data.id
              item.id = 0
              setTimeout(() => {
                saveWaybillItem(item);
              }, 500)
            })
            setLoading(false)
            Swal.fire({
              title: 'Successs!',
              text: `Waybill # ${values.waybill_no} has been successfully save!`,
              icon: 'success',
              confirmButtonText: 'Print',
              cancelButtonText: 'Views',
              showCancelButton: true,
              customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-primary'
              }
            }).then((result) => {
              if (result.isConfirmed) {
                navigate(`/section/waybills/print/${response.data.data.id}`);
              } else if (result.dismiss === Swal.DismissReason.cancel) {
                navigate('/section/waybills/view');
              }
            })
          }
        })
        .catch((error) => {
          setLoading(false)
          setSubmitting(false)
          setStatus('Problem occured when saving your waybill.')
          Swal.fire({
            title: 'Error!',
            text: 'Problem occured when creating your waybill',
            icon: 'error',
            confirmButtonText: 'OK',
            iconColor: 'primary'
          })
          console.error(error)
        })
    }, 2000)

  }

  const setDateNowChange = (e: Date) => {
    const data = { ...initValues };
    data.date = e
    setInitValues(data);
  }
  const setRecievedDateChange = (e: Date) => {
    const data = { ...initValues };
    data.received_date = e;
    setInitValues(data);
  }
  const shipperUpdateEvent = (contact: ContactDetails) => {
    const data = { ...initValues };
    data.shipper_name = contact.name;
    data.shipper_address = contact.address;
    data.shipper_contact_no = contact.contact_no;
    setInitValues(data);
  }
  const consigneeUpdateEvent = (contact: ContactDetails) => {
    const data = { ...initValues };
    data.consignee = contact.name;
    data.address = contact.address;
    data.mobile = contact.contact_no;
    setInitValues(data);
  }
  const handleStringValueUpdate = (id: number, e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const data = { ...initValues }
    const updateItems = data.items.map((item) => {
      if (item.id === id) {
        return { ...item, description: value }
      }
      return item;
    });
    const updateData = { ...data, items: updateItems };

    setInitValues(updateData);
  }
  const handleNumericValueUpdate = (id: number, values: NumberFormatValues, event: SourceInfo) => {
    const data = { ...initValues };
    const dataItems = [...data.items]
    const updatedItems = dataItems.map((item, index) => {
      if (item.id === id) {
        if (event.event?.currentTarget.name === 'quantity') {
          return { ...item, quantity: values.floatValue }
        } else if (event.event?.currentTarget.name === 'weight') {
          return { ...item, weight: values.floatValue }
        } else {
          return { ...item, freight_amount: values.floatValue }
        }
      }
      return item;
    })
    const updatedData = { ...data, items: updatedItems };
    updatedData.total_freight_amount = updatedData.items.reduce((a, v) => a = a + (v.freight_amount === undefined ? 0 : v.freight_amount), 0)
    updatedData.total_weight = updatedData.items.reduce((a, v) => a = a + (v.weight === undefined ? 0 : v.weight), 0)
    updatedData.total_freight_vat = updatedData.total_freight_amount * .12;
    setInitValues(updatedData);
  }
  const addNewItemClickHandler = () => {
    const data = { ...initValues }
    const itemModel = { ...waybillItemModel }
    itemModel.id = initValues.items[initValues.items.length - 1].id + 1;
    itemModel.line = initValues.items[initValues.items.length - 1].id + 1;

    const updatedItems = [...initValues.items, itemModel]
    const updatedData = { ...data, items: updatedItems }
    setInitValues(updatedData)
  }
  const deleteItemClickHandler = (value: number) => {
    const data = { ...initValues }
    const dataItems = [...data.items]
    const filteredItems = dataItems.filter(item => item.line !== value);
    const updatedData = { ...data, items: filteredItems }
    updatedData.total_freight_amount = filteredItems.reduce((a, v) => a = a + (v.freight_amount === undefined ? 0 : v.freight_amount), 0)
    updatedData.total_weight = filteredItems.reduce((a, v) => a = a + (v.weight === undefined ? 0 : v.weight), 0)
    updatedData.total_freight_vat = updatedData.total_freight_amount * .12;
    setInitValues(updatedData)
  }
  const onFreightAmountChange = (values: NumberFormatValues) => {
    const data = { ...initValues }
    data.total_freight_amount = values.floatValue!;
    setInitValues(data)
  }
  const onDiscountChange = (values: NumberFormatValues) => {
    const data = { ...initValues }
    data.scpwd_discount = values.floatValue!;
    setInitValues(data)
  }
  const onTotalFreightPaidAmount = (values: NumberFormatValues) => {
    const data = { ...initValues }
    data.total_freight_paid = values.floatValue!
    const total = data.total_freight_amount + data.scpwd_discount;
    if (data.total_freight_paid >= total) {
      data.paid = true;
    }
    setInitValues(data)
  }
  const onSalesInvoiceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    const data = { ...initValues, invoice_no: value }
    setInitValues(data)
  }
  const onReferenceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    const data = { ...initValues, reference: value }
    setInitValues(data)
  }
  const onReceivedByChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    const data = { ...initValues, received_by: value }
    setInitValues(data)
  }
  const onStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.currentTarget;
    const data = { ...initValues }
    data.status = Number(value)
    setInitValues(data)
  }
  const onChargeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const data = { ...initValues }
    data.charge_to = Number(e.currentTarget.value)
    setInitValues(data)
  }
  const onWaybillNoChange = (e: NumberFormatValues) => {
    const data = { ...initValues }
    data.waybill_no = e.floatValue!
    setInitValues(data);
    getWaybillByNo(Number(e.value), branch).then((response) => {
      setWaybillNoValid(response.data.meta.pagination.total > 0)
    })
  }

  const loadContactShipperDetails = (input: string, a: InputActionMeta) => {
    if (a.action === 'input-change' && input.length > 2) {
      searchContact(input, 'shipper').then((response) => {
        setShippers(response.data.data)
      })
    }
  }

  const loadContactConsigneeDetails = (input: string, a: InputActionMeta) => {
    if (a.action === 'input-change' && input.length > 2) {
      searchContact(input, 'consignee').then((response) => {
        setConsignees(response.data.data)
      })
    }
  }

  const selectContactDetails = (input: SingleValue<ContactDetailsData>, a: ActionMeta<ContactDetailsData>) => {
    if (a.action === 'select-option') {
      if (a.name === 'shipper') {
        const data = { ...initValues }
        data.shipper_name = input !== null ? input.attributes.name : ''
        data.shipper_address = input !== null ? input.attributes.address : ''
        data.shipper_contact_no = input !== null ? input.attributes.contact_no! : ''
        setInitValues(data)
      } else {
        const data = { ...initValues }
        data.consignee = input !== null ? input.attributes.name : ''
        data.address = input !== null ? input.attributes.address : ''
        data.mobile = input !== null ? input.attributes.contact_no! : ''
        setInitValues(data)
      }
    }
  }

  const onClickConsigneeSearchable = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSaveConsignee(e.currentTarget.checked)
  }

  const onClickShipperSearchable = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSaveShipper(e.currentTarget.checked)
  }

  return (
    <Formik validationSchema={currentSchema}
      initialValues={initValues}
      onSubmit={submit}
      enableReinitialize={true}
      validateOnChange={true}>
      {(prop) => (
        <div className={`card ${className}`}>
          <SaveWaybill loading={loading} waybillValid={waybillNoValid} />
          <div className='car-body'>
            <Form className='row sg-4 p-10' id='kt_create_waybill_form'>
              <div className="row">
                <div className='col-md-3'>
                  <label className='d-flex align-items-center form-label-sm fw-bold'>
                    <span className='required'>Waybill #</span>
                  </label>
                  <NumericFormat
                    maxLength={10}
                    name='waybill_no'
                    placeholder='Waybill #'
                    value={prop.values.waybill_no}
                    onValueChange={(values) => {
                      onWaybillNoChange(values);
                      prop.setFieldValue('waybill_no', values.value)
                    }}
                    className={clsx(
                      'form-control form-control-solid fw-bold',
                      { 'is-invalid': waybillNoValid },
                      {
                        'is-valid': !waybillNoValid,
                      }
                    )} />
                  <div className='text-danger mtc-2'>
                    <ErrorMessage name='waybill_no' />
                  </div>
                  <div className='form-text'>
                    Waybill # found in the waybill receipt
                  </div>
                </div>
                <div className='col-md-3'>
                  <label className='d-flex align-items-center form-label-sm fw-bold'>
                    <span className='required'>Date</span>
                  </label>
                  <DatePicker name='date'
                    showTimeSelect
                    required
                    selected={initValues.date}
                    onChange={(e: Date) => {
                      if (e === null) { e = new Date() }
                      setDateNowChange(e)
                      prop.setFieldValue('date', e)
                    }}
                    dateFormat={'MM/dd/yyy hh:mm aa'}
                    className='form-control form-control-solid fw-bold' />
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='date' />
                  </div>

                  <div className='form-text'>
                    Waybill Date
                  </div>
                </div>
                <div className='col-md-3'>
                  <label className='d-flex align-items-center form-label-sm fw-bold'>
                    <span className='required'>Status</span>
                  </label>
                  <Field
                    as='select'
                    name='status'
                    value={initValues.status}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      onStatusChange(e)
                      prop.setFieldValue(e.currentTarget.name, Number(e.currentTarget.value))
                    }}
                    className='form-select form-control-solid form-select-solid fw-bold'>
                    <option value='1'>Warehouse</option>
                    <option value='5'>Loaded</option>
                    <option value='2'>In Transit</option>
                    <option value='3'>Arrived</option>
                    <option value='4'>Delivered</option>
                  </Field>
                  <div className='form-text'>
                    Status of the shipment
                  </div>
                </div>
                <div className='col-md-3'>
                  <label className='d-flex align-items-center form-label-sm fw-bold'>
                    <span className='required'>Charge To</span>
                  </label>

                  <Field
                    as='select'
                    name='charge_to'
                    value={initValues.charge_to}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      onChargeChange(e)
                      prop.setFieldValue(e.currentTarget.name, Number(e.currentTarget.value))
                    }}
                    className='form-select form-control-solid form-select-solid fw-bold' >
                    <option value='1'>Collect</option>
                    <option value='2'>Prepaid</option>
                    <option value='3'>Account</option>
                  </Field>
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='charge_to' />
                  </div>

                  <div className='form-text'>
                    Client payment options
                  </div>
                </div>
              </div>
              <div className="row">
                <div className='col-md-3 mt-5'>
                  <KTCard className='shadow-sm mt-5'>
                    <div className='card-header min-h-10px'>
                      <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bold fs-base fw-bold mb-1 required'>Shipper</span>

                      </h3>
                      <div className='card-toolbar'>
                        <button
                          type='button'
                          className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                          data-kt-menu-trigger='click'
                          data-kt-menu-placement='bottom-end'
                          data-kt-menu-flip='top-end'
                        >
                          <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-2' />
                        </button>
                        <Shipper
                          name={prop.values.shipper_name}
                          contact_no={initValues.shipper_contact_no}
                          address={initValues.shipper_address}
                          searchable={saveShipper}
                          clickSearchable={onClickShipperSearchable}
                          shipperUpdateEventChange={shipperUpdateEvent}
                          onChange={(contact: ContactDetails) => {
                            prop.setFieldValue('shipper_name', contact.name)
                            prop.setFieldValue('shipper_address', contact.address)
                            prop.setFieldValue('shipper_contact_no', contact.contact_no)
                          }}
                        />
                      </div>
                    </div>
                    <ContactDetailsControl

                      options={shippers}
                      load={loadContactShipperDetails}
                      select={selectContactDetails}
                      type='shipper'
                      selectedValue={selectedShipper!} />
                    <KTCardBody className='py-4'>
                      {prop.errors.shipper_name &&
                        <label className='d-flex align-items-center form-label-sm fw-bold'>
                          <KTSVG
                            path='/media/icons/duotune/general/gen044.svg'
                            className='svg-icon-4 me-1 svg-icon-danger' />
                          <span className='px-2 py-2'>{prop.errors.shipper_name}</span>
                        </label>
                      }
                      {prop.errors.shipper_address &&
                        <label className='d-flex align-items-center form-label-sm fw-bold'>
                          <KTSVG
                            path='/media/icons/duotune/general/gen044.svg'
                            className='svg-icon-4 me-1 svg-icon-danger' />
                          <span className='px-2 py-2'>{prop.errors.shipper_address}</span>
                        </label>
                      }
                      {prop.errors.shipper_contact_no &&
                        <label className='d-flex align-items-center form-label-sm fw-bold'>
                          <KTSVG
                            path='/media/icons/duotune/general/gen044.svg'
                            className='svg-icon-4 me-1 svg-icon-danger' />
                          <span className='px-2 py-2'>{prop.errors.shipper_contact_no}</span>
                        </label>
                      }
                      <Field
                        type='text'
                        name='shipper_name'
                        className='d-none'
                        values={prop.values.shipper_name}
                        required onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          prop.setFieldValue(e.currentTarget.name, e.currentTarget.value)
                        }} />
                      {initValues.shipper_name &&
                        <label className='d-flex align-items-center form-label-sm fw-bold'>
                          <KTSVG
                            path='/media/icons/duotune/communication/com006.svg'
                            className='svg-icon-4 me-1 svg-icon-danger' />
                          <span className='px-2 py-2'>{prop.values.shipper_name}</span>
                        </label>
                      }
                      {initValues.shipper_contact_no &&
                        <label className='d-flex align-items-center form-label-sm fw-bold'>
                          <KTSVG
                            path='/media/icons/duotune/electronics/elc003.svg'
                            className='svg-icon-4 me-1 svg-icon-primary'
                          />
                          <span className='px-2 py-2'>{prop.values.shipper_contact_no}</span>

                        </label>
                      }
                      {initValues.shipper_address &&
                        <label className='d-flex align-items-center form-label-sm fw-bold'>
                          <KTSVG
                            path='/media/icons/duotune/general/gen018.svg'
                            className='svg-icon-4 me-1 svg-icon-success'
                          />
                          <span className='px-2 form-text'>{prop.values.shipper_address}</span>

                        </label>
                      }
                    </KTCardBody>
                  </KTCard>
                </div>
                <div className='col-md-3 mt-5'>
                  <KTCard className='shadow-sm mt-5'>
                    <div className='card-header min-h-10px'>
                      <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bold fs-base fw-bold mb-1 required'>Consignee</span>

                      </h3>
                      <div className='card-toolbar'>
                        <button
                          type='button'
                          className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                          data-kt-menu-trigger='click'
                          data-kt-menu-placement='bottom-end'
                          data-kt-menu-flip='top-end'
                        >
                          <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-2' />
                        </button>
                        <Consignee
                          name={initValues.consignee}
                          contact_no={initValues.mobile}
                          address={initValues.address}
                          searchable={saveConsignee}
                          clickSearchable={onClickConsigneeSearchable}
                          consigneeUpdateEventChange={(contact: ContactDetails) => {
                            consigneeUpdateEvent(contact)
                            prop.setFieldValue('consignee', contact.name)
                            prop.setFieldValue('address', contact.address)
                            prop.setFieldValue('mobile', contact.contact_no)
                          }} />
                      </div>
                    </div>
                    <ContactDetailsControl
                      options={consignees}
                      load={loadContactConsigneeDetails}
                      select={selectContactDetails}
                      type='consignee'
                      selectedValue={selectedConsignee!} />
                    <KTCardBody className='py-4'>
                      {prop.errors.consignee &&
                        <label className='d-flex align-items-center form-label-sm fw-bold'>
                          <KTSVG
                            path='/media/icons/duotune/general/gen044.svg'
                            className='svg-icon-4 me-1 svg-icon-danger' />
                          <span className='px-2 py-2'>{prop.errors.consignee}</span>
                        </label>
                      }
                      {prop.errors.address &&
                        <label className='d-flex align-items-center form-label-sm fw-bold'>
                          <KTSVG
                            path='/media/icons/duotune/general/gen044.svg'
                            className='svg-icon-4 me-1 svg-icon-danger' />
                          <span className='px-2 py-2'>{prop.errors.address}</span>
                        </label>
                      }
                      {prop.errors.mobile &&
                        <label className='d-flex align-items-center form-label-sm fw-bold'>
                          <KTSVG
                            path='/media/icons/duotune/general/gen044.svg'
                            className='svg-icon-4 me-1 svg-icon-danger' />
                          <span className='px-2 py-2'>{prop.errors.mobile}</span>
                        </label>
                      }
                      {initValues.consignee &&
                        <label className='d-flex align-items-center form-label-sm fw-bold'>
                          <KTSVG
                            path='/media/icons/duotune/communication/com006.svg'
                            className='svg-icon-4 me-1 svg-icon-info' />
                          <span className='px-2 py-2'>{initValues.consignee}</span>

                        </label>
                      }
                      {initValues.mobile &&
                        <label className='d-flex align-items-center form-label-sm fw-bold'>
                          <KTSVG
                            path='/media/icons/duotune/electronics/elc003.svg'
                            className='svg-icon-4 me-1 svg-icon-primary'
                          />
                          <span className='px-2 py-2'>{initValues.mobile}</span>
                        </label>
                      }
                      {initValues.address &&
                        <label className='d-flex align-items-center form-label-sm fw-bold'>
                          <KTSVG
                            path='/media/icons/duotune/general/gen018.svg'
                            className='svg-icon-4 me-1 svg-icon-success'
                          />
                          <span className='px-2 form-text'>{initValues.address}</span>

                        </label>
                      }
                    </KTCardBody>
                  </KTCard>
                </div>
                <div className='col-md-3 mt-5'>
                  <div className=''>
                    <label className='d-flex align-items-center form-label-sm fw-bold'>
                      <span className=''>Received By</span>
                    </label>

                    <Field
                      maxLength={20}
                      type='text'
                      name='received_by'
                      value={prop.values.received_by}
                      className='form-control form-control-solid fw-bold'
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        onReceivedByChange(e)
                        prop.setFieldValue(e.currentTarget.name, e.currentTarget.value)
                      }}
                    />
                    <div className='text-danger mtc-2'>
                      <ErrorMessage name='received_by' />
                    </div>

                    <div className='form-text'>
                      Received by
                    </div>
                  </div>
                  <div className=''>
                    <label className='d-flex align-items-center form-label-sm fw-bold mt-5'>
                      <span className=''>Received Date</span>
                    </label>

                    <DatePicker name='received_date'
                      showTimeSelect
                      selected={initValues.received_date}
                      onChange={(e: Date) => {
                        if (e === null) { e = new Date() }
                        setRecievedDateChange(e)
                        prop.setFieldValue('received_date', e)
                      }}
                      dateFormat={'MM/dd/yyyy'}
                      className='form-control form-control-solid fw-bold' />

                    <div className='text-danger mtc-2'>
                      <ErrorMessage name='received_date' />
                    </div>

                    <div className='form-text'>
                      Date received the items
                    </div>
                  </div>
                </div>
                <div className='col-md-3 mt-5'>
                  <div className='row'>
                    <label className='d-flex align-items-center form-label-sm fw-bold'>
                      <span className='required'>Total Freight Amount</span>
                    </label>
                    <NumericFormat
                      maxLength={12}
                      name='total_freight_amount'
                      placeholder='Waybill #'
                      value={initValues.total_freight_amount}
                      className='form-control form-control-solid fw-bold'
                      thousandSeparator={','}
                      decimalScale={2}
                      onValueChange={(values) => onFreightAmountChange(values)}
                    />
                    <div className='text-danger mtc-2'>
                      <ErrorMessage name='total_freight_amount' />
                    </div>

                    <div className='form-text'>
                      Total Freight Amount from items
                    </div>
                  </div>
                  <div className='row mt-5'>
                    <label className='d-flex align-items-center form-label-sm fw-bold'>
                      <span className='required'>Total Freight VAT</span>
                    </label>
                    <NumericFormat
                      maxLength={10}
                      name='total_freight_vat'
                      placeholder='Total Freight Vat'
                      value={initValues.items.reduce((a, v) => a = a + (v.freight_amount === undefined ? 0 : v.freight_amount), 0) * .12}
                      className='form-control form-control-solid fw-bold'
                      thousandSeparator={','}
                      allowLeadingZeros={false}
                      decimalScale={2}
                    />
                    <div className='text-danger mtc-2'>
                      <ErrorMessage name='total_freight_vat' />
                    </div>

                    <div className='form-text'>
                      Total Freight VAT 12%
                    </div>
                  </div>



                </div>
              </div>
              <div className='d-flex flex-row mt-5'>
                <div className='col-md-9'>
                  <FieldArray name='items'
                    render={
                      arrayHelpers => (
                        prop.errors.items !== undefined && prop.errors.items.length > 0 &&
                        (
                          <label className='d-flex align-items-center form-label-sm fw-bold mx-5'>
                            <KTSVG
                              path='/media/icons/duotune/general/gen044.svg'
                              className='svg-icon-4 me-1 svg-icon-danger' />
                            <span className='px-2 py-2'>Item description is required</span>
                          </label>
                        )
                      )
                    }
                  />
                  <WaybillItems
                    deleteItemClick={deleteItemClickHandler}
                    addNewItemClick={addNewItemClickHandler}
                    onStringUpdate={handleStringValueUpdate}
                    onNumericUpdate={handleNumericValueUpdate}
                    items={initValues.items}
                    className='card-custom shadow mb-5 mb-xl-8 me-5' />


                </div>
                <div className='col-md-3'>

                  <div className='row'>
                    <label className='d-flex align-items-center form-label-sm fw-bold'>
                      <span className='required'>SC PWD Discount</span>
                    </label>
                    <NumericFormat
                      maxLength={10}
                      name='total_freight_vat'
                      placeholder='Enter discount'
                      value={initValues.scpwd_discount}
                      className='form-control form-control-solid fw-bold'
                      thousandSeparator={','}
                      allowLeadingZeros={false}
                      decimalScale={2}
                      onValueChange={(values) => onDiscountChange(values)}
                    />
                    <div className='text-danger mtc-2'>
                      <ErrorMessage name='total_freight_vat' />
                    </div>

                    <div className='form-text'>
                      Senior Citizen or PWD Discount
                    </div>
                  </div>
                  <div className='row mt-5'>
                    <label className='d-flex align-items-center form-label-sm fw-bold'>
                      <span className='required'>Total Freight Paid</span>
                    </label>
                    <NumericFormat
                      maxLength={10}
                      name='total_freight_paid'
                      placeholder='Total Payment'
                      value={initValues.total_freight_paid}
                      className='form-control form-control-solid fw-bold'
                      thousandSeparator={','}
                      allowLeadingZeros={false}
                      decimalScale={2}
                      onValueChange={(values) => onTotalFreightPaidAmount(values)}
                    />
                    <div className='text-danger mtc-2'>
                      <ErrorMessage name='total_freight_paid' />
                    </div>

                    <div className='form-text'>
                      Total Payment
                    </div>
                  </div>
                  <div className='row mt-5'>
                    <label className='d-flex align-items-center form-label-sm fw-bold'>
                      <span className=''>Sales Invoice No</span>
                    </label>

                    <Field
                      maxLength={12}
                      type='text'
                      name='invoice_no'
                      value={initValues.invoice_no}
                      className='form-control form-control-solid fw-bold'
                      onChange={onSalesInvoiceChange}
                    />
                    <div className='text-danger mtc-2'>
                      <ErrorMessage name='invoice_no' />
                    </div>

                    <div className='form-text'>
                      Sales Invoice #
                    </div>
                  </div>
                  <div className='row mt-5'>
                    <label className='d-flex align-items-center form-label-sm fw-bold'>
                      <span className=''>Reference No</span>
                    </label>

                    <Field
                      maxLength={120}
                      type='text'
                      name='reference'
                      value={initValues.reference}
                      className='form-control form-control-solid fw-bold'
                      onChange={onReferenceChange}
                    />
                    <div className='text-danger mtc-2'>
                      <ErrorMessage name='reference' />
                    </div>

                    <div className='form-text'>
                      Reference details
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div >
        </div >
      )}
    </Formik >
  )
}

export { WaybillCreate }

type SaveProps = {
  loading: boolean
  waybillValid: boolean
}

const SaveWaybill: React.FC<SaveProps> = ({ loading, waybillValid }) => {
  const { submitForm, validateForm } = useFormikContext();
  const handleSave = () => {
    validateForm().then((result) => {
      console.log(result);
    })
    submitForm();
  }
  return (
    <div className='card-header border-0 pt-5'>
      <h3 className='card-title align-items-start flex-column'>
        <span className='card-label fw-bold fs-3 mb-1'>New waybill</span>
        <span className='text-muted mt-1 fw-semibold fs-7'>Help?</span>
      </h3>
      <div className='card-toolbar'>
        <button type='submit' disabled={waybillValid} onClick={() => handleSave()}
          className='btn btn-success'>=
          <i className="bi bi-save"> </i>
          {!loading && <span className='indicator-label'>Save</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
    </div>
  )
}