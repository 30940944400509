import axios from 'axios'
import {
  Manifest,
  ManifestCreatedResponse,
  ManifestData,
  ManifestResponseData,
  Meta,
  TruckResponseData,
} from '../../../model/models'

const API_URL = process.env.REACT_APP_API_URL

export function getManifests(page: number, pageSize: number, branch: any) {
  return axios.get<ManifestResponseData>(
    `${API_URL}/manifests?populate=*&pagination[page]=${page}&pagination[pagesize]=${pageSize}&filters[branch][$eq]=${branch}&sort[0]=createdAt:desc`
  )
}

export function getManifestByNo(manifestNo: string) {
  return axios.get<ManifestResponseData>(
    `${API_URL}/manifests?filters[manifest_no][$eq]=${manifestNo}`
  )
}

export function getManifestById(id: number) {
  return axios.get<{data: ManifestData, meta: Meta}>(
    `${API_URL}/manifests/${id}`
  )
}

export function getManifestByManifestId(id: number) {
  return axios.get<{data: ManifestData[], meta: Meta}>(
    `${API_URL}/manifests/?filters[manifest_id][$eq]=${id}`
  )
}

export function getTruckDetails() {
  return axios.get<TruckResponseData>(
    `${API_URL}/trucks?populate=*&pagination[page]=1&pagination[pageSize]=300`
  )
}

export function saveManifest(manifest: Manifest) {
  return axios.post<ManifestCreatedResponse>(`${API_URL}/manifests`, {data: manifest})
}

export function updateManifest(id: number, dataToUpdate: any) {
  return axios.put<ManifestCreatedResponse>(`${API_URL}/manifests/${id}`, {data: dataToUpdate})
}

export function searchManifestByNo(input: string, branch: any) {
  return axios.get<ManifestResponseData>(
    `${API_URL}/manifests/?filters[manifest_no][$startsWith]=${input}&filters[branch][$eq]=${branch}`
  )
}

export function filterManifestByTruck(input: number, branch: any) {
  return axios.get<ManifestResponseData>(
    `${API_URL}/manifests/?filters[truck_id][$eq]=${input}&filters[branch][$eq]=${branch}&sort[0]=createdAt:desc`
  )
}


