/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import { TruckData, WaybillIdNo, WaybillModel } from '../../../model/models';
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../setup';
import { Formik, Form, Field, ErrorMessage, useFormikContext, FormikHelpers } from 'formik'
import DatePicker from "react-datepicker";
import { NumberFormatValues, NumericFormat } from 'react-number-format'
import { getManifestByNo, getTruckDetails, saveManifest, updateManifest } from '../redux/ManifestApi';
import { useNavigate } from "react-router-dom";
import clsx from 'clsx';
import { createManifestSchemas, ICreateManifest, inits } from './ManifestCreateHelper';
import Select, { ActionMeta, InputActionMeta } from 'react-select'
import Swal from 'sweetalert2';
import { UserModel } from '../../../../app/modules/auth/core/_models';
import { getWaybillById, getWaybillByNoAndStatus, getWaybillsByStartsWithNo, searchWaybillContains, updateWaybill } from '../../waybills/redux/WaybillsApi';
import DataTable, { TableColumn } from 'react-data-table-component';
import moment from 'moment';
import { KTSVG } from '../../../../_metronic/helpers';
import { fetchTrucksData, reset } from '../redux/TruckDataSlice';
import { SelectComponentManifest } from './SelectComponentManifest';

type Props = {
  className: string
}

const customStyles = {
  headCells: {
    style: {
      fontWeight: 'bold'
    },
  }
};

const ManifestCreate: React.FC<Props> = ({ className }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [currentSchema] = useState(createManifestSchemas[0])
  const [initValues, setInitValues] = useState<ICreateManifest>(inits)
  const [invalidManifest, setInvalidManifest] = useState(true)
  const [totalCollect, setTotalCollect] = useState(0)
  const [totalAccount, setTotalAccount] = useState(0)
  const [totalPrepaid, setTotalPrepaid] = useState(0)
  const [truckOptions, setTruckOptions] = useState<{ value: number, label: string }[]>([])
  const [waybillData, setWaybillData] = useState<WaybillIdNo[]>()
  const [waybills, setWaybills] = useState<WaybillModel[]>([])
  const [validWaybill, setValidWaybill] = useState(false)
  const truckData: TruckData[] = useSelector<RootState>(({ truckData }) => truckData.data?.data, shallowEqual) as TruckData[]
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
  const branch = useSelector<RootState>(({ configs }) => configs.branchId, shallowEqual) as string

  const loadTrucksData = React.useCallback(() => {
    getTruckDetails().then((response) => {
      dispatch(fetchTrucksData(response.data))
    });
  }, [dispatch])

  useEffect(() => {
    if (truckData === undefined) {
      loadTrucksData()
    }
    getWaybills('')
    var data = { ...initValues }
    setInitValues(data)
    getManifestByNo(initValues.manifest_no).then((response) => {
      setInvalidManifest(response.data.meta.pagination.total === 0)
    })
    if (waybills !== undefined) {
      const collectWaybills = waybills.filter(items => items.charge_to === 1);
      setTotalCollect(collectWaybills.reduce((a, v) => a = a + (v.total_freight_amount === undefined ? 0 : v.total_freight_amount), 0))
      const prepaidWaybills = waybills.filter(items => items.charge_to === 2);
      setTotalPrepaid(prepaidWaybills.reduce((a, v) => a = a + (v.total_freight_amount === undefined ? 0 : v.total_freight_amount), 0))
      const accountWaybills = waybills.filter(items => items.charge_to === 3);
      setTotalAccount(accountWaybills.reduce((a, v) => a = a + (v.total_freight_amount === undefined ? 0 : v.total_freight_amount), 0))
    }
  }, [invalidManifest, waybills, truckData]);

  const submit = async (values: ICreateManifest, { setStatus, setSubmitting }: FormikHelpers<ICreateManifest>) => {
    values.user_id = user.id
    setLoading(true)
    setSubmitting(true)
    localStorage.setItem('manifest_no_last', values.manifest_no.toString())
    values.branch = branch
    saveManifest(values)
      .then((response) => {
        if (response.status === 200) {
          const manifestId = response.data.data.id
          updateManifest(response.data.data.id, { "manifest_id": manifestId })
          waybills.forEach((item) => {
            updateWaybill(item.id,
              {
                updatedby_id: user.id,
                manifest_id: manifestId,
                manifest_sequence: item.manifest_sequence,
                status: 2
              })
          })
          setTimeout(() => {
            setLoading(false)
            dispatch(reset)
            Swal.fire({
              title: 'Successs!',
              text: `Manifest # ${values.manifest_no} has been successfully save!`,
              icon: 'success',
              confirmButtonText: 'Print',
              cancelButtonText: 'Views',
              showCancelButton: true,
              customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-primary'
              }
            }).then((result) => {
              if (result.isConfirmed) {
                navigate(`/section/manifests/print/${response.data.data.id}`);
              } else if (result.dismiss === Swal.DismissReason.cancel) {
                navigate('/section/manifests/view');
              }
            })
          }, 3000)
        }
      })
      .catch((error) => {
        setLoading(false)
        setSubmitting(false)
        setStatus('Problem occured when saving your manifest.')
        Swal.fire({
          title: 'Error!',
          text: 'Problem occured when creating your manifest',
          icon: 'error',
          confirmButtonText: 'OK',
          iconColor: 'primary'
        })
        console.error(error)
      })
  }

  const onManifestNoChange = (e: NumberFormatValues) => {
    const data = { ...initValues }
    data.manifest_no = e.value
    setInitValues(data);
    getManifestByNo(e.value).then((response) => {
      setInvalidManifest(response.data.meta.pagination.total === 0)
    })
  }

  const onStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.currentTarget;
    const data = { ...initValues }
    data.status_id = Number(value)
    setInitValues(data)
  }

  const onCargoSelected = (value: number) => {
    const data = { ...initValues }
    data.truck_id = value
    setInitValues(data)
  }

  const onTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    if (e.currentTarget.name === 'description') {
      const description = { ...initValues, description: value }
      setInitValues(description)
    } else if (e.currentTarget.name === 'destination') {
      const destination = { ...initValues, destination: value }
      setInitValues(destination)
    } else if (e.currentTarget.name === 'driver') {
      const driver = { ...initValues, driver: value }
      setInitValues(driver)
    }
  }

  const addWaybill = (waybillNo: number) => {
    getWaybillByNoAndStatus(waybillNo, 5, branch).then((response) => {
      if (waybills !== undefined) {
        setValidWaybill(response.data.meta.pagination.total > 0)
        const waybill = response.data.data.find((item) => {
          return item.attributes.waybill_no == waybillNo
        })
        if (!waybills.find(item => item.waybill_id === waybill?.attributes.waybill_id)) {
          if(waybill !== undefined) {
            waybill.attributes.manifest_sequence = waybills.length + 1
            waybill.attributes.id = waybill.id
            const data = [...waybills, waybill?.attributes]
            setWaybills(data)
          }
        }
      }

    })
  }

  const onSelectedWaybill = (input: number, a: ActionMeta<WaybillIdNo>) => {
    if (a.action === 'select-option') {
      getWaybillById(input).then((response) => {
        if (waybills !== undefined) {
          const waybill = response.data.data.attributes;
          const data = [...waybills]
          if (!waybills.find(item => item.waybill_id === waybill.waybill_id)) {
            waybill.id = response.data.data.id
            waybill.manifest_sequence = waybills.length + 1
            data.push(waybill)
            setWaybills(data)
          }
        }

      })
    }
  }

  const loadWaybills = (input: string, a: InputActionMeta) => {
    if (a.action === 'input-change') {
      getWaybills(input)
    }
  }

  const getWaybills = (input: string) => {
    if (!Number.isNaN(Number(input))) {
      getWaybillsByStartsWithNo(Number(input), 5, branch).then((response) => {
        const results = response.data.data.map((item) => {
          const label = `${item.attributes.waybill_no} > ${item.attributes.consignee}`;
          return { value: item.id, label: label }
        })
        setWaybillData(results)
      })
    } else {
      searchWaybillContains(input, 5, branch).then((result) => {
        const searchResults = result.data.data.map((item) => {
          const label = `${item.attributes.waybill_no} > ${item.attributes.consignee}`;
          return { value: item.id, label: label }
        })
        setWaybillData(searchResults)
      })
    }
  }

  const deleteWaybill = (waybillNo: number) => {
    const filtered = waybills.filter(item => item.waybill_no !== waybillNo)
    setWaybills(filtered)
  }

  const onFocusTruckList = (e: React.FocusEvent<HTMLInputElement>) => {
    const options = truckData.map((item) => {
      return { value: item.attributes.truck_id, label: item.attributes.plate_no }
    })
    setTruckOptions(options)
  }

  const setDepartureDateChange = (e: Date) => {
    const data = { ...initValues }
    data.departure_date = e
    data.arrival_date = moment(e).add(1, 'day').toDate()
    setInitValues(data)
  }

  const setArrivalDateChange = (e: Date) => {
    const data = { ...initValues }
    data.arrival_date = e
    setInitValues(data)
  }

  const onPercentageChange = (e: NumberFormatValues) => {
    const data = { ...initValues }
    data.percentage = e.floatValue === undefined ? 0 : e.floatValue
    setInitValues(data)
  }

  const columns: TableColumn<WaybillModel>[] = [
    {
      name: 'WAYBILL#',
      selector: row => row.waybill_no,
      sortable: true,
      width: "120px"
    },
    {
      name: 'SHIPPER',
      selector: row => row.shipper_name,
      cell: row => {
        if (row.shipper_name === "0") {
          return "";
        } else {
          return row.shipper_name
        }
      }
    },
    {
      name: 'CONSIGNEE',
      selector: row => row.consignee,
      cell: row => row.consignee,
      sortable: true
    },
    {
      name: 'DESTINATION',
      selector: row => row.address,
      cell: row => row.address
    },
    {
      name: 'STATUS',
      width: "120px",
      selector: row => row.status,
      cell: row => {
        if (row.status === 1) {
          return (
            <span className='badge badge-light-primary fs-8 fw-bold my-2'>Warehouse</span>
          )
        } else if (row.status === 2) {
          return (
            <span className='badge badge-light-warning fs-8 fw-bold my-2'>InTransit</span>
          )
        } else if (row.status === 3) {
          return <span className='badge badge-light-info fs-8 fw-bold my-2'>Arrived</span>
        } else if (row.status === 5) {
          return <span className='badge badge-light-danger fs-8 fw-bold my-2'>Loaded</span>
        } else {
          return <span className='badge badge-light-success fs-8 fw-bold my-2'>Delivered</span>
        }
      }
    },
    {
      name: 'DATE',
      selector: row => moment(row.date).format('MM/DD/YYYY'),
      sortable: true,
      sortField: 'date',
      width: "120px"
    },
    {
      name: 'AMOUNT',
      selector: row => row.total_freight_amount,
      format: row => row.total_freight_amount.toLocaleString('en-US', { minimumFractionDigits: 2 }),
      sortable: true,
      width: "120px"
    },
    {
      name: 'TERMS',
      width: "120px",
      selector: row => row.charge_to,
      cell: row => {
        if (row.charge_to === 1) {
          return (
            <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-7'>Collect</span>
          )
        } else if (row.charge_to === 2) {
          return (
            <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-7'>Prepaid</span>
          )
        } else {
          return (
            <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-7'>Account</span>
          )
        }
      }
    },
    {
      name: 'REMOVE',
      width: '80px',
      button: true,
      cell: row => (
        <a onClick={() => deleteWaybill(row.waybill_no)} href='#'
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'>
          <KTSVG
            path='/media/icons/duotune/general/gen027.svg'
            className='svg-icon-3'
          />
        </a>
      )
    }
  ];

  return (
    <>
      <Formik validationSchema={currentSchema}
        initialValues={initValues}
        onSubmit={submit}
        enableReinitialize={true}
        validateOnChange={true}>
        {(prop) => (
          <div className={`card ${className}`}>
            <SaveWaybill loading={loading} valid={invalidManifest} />
            <div className='car-body'>
              <Form className='row sg-4 p-10' id='kt_create_waybill_form'>
                <div className="row">
                  <div className='col-md-3'>
                    <label className='d-flex align-items-center form-label-sm fw-bold'>
                      <span className='required'>Manifest No</span>
                    </label>
                    <NumericFormat
                      maxLength={10}
                      allowLeadingZeros
                      name='manifest_no'
                      placeholder='Manifest #'
                      value={prop.values.manifest_no}
                      onValueChange={(values) => {
                        onManifestNoChange(values);
                        prop.setFieldValue('manifest_no', values.value)
                      }}
                      className={clsx(
                        'form-control form-control-solid fw-bold',
                        { 'is-invalid': !invalidManifest },
                        {
                          'is-valid': invalidManifest,
                        }
                      )} />
                    <div className='text-danger mtc-2'>
                      <ErrorMessage name='manifest_no' />
                    </div>
                    <div className='form-text'>
                      Manifest identification
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <label className='d-flex align-items-center form-label-sm fw-bold'>
                      <span className='required'>Departure Date</span>
                    </label>
                    <DatePicker name='departure_date'
                      required
                      selected={prop.values.departure_date}
                      onChange={(e: Date) => {
                        if (e === null) { e = new Date() }
                        setDepartureDateChange(e)
                        prop.setFieldValue('departure_date', e)
                      }}
                      dateFormat={'MM/dd/yyy'}
                      className='form-control form-control-solid fw-bold' />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='departure_date' />
                    </div>

                    <div className='form-text'>
                      Departure Date
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <label className='d-flex align-items-center form-label-sm fw-bold'>
                      <span className='required'>Arrival Date</span>
                    </label>
                    <DatePicker name='arrival_date'
                      required
                      selected={initValues.arrival_date}
                      onChange={(e: Date) => {
                        if (e === null) { e = new Date() }
                        setArrivalDateChange(e)
                        prop.setFieldValue('arrival_date', e)
                      }}
                      dateFormat={'MM/dd/yyy'}
                      className='form-control form-control-solid fw-bold' />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='arrival_date' />
                    </div>

                    <div className='form-text'>
                      Arrival Date
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <label className='d-flex align-items-center form-label-sm fw-bold'>
                      <span className='required'>Status</span>
                    </label>
                    <Field
                      as='select'
                      name='status_id'
                      value={initValues.status_id}
                      onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                        onStatusChange(e)
                      }}
                      className='form-select form-select-solid fw-bold'>
                      <option value='1'>Warehouse</option>
                      <option value='5'>Loaded</option>
                      <option value='2'>In Transit</option>
                      <option value='3'>Arrived</option>
                      <option value='4'>Delivered</option>
                    </Field>
                    <div className='form-text'>
                      Status of the manifest
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className='col-md-3 mt-5'>
                    <div className=''>
                      <label className='d-flex align-items-center form-label-sm fw-bold'>
                        <span className='required'>Route</span>
                      </label>
                      <Field
                        required
                        maxLength={240}
                        type='text'
                        name='description'
                        value={prop.values.description}
                        className='form-control fw-bold'
                        onChange={onTextChange}
                      />
                      <div className='text-danger mtc-2'>
                        <ErrorMessage name='description' />
                      </div>

                      <div className='form-text'>
                        Route
                      </div>

                    </div>
                  </div>
                  <div className='col-md-3 mt-5'>
                    <div className=''>
                      <label className='d-flex align-items-center form-label-sm fw-bold'>
                        <span className=''>Destination</span>
                      </label>
                      <Field
                        maxLength={120}
                        type='text'
                        name='destination'
                        value={prop.values.destination}
                        className='form-control fw-bold'
                        onChange={onTextChange}
                      />
                      <div className='text-danger mtc-2'>
                        <ErrorMessage name='destination' />
                      </div>

                      <div className='form-text'>
                        Destination
                      </div>

                    </div>
                  </div>
                  <div className='col-md-3 mt-5'>
                    <div className=''>
                      <label className='d-flex align-items-center form-label-sm fw-bold'>
                        <span className=''>Driver's Name</span>
                      </label>
                      <Field
                        maxLength={120}
                        type='text'
                        name='driver'
                        value={prop.values.driver}
                        className='form-control fw-bold'
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          onTextChange(e)
                          prop.setFieldValue(e.currentTarget.name, e.currentTarget.value)
                        }}
                      />
                      <div className='text-danger mtc-2'>
                        <ErrorMessage name='driver' />
                      </div>

                      <div className='form-text'>
                        Driver's name
                      </div>

                    </div>
                  </div>
                  <div className='col-md-3 mt-5'>
                    <label className='d-flex align-items-center form-label-sm fw-bold'>
                      <span className='required'>Cargo Truck</span>
                    </label>
                    <Select
                      name='truck_id'
                      isClearable
                      className='fw-bold'
                      isSearchable
                      onChange={(e) => {
                        onCargoSelected(e?.value!)
                      }}
                      onFocus={onFocusTruckList}
                      options={truckOptions} />
                    <div className='text-danger mtc-2'>
                      <ErrorMessage name='truck_id' />
                    </div>
                    <div className='form-text'>
                      Cargo trucks from Aguileon and Sub-Trucker
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className='col-md-3 mt-5'>
                    <div className=''>
                      <label className='d-flex align-items-center form-label-sm fw-bold'>
                        <span className='required'>Percentage</span>
                      </label>
                      <NumericFormat
                        maxLength={3}
                        name='percentage'
                        placeholder='Percentage'
                        value={prop.values.percentage}
                        onValueChange={(values) => {
                          onPercentageChange(values);
                          prop.setFieldValue('percentage', values.value)
                        }}
                        className='form-control fw-bold' />
                      <div className='text-danger mtc-2'>
                        <ErrorMessage name='Percentage' />
                      </div>

                      <div className='form-text'>
                        Percentage
                      </div>

                    </div>
                  </div>
                  <div className='col-md-6 mt-5 d-flex'>
                    <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                      <div className="d-flex align-items-center">
                        <div className="fs-2 fw-bold" data-kt-countup="true" data-kt-countup-value="6800" data-kt-countup-prefix="$">{totalCollect.toLocaleString()}</div>
                      </div>
                      <div className="fw-semibold fs-6 ">Total Collect</div>
                    </div>
                    <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                      <div className="d-flex align-items-center">
                        <div className="fs-2 fw-bold" data-kt-countup="true" data-kt-countup-value="6800" data-kt-countup-prefix="$">{totalAccount.toLocaleString()}</div>
                      </div>
                      <div className="fw-semibold fs-6 ">Total Account</div>
                    </div>
                    <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                      <div className="d-flex align-items-center">
                        <div className="fs-2 fw-bold" data-kt-countup="true" data-kt-countup-value="6800" data-kt-countup-prefix="$">{totalPrepaid.toLocaleString()}</div>
                      </div>
                      <div className="fw-semibold fs-6 ">Total Prepaid</div>
                    </div>
                  </div>

                </div>
              </Form>
            </div >
          </div >
        )}
      </Formik >
      <DataTable columns={columns}
        data={waybills}
        striped
        selectableRowsHighlight
        pointerOnHover
        progressPending={loading}
        customStyles={customStyles}
        subHeader
        subHeaderComponent={<SelectComponentManifest waybill={waybillData} load={loadWaybills} select={onSelectedWaybill} isValid={validWaybill} addWaybill={addWaybill} />}
        className='table-responsive' />
    </>
  )
}

export { ManifestCreate }

type SaveProps = {
  loading: boolean
  valid: boolean
}

const SaveWaybill: React.FC<SaveProps> = ({ loading, valid }) => {
  const { submitForm, validateForm } = useFormikContext();
  const handleSave = () => {
    validateForm().then((result) => {
      console.log(result);
    })
    submitForm();
  }
  return (
    <div className='card-header border-0 pt-5'>
      <h3 className='card-title align-items-start flex-column'>
        <span className='card-label fw-bold fs-3 mb-1'>New manifest</span>
        <span className='text-muted mt-1 fw-semibold fs-7'>Help?</span>
      </h3>
      <div className='card-toolbar'>
        <button type='submit' disabled={!valid} onClick={() => handleSave()}
          className='btn btn-success'>=
          <i className="bi bi-save"> </i>
          {!loading && <span className='indicator-label'>Save</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
    </div>
  )
}

