import { PDFViewer, Page, StyleSheet, View, Document, Font, Text, Image } from '@react-pdf/renderer';
import moment from 'moment';
import React, { FC, useEffect, useState } from 'react'
import DatePicker from "react-datepicker";
import { ReportMonthlyData } from '../../../model/models';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../../../setup';
import { getMonthlyReport } from '../redux/ReportsApi';
import { right } from '@popperjs/core';

Font.register({
  family: 'Libre',
  src: 'https://fonts.gstatic.com/s/librebaskerville/v4/pR0sBQVcY0JZc_ciXjFsKyyZRYCSvpCzQKuMWnP5NDY.ttf'
});

const styles = StyleSheet.create({
  viewer: {
    width: '100%',
    height: '75%'
  }
});

const MonthlyReportPage: FC = () => {
  const branch = useSelector<RootState>(({ configs }) => configs.branchId, shallowEqual) as string
  const [date, setDate] = useState(new Date())
  const [month, setMonth] = useState(0)
  const [year, setYear] = useState(0)
  const [status, setStatus] = useState(0)
  const [payment, setPayment] = useState(0)
  const [reportData, setReportData] = useState<ReportMonthlyData>()
  const [totalCollect, setTotalCollect] = useState(0)
  const [totalAccount, setTotalAccount] = useState(0)
  const [totalPrepaid, setTotalPrepaid] = useState(0)

  useEffect(() => {
    const getReport = () => {
      getMonthlyReport(payment, status, month, year, branch).then((response) => {
        if (response.status === 200) {
          setReportData(response.data)
          const collect = response.data.data.summary.filter(a => a.charge === 1);
          const account = response.data.data.summary.filter(a => a.charge === 2);
          const prepaid = response.data.data.summary.filter(a => a.charge === 3);
          setTotalCollect(collect.length > 0 ? collect[0].total : 0);
          setTotalAccount(account.length > 0 ? account[0].total : 0);
          setTotalPrepaid(prepaid.length > 0 ? prepaid[0].total : 0);
        }
      })
    }

    getReport()


  }, [payment, status, month, year, branch])

  const onDateChange = (e: Date) => {
    setMonth(Number(moment(e).format('M')))
    setYear(Number(moment(e).format('YYYY')))
    setDate(e)
  }

  const onStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setStatus(Number(e.currentTarget.value))
  }

  const onPaymentChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setPayment(Number(e.currentTarget.value))
  }

  return (
    <>
      <div className="card card-custom">
        <div className="card-header">
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Monthly Report</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>Help?</span>
          </h3>
        </div>
        <div className="card-body">
          <form>
            <div className='row'>
              <div className='col-md-3'>
                <label className='d-flex align-items-center form-label-sm fw-bold'>
                  <span className='required'>Month</span>
                </label>
                <DatePicker name='date'
                  value={moment(date).format('MMMM YYYY')}
                  isClearable
                  required
                  onChange={onDateChange}
                  dateFormat="MM/yyyy"
                  showMonthYearPicker
                  className='form-control form-control-solid fw-bold' />
                <div className='form-text'>
                  Waybill Date
                </div>
              </div>
              <div className='col-md-3'>
                <label className='d-flex align-items-center form-label-sm fw-bold'>
                  <span className='required'>Charge To</span>
                </label>

                <select
                  name='charge_to'
                  value={payment}
                  onChange={onPaymentChange}
                  className='form-select form-control-solid form-select-solid fw-bold' >
                  <option value='0'>All</option>
                  <option value='1'>Collect</option>
                  <option value='2'>Prepaid</option>
                  <option value='3'>Account</option>
                </select>
                <div className='form-text'>
                  Client payment options
                </div>
              </div>
              <div className='col-md-3'>
                <label className='d-flex align-items-center form-label-sm fw-bold'>
                  <span className='required'>Status</span>
                </label>
                <select

                  name='status'
                  value={status}
                  onChange={onStatusChange}
                  className='form-select form-control-solid form-select-solid fw-bold'>
                  <option value='0'>All</option>
                  <option value='1'>Warehouse</option>
                  <option value='5'>Loaded</option>
                  <option value='2'>In Transit</option>
                  <option value='3'>Arrived</option>
                  <option value='4'>Delivered</option>
                </select>
                <div className='form-text'>
                  Status of the shipment
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <PDFViewer style={styles.viewer} >
        <Document pageLayout='twoPageRight'>
          <Page size={[612.0, 792.0]} orientation='portrait' wrap >

            <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
              <Image style={{ width: 200, height: 50, marginTop: 10 }} src='/media/logos/Aguileon_Logo.png' />
            </View>
            <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
              <Text style={{ fontFamily: 'Libre', fontSize: 7 }}>1426 Geliños St. Brgy. 342, Zone 34, District III, Sta. Cruz, Manila</Text>
            </View>
            <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
              <Text style={{ fontFamily: 'Libre', fontSize: 10, marginTop: 10 }}>Monthly Report {moment(date).format('MMMM YYYY')}</Text>
            </View>
            <View style={{ flexDirection: 'row', justifyContent: 'center', marginTop: 5, fontFamily: 'Libre', borderTop: .5, borderBottom: .5 }}>
              <View style={{ flexDirection: 'row', width: 140, margin: 5, paddingTop: 5 }}>
                <Text style={{ fontSize: 8 }}>Overall Total: PHP  </Text>
                <Text style={{ fontSize: 8, fontWeight: 'ultrabold' }}>{reportData?.meta.configuration.totalAmount.toLocaleString()}</Text>
              </View>
              <View style={{ flexDirection: 'row', width: 120, margin: 5, paddingTop: 5 }}>
                <Text style={{ fontSize: 8 }}>Collect: PHP  </Text>
                <Text style={{ fontSize: 8, fontWeight: 'ultrabold' }}> {totalCollect.toLocaleString()}</Text>
              </View>
              <View style={{ flexDirection: 'row', width: 120, margin: 5, fontFamily: 'Libre', paddingTop: 5 }}>
                <Text style={{ fontSize: 8 }}>Account: PHP  </Text>
                <Text style={{ fontSize: 8, fontWeight: 'ultrabold' }}> {totalAccount.toLocaleString()}</Text>
              </View>
              <View style={{ flexDirection: 'row', width: 120, margin: 5, fontFamily: 'Libre', paddingTop: 5 }}>
                <Text style={{ fontSize: 8 }}> Prepaid: PHP  </Text>
                <Text style={{ fontSize: 8, fontWeight: 'ultrabold' }}> {totalPrepaid.toLocaleString()}</Text>
              </View>
            </View>
            <View fixed style={{
              flexDirection: 'row',
              justifyContent: 'flex-start',
              marginTop: 5,
              marginBottom: 5,
              fontFamily: 'Libre',
              marginHorizontal: 20,
              paddingBottom: 5
            }}>
              <View style={{ flexDirection: 'row', width: 60, paddingTop: 5 }}>
                <Text style={{ fontSize: 8 }}>Day </Text>
              </View>
              <View style={{ flexDirection: 'row', width: 140, fontFamily: 'Libre', paddingTop: 5 }}>
                <Text style={{ fontSize: 8 }}> </Text>
              </View>
              <View style={{ flexDirection: 'row', width: 160, fontFamily: 'Libre', paddingTop: 5, textAlign: right }}>
                <Text style={{ fontSize: 8, textAlign: right }}>Total Amount </Text>
              </View>
            </View>
            <View>
              {
                reportData?.data.report.map((row, index) => {
                  return <View key={index}
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      marginTop: 5,
                      fontFamily: 'Libre',
                      marginHorizontal: 10,
                      borderBottom: .5, paddingBottom: 2, paddingLeft: 25
                    }}>
                    <Text style={{ fontSize: 7, width: 60 }}>  {row.day}</Text>
                    <Text style={{ fontSize: 7, width: 160, paddingRight: 5 }}>{row.day_name}</Text>
                    <Text style={{ fontSize: 7, width: 60, paddingRight: 10, textAlign: 'right' }}>{row.daily_total.toLocaleString()}</Text>

                  </View>
                })
              }
              <View style={{
                flexDirection: 'row',
                justifyContent: 'flex-start',
                marginTop: 5,
                fontFamily: 'Libre',
                marginHorizontal: 10,
                borderBottom: .5, paddingBottom: 2, paddingLeft: 25
              }}>
                <Text style={{ fontSize: 9, width: 60 }}>  </Text>
                <Text style={{ fontSize: 9, width: 160, paddingRight: 5 }}>Total Amount (PHP)</Text>
                <Text style={{ fontSize: 9, width: 160, paddingRight: 10, fontWeight: 'bold' }}>{reportData?.meta.configuration.totalAmount.toLocaleString()}</Text>
              </View>
            </View>
            <View fixed style={{ flexDirection: 'row', justifyContent: 'center', marginVertical: 10 }} >
              <Text style={{ fontSize: 7, paddingVertical: 5 }} render={({ pageNumber, totalPages }) => (
                `Pages ${pageNumber} / ${totalPages}`
              )} />
            </View>

          </Page>
        </Document>
      </PDFViewer>
    </>
  )
}

export { MonthlyReportPage }
