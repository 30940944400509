import React from 'react'
import { Navigate, Route, Routes, Outlet } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { StatementsViewPage } from './components/StatementsViewPage'
import { SOACreatePage } from './components/SOACreatePage'
import { SOADetailsPage } from './components/SOADetailsPage'
import { SOAPrintPage } from './components/SOAPrintPage'


const accountsBreadCrumbs: Array<PageLink> = [
  {
    title: 'Statement of Accounts',
    path: '/section/accounts/view',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'Create Statement',
    path: '/section/accounts/create',
    isSeparator: true,
    isActive: false,
  },
  {
    title: '',
    path: '/section/accounts/details/:id',
    isSeparator: false,
    isActive: false,
  }
]

const StatementsPage: React.FC = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>

        <Route
          path='view'
          element={
            <>
              <PageTitle breadcrumbs={accountsBreadCrumbs}>View</PageTitle>
              <StatementsViewPage />
            </>
          }
        />

        <Route
          path='details/:id'
          element={
            <>
              <PageTitle breadcrumbs={accountsBreadCrumbs}>Details</PageTitle>
              <SOADetailsPage />
            </>
          }
        />
        <Route
          path='print/:id'

          element={
            <>
              <PageTitle breadcrumbs={accountsBreadCrumbs}>Print</PageTitle>
              <SOAPrintPage />
            </>
          }
        />

        <Route
          path='create'
          element={
            <>
              <PageTitle breadcrumbs={accountsBreadCrumbs}>Create</PageTitle>
              <SOACreatePage />
            </>
          }
        />

        <Route index element={<Navigate to='/section/accounts/view' />} />
      </Route>
    </Routes>
  )
}
export default StatementsPage
