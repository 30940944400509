import Select, { ActionMeta, GroupBase, InputActionMeta, OptionProps, SingleValue, components } from 'react-select'
import { ContactDetailsData } from '../../../../model/models'


type SelectProps = {
    options: ContactDetailsData[]
    load: (input: string, a: InputActionMeta) => void
    select: (input: SingleValue<ContactDetailsData>, a: ActionMeta<ContactDetailsData>) => void
    type: string,
    selectedValue: ContactDetailsData
}

const ContactDetailsControl: React.FC<SelectProps> = ({ options, load, select, type, selectedValue }) => {
    return (
        <Select
            defaultValue={selectedValue}
            name={type}
            className={'w-3 my-2 mx-2'}
            isClearable
            isSearchable
            options={options}
            getOptionLabel={(option) => {
                if(option.id === 0) {
                   return `${option.attributes.name}`
                } 
                return `${option.attributes.name}  ${option.attributes.address}`
            }}
            placeholder={`Search ${type}`}
            onInputChange={(e, a) => load(e, a)}
            controlShouldRenderValue
            onChange={(e, a) => select(e, a)}
        />
    )
}

export {ContactDetailsControl}