/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import { CustomerData, Customers, WaybillIdNo, WaybillModel } from '../../../model/models';
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../../setup';
import { Formik, Form, Field, ErrorMessage, useFormikContext, FormikHelpers } from 'formik'
import DatePicker from "react-datepicker";
import { NumberFormatValues, NumericFormat } from 'react-number-format'
import clsx from 'clsx';
import { createSOASchemas, ICreateSOA, inits } from './SOACreateHelper';
import Select, { ActionMeta, InputActionMeta, SingleValue } from 'react-select'
import Swal from 'sweetalert2';
import { UserModel } from '../../auth/core/_models';
import { getWaybillById, getWaybillItems, getWaybillsFilterByNo, searchWaybillByConsignee, updateWaybill } from '../../waybills/redux/WaybillsApi';
import DataTable, { TableColumn } from 'react-data-table-component';
import moment from 'moment';
import { KTSVG } from '../../../../_metronic/helpers';
import { getAccountByNo, saveSOA, updateSOA } from '../redux/AccountsApi';
import { SelectComponent } from '../../manifests/components/SelectComponent';


type Props = {
  className: string
}

const customStyles = {
  headCells: {
    style: {
      fontWeight: 'bold'
    },
  }
};

const SOACreate: React.FC<Props> = ({ className }) => {
  const [loading, setLoading] = useState(false)
  const [currentSchema] = useState(createSOASchemas[0])
  const [initValues, setInitValues] = useState<ICreateSOA>(inits)
  const [invalidReference, setInvalidReference] = useState(true)
  const [waybillData, setWaybillData] = useState<WaybillIdNo[]>()
  const [waybills, setWaybills] = useState<WaybillModel[]>([])
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
  const customerData: Customers = useSelector<RootState>(({ customers }) => customers.data, shallowEqual) as Customers
  const branch = useSelector<RootState>(({ configs }) => configs.branchId, shallowEqual) as string

  useEffect(() => {
    console.log(initValues.account_id)
    checkAccountNo(initValues.account_no)
  }, [invalidReference, waybills]);

  const submit = async (values: ICreateSOA, { setStatus, setSubmitting }: FormikHelpers<ICreateSOA>) => {
    values.createdby_id = user.id
    values.updatedby_id = user.id
    values.branch = branch
    setLoading(true)
    setSubmitting(true)
    console.log(values)
    localStorage.setItem('soa_no_last', values.account_no.toString())
    setTimeout(() => {
      if (initValues.account_id !== 0) {
        updateSOA(initValues.account_id, initValues)
          .then((updateResponse) => {
            setLoading(false)
            if (updateResponse.status === 200) {

              Swal.fire({
                title: 'Successs!',
                text: `SOA # ${values.account_no} has been successfully save! You can now add waybills.`,
                icon: 'success',
              })
            }
          })
          .catch((error) => {
            setLoading(false)
            setSubmitting(false)
            setStatus('Problem occured when saving your statement of accounts.')
            Swal.fire({
              title: 'Error!',
              text: 'Problem occured when creating your statement of accounts',
              icon: 'error',
              confirmButtonText: 'OK',
              iconColor: 'primary'
            })
            console.error(error)
          })
      } else {
        saveSOA(values)
          .then((response) => {
            if (response.status === 200) {
              const account_id = response.data.data.id
              initValues.account_id = account_id
              updateSOA(account_id, { "account_id": account_id })
              setLoading(false)
              Swal.fire({
                title: 'Successs!',
                text: `SOA # ${values.account_no} has been successfully save! You can now add waybills.`,
                icon: 'success',
              })
            }
          })
          .catch((error) => {
            setLoading(false)
            setSubmitting(false)
            setStatus('Problem occured when saving your statement of accounts.')
            Swal.fire({
              title: 'Error!',
              text: 'Problem occured when creating your statement of accounts',
              icon: 'error',
              confirmButtonText: 'OK',
              iconColor: 'primary'
            })
            console.error(error)
          })
      }
    }, 2000)
  }

  const onAccountNoChange = (e: NumberFormatValues) => {
    if (e.value === '') {
      setInvalidReference(false)
    } else {
      const data = { ...initValues }
      data.account_no = e.floatValue!
      setInitValues(data);
      checkAccountNo(data.account_no)
    }
  }

  const checkAccountNo = (accountNo: number) => {
    getAccountByNo(accountNo).then((response) => {
      if (initValues.account_id === 0) {
        setInvalidReference(response.data.meta.pagination.total === 0)
      }
    })
  }

  const onStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.currentTarget;
    const data = { ...initValues }
    data.status = Number(value)
    setInitValues(data)
  }

  const onCustomerChange = (value: SingleValue<CustomerData>, a: ActionMeta<CustomerData>) => {
    const data = { ...initValues }
    data.customer_id = value !== null ? value.attributes.customer_id : 0
    setInitValues(data)
  }

  const onTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    if (e.currentTarget.name === 'note') {
      const note = { ...initValues, note: value }
      setInitValues(note)
    } else if (e.currentTarget.name === 'description') {
      const description = { ...initValues, description: value }
      setInitValues(description)
    }
  }

  const onNumericChange = (e: NumberFormatValues, field: string) => {
    if (field === 'total_amount') {
      const dataTotalAmout = { ...initValues, total_amount: e.floatValue! }
      setInitValues(dataTotalAmout)
    } else if (field === 'total_vat') {
      const dataTotalVat = { ...initValues, total_vat: e.floatValue! }
      setInitValues(dataTotalVat)
    } else {
      const dataTotalPaid = { ...initValues, total_paid: e.floatValue! }
      setInitValues(dataTotalPaid)
    }
  }

  const onSelectedWaybill = (input: number, a: ActionMeta<WaybillIdNo>) => {
    if (a.action === 'select-option') {
      getWaybillById(input).then((response) => {
        const currentWaybill = response.data.data
        if (currentWaybill.attributes.item_description === '' || currentWaybill.attributes.item_description === null) {
          getWaybillItems(currentWaybill.id).then((itemResponse) => {
            const description = itemResponse.data.data.map(i => i.attributes.description).join(' / ')
            const qty = itemResponse.data.data.reduce((a, v) => a = a + (v.attributes.quantity === undefined ? 0 : v.attributes.quantity), 0)
            updateWaybill(currentWaybill.id, {
              account_id: initValues.account_id,
              item_description: description,
              total_quantity: qty
            }
            )
          })
        } else {
          updateWaybill(currentWaybill.id,
            {
              account_id: inits.account_id
            })
        }

        if (waybills !== undefined) {
          const account = { ...initValues }
          const waybill = response.data.data.attributes;
          const data = [...waybills]
          if (!waybills.find(item => item.waybill_id === waybill.waybill_id)) {
            const selectedWaybill = response.data.data.attributes
            selectedWaybill.id = response.data.data.id
            data.push(selectedWaybill)
            setWaybills(data)
          }
          account.total_amount = data.reduce((a, v) => a = a + (v.total_freight_amount === undefined ? 0 : v.total_freight_amount), 0)
          account.total_vat = data.reduce((a, v) => a = a + (v.total_freight_vat === undefined ? 0 : v.total_freight_vat), 0)
          updateSOA(initValues.account_id, account)
          setInitValues(account)
        }

      })
    }
  }

  const loadWaybills = (input: string, a: InputActionMeta) => {
    if (a.action === 'input-change') {
      if (input.length > 2) {
        getWaybills(input)
      }
    }
  }

  const getWaybills = (input: string) => {
    if (!Number.isNaN(Number(input))) {
      getWaybillsFilterByNo(Number(input), branch).then((response) => {
        const results = response.data.data.map((item) => {
          const label = `${item.attributes.waybill_no} > ${item.attributes.consignee}`;
          return { value: item.id, label: label }
        })
        setWaybillData(results)
      })
    } else {
      searchWaybillByConsignee(input, branch).then((result) => {
        const searchResults = result.data.data.map((item) => {
          const label = `${item.attributes.waybill_no} > ${item.attributes.consignee}`;
          return { value: item.id, label: label }
        })
        setWaybillData(searchResults)
      })
    }
  }

  const deleteWaybill = (waybillNo: number) => {
    const filtered = waybills.filter(item => item.waybill_no !== waybillNo)
    setWaybills(filtered)
  }

  const columns: TableColumn<WaybillModel>[] = [
    {
      name: 'WAYBILL#',
      selector: row => row.waybill_no,
      sortable: true,
      width: "120px"
    },
    {
      name: 'SHIPPER',
      selector: row => row.shipper_name,
      cell: row => {
        if (row.shipper_name === "0") {
          return "";
        } else {
          return row.shipper_name
        }
      }
    },
    {
      name: 'CONSIGNEE',
      selector: row => row.consignee,
      cell: row => row.consignee,
      sortable: true
    },
    {
      name: 'DESTINATION',
      selector: row => row.address,
      cell: row => row.address
    },
    {
      name: 'STATUS',
      width: "120px",
      selector: row => row.status,
      cell: row => {
        if (row.status === 1) {
          return (
            <span className='badge badge-light-primary fs-8 fw-bold my-2'>Warehouse</span>
          )
        } else if (row.status === 2) {
          return (
            <span className='badge badge-light-warning fs-8 fw-bold my-2'>InTransit</span>
          )
        } else if (row.status === 3) {
          return <span className='badge badge-light-info fs-8 fw-bold my-2'>Arrived</span>
        } else if (row.status === 5) {
          return <span className='badge badge-light-danger fs-8 fw-bold my-2'>Loaded</span>
        } else {
          return <span className='badge badge-light-success fs-8 fw-bold my-2'>Delivered</span>
        }
      }
    },
    {
      name: 'DATE',
      selector: row => moment(row.date).format('MM/DD/YYYY'),
      sortable: true,
      sortField: 'date',
      width: "120px"
    },
    {
      name: 'AMOUNT',
      selector: row => row.total_freight_amount,
      format: row => row.total_freight_amount.toLocaleString('en-US', { minimumFractionDigits: 2 }),
      sortable: true,
      width: "120px"
    },
    {
      name: 'TERMS',
      width: "120px",
      selector: row => row.charge_to,
      cell: row => {
        if (row.charge_to === 1) {
          return (
            <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-7'>Collect</span>
          )
        } else if (row.charge_to === 2) {
          return (
            <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-7'>Prepaid</span>
          )
        } else {
          return (
            <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-7'>Account</span>
          )
        }
      }
    },
    {
      name: 'REMOVE',
      width: '80px',
      button: true,
      cell: row => (
        <a onClick={() => deleteWaybill(row.waybill_no)} href='#'
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'>
          <KTSVG
            path='/media/icons/duotune/general/gen027.svg'
            className='svg-icon-3'
          />
        </a>
      )
    }
  ];

  return (
    <>
      {
        initValues.account_id === 0 &&
        <div className="alert alert-primary d-flex align-items-center p-5 mb-10">
          <KTSVG path="/media/icons/duotune/general/gen044.svg" className="svg-icon-primary svg-icon-2hx px-5" />

          <div className="d-flex flex-column">
            <h5 className="mb-1">To add waybills, click save.</h5>
            {/* <span>Before you add waybills, please save the SOA details first</span> */}
          </div>
        </div>
      }
      <Formik validationSchema={currentSchema}
        initialValues={initValues}
        onSubmit={submit}
        enableReinitialize={true}
        validateOnChange={true}>
        {(prop) => (
          <div className={`card ${className}`}>
            <SaveSOA loading={loading} valid={invalidReference} client={customerData.data.filter(c => c.attributes.customer_id === initValues.customer_id)[0]?.attributes.name} account_id={initValues.account_id} />
            <div className='car-body'>
              <Form className='row sg-4 p-10' id='kt_create_soa_form'>
                <div className="row">
                  <div className='col-md-3'>
                    <label className='d-flex align-items-center form-label-sm fw-bold'>
                      <span className='required'>Reference No</span>
                    </label>
                    <NumericFormat
                      maxLength={10}
                      name='account_no'
                      placeholder='Reference #'
                      value={prop.values.account_no}
                      onValueChange={(values) => {
                        onAccountNoChange(values);
                        prop.setFieldValue('account_no', values.value)
                      }}
                      className={clsx(
                        'form-control form-control-solid fw-bold',
                        { 'is-invalid': !invalidReference },
                        {
                          'is-valid': invalidReference,
                        }
                      )} />
                    <div className='text-danger mtc-2'>
                      <ErrorMessage name='account_no' />
                    </div>
                    <div className='form-text'>
                      Reference # of account
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <label className='d-flex align-items-center form-label-sm fw-bold'>
                      <span className='required'>Statement Date</span>
                    </label>
                    <DatePicker name='statement_date'
                      required
                      minDate={new Date()}
                      selected={prop.values.statement_date}
                      onChange={(e: Date) => {
                        if (e === null) { e = new Date() }
                        //setDepartureDateChange(e)
                        prop.setFieldValue('statement_date', e)
                      }}
                      dateFormat={'MM/dd/yyy'}
                      className='form-control form-control-solid fw-bold' />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='statement_date' />
                    </div>

                    <div className='form-text'>
                      Statement of Account Date
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <label className='d-flex align-items-center form-label-sm fw-bold'>
                      <span className='required'>Due Date</span>
                    </label>
                    <DatePicker name='due_date'
                      required
                      minDate={new Date()}
                      selected={initValues.due_date}
                      onChange={(e: Date) => {
                        if (e === null) { e = new Date() }
                        //setArrivalDateChange(e)
                        prop.setFieldValue('due_date', e)
                      }}
                      dateFormat={'MM/dd/yyy'}
                      className='form-control form-control-solid fw-bold' />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='due_date' />
                    </div>

                    <div className='form-text'>
                      Due Date
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <label className='d-flex align-items-center form-label-sm fw-bold'>
                      <span className='required'>Date Paid</span>
                    </label>
                    <DatePicker name='date_paid'
                      required
                      selected={initValues.date_paid}
                      onChange={(e: Date) => {
                        if (e === null) { e = new Date() }
                        //setArrivalDateChange(e)
                        prop.setFieldValue('date_paid', e)
                      }}
                      dateFormat={'MM/dd/yyy'}
                      className='form-control form-control-solid fw-bold' />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='date_paid' />
                    </div>

                    <div className='form-text'>
                      Date Paid
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className='col-md-3 mt-7'>
                    <label className='d-flex align-items-center form-label-sm fw-bold'>
                      <span className='required'>Customer Account To</span>
                    </label>
                    <Select
                      name='customer_id'
                      isClearable
                      className='fw-bold'
                      isSearchable
                      onChange={(e, a) => onCustomerChange(e, a)}
                      //onFocus={onFocusTruckList}
                      options={customerData.data}
                      getOptionValue={(option) => { return `${option.attributes.customer_id}` }}
                      getOptionLabel={(option) => { return `${option.attributes.name}` }}
                    />
                    <div className='text-danger mtc-2'>
                      <ErrorMessage name='customer_id' />
                    </div>
                    <div className='form-text'>
                      Select a customer for this statement
                    </div>
                  </div>
                  <div className='col-md-3 mt-5'>
                    <label className='d-flex align-items-center form-label-sm fw-bold'>
                      <span className='required'>Status</span>
                    </label>
                    <Field
                      as='select'
                      name='status'
                      value={initValues.status}
                      onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                        onStatusChange(e)
                      }}
                      className='form-select fw-bold'>
                      <option value='0'>Unpaid</option>
                      <option value='1'>Acknowledged</option>
                      <option value='2'>Full Payment</option>
                      <option value='3'>Installment</option>
                      <option value='4'>OverDue</option>
                    </Field>
                    <div className='form-text'>
                      Status of the manifest
                    </div>
                  </div>
                  <div className='col-md-3 mt-5'>
                    <div className=''>
                      <label className='d-flex align-items-center form-label-sm fw-bold'>
                        <span className=''>Note</span>
                      </label>
                      <Field
                        required
                        maxLength={240}
                        type='text'
                        name='note'
                        value={prop.values.note}
                        className='form-control fw-bold'
                        onChange={onTextChange}
                      />
                      <div className='text-danger mtc-2'>
                        <ErrorMessage name='note' />
                      </div>

                      <div className='form-text'>
                        Note
                      </div>

                    </div>
                  </div>
                  <div className='col-md-3 mt-5'>
                    <div className=''>
                      <label className='d-flex align-items-center form-label-sm fw-bold'>
                        <span className=''>Description</span>
                      </label>
                      <Field
                        maxLength={120}
                        type='text'
                        name='description'
                        value={prop.values.description}
                        className='form-control fw-bold'
                        onChange={onTextChange}
                      />
                      <div className='text-danger mtc-2'>
                        <ErrorMessage name='description' />
                      </div>

                      <div className='form-text'>
                        Description
                      </div>

                    </div>
                  </div>


                </div>
                <div className="row">
                  <div className='col-md-3 mt-5'>
                    <label className='d-flex align-items-center form-label-sm fw-bold'>
                      <span className='required'>Total Billing Amount</span>
                    </label>
                    <NumericFormat
                      maxLength={10}
                      name='total_amount'
                      placeholder='Total Amount'
                      value={waybills.reduce((a, v) => a = a + (v.total_freight_amount === undefined ? 0 : v.total_freight_amount), 0)}
                      className='form-control form-control-solid fw-bold'
                      thousandSeparator={','}
                      allowLeadingZeros={false}
                      decimalScale={2}
                      onValueChange={(values) => {
                        onNumericChange(values, 'total_amount');
                      }}
                    />
                    <div className='text-danger mtc-2'>
                      <ErrorMessage name='total_amount' />
                    </div>

                    <div className='form-text'>
                      Total Billing Amount
                    </div>
                  </div>
                  <div className='col-md-3 mt-5'>
                    <label className='d-flex align-items-center form-label-sm fw-bold'>
                      <span className='required'>Total VAT Amount</span>
                    </label>
                    <NumericFormat
                      maxLength={10}
                      name='total_vat'
                      placeholder='Total Vat Amount'
                      value={waybills.reduce((a, v) => a = a + (v.total_freight_amount === undefined ? 0 : v.total_freight_amount), 0) * .12}
                      className='form-control form-control-solid fw-bold'
                      thousandSeparator={','}
                      allowLeadingZeros={false}
                      decimalScale={2}
                      onValueChange={(values) => {
                        onNumericChange(values, 'total_vat');
                      }}
                    />
                    <div className='text-danger mtc-2'>
                      <ErrorMessage name='total_vat' />
                    </div>

                    <div className='form-text'>
                      Total VAT Amount
                    </div>
                  </div>
                  <div className='col-md-3 mt-5'>
                    <label className='d-flex align-items-center form-label-sm fw-bold'>
                      <span className='required'>Total Amount Paid</span>
                    </label>
                    <NumericFormat
                      maxLength={10}
                      name='total_paid'
                      placeholder='Total Amount Paid'
                      value={initValues.total_paid}
                      className='form-control form-control-solid fw-bold'
                      thousandSeparator={','}
                      allowLeadingZeros={false}
                      decimalScale={2}
                      onValueChange={(values) => {
                        onNumericChange(values, 'total_paid');
                      }}
                    />
                    <div className='text-danger mtc-2'>
                      <ErrorMessage name='total_paid' />
                    </div>

                    <div className='form-text'>
                      Total Amount Paid
                    </div>
                  </div>

                </div>
              </Form>
            </div >
          </div >
        )}
      </Formik >
      {
        initValues.account_id > 0 &&
        <DataTable columns={columns}
          data={waybills}
          striped
          selectableRowsHighlight
          pointerOnHover
          progressPending={loading}
          customStyles={customStyles}
          subHeader
          subHeaderComponent={<SelectComponent waybill={waybillData} load={loadWaybills} select={onSelectedWaybill} />}
          className='table-responsive' />
      }
    </>
  )
}

export { SOACreate }

type SaveProps = {
  loading: boolean
  valid: boolean
  client: string,
  account_id: number
}

const SaveSOA: React.FC<SaveProps> = ({ loading, valid, client, account_id = 0 }) => {
  const { values, submitForm, validateForm } = useFormikContext();
  const handleSave = () => {
    console.log(values)
    validateForm().then((result) => {
      console.log(result);
    })
    submitForm();
  }
  return (
    <div className='card-header border-0 pt-5'>
      <h3 className='card-title align-items-start flex-column'>
        <span className='card-label fw-bold fs-3 mb-1'>Create Statement of Account to {client}</span>
        <span className='text-muted mt-1 fw-semibold fs-7'>Help?</span>
      </h3>
      <div className='card-toolbar' style={{ width: 200 }}>
        <div className='d-flex flex-row' style={{ justifyContent: 'space-between' }}>
          <button type='submit' disabled={!valid} onClick={() => handleSave()}
            className='btn btn-success' style={{ marginRight: 10 }}>
            <i className="bi bi-save"> </i>
            {!loading && <span className='indicator-label'>Save</span>}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          {
            account_id > 0
            &&
            <a href={`/section/accounts/print/${account_id}`} className='btn btn-light-primary'>
              <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-3' />
              Print
            </a>
          }
        </div>
      </div>
    </div>
  )
}

