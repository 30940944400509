import moment from 'moment'
import * as Yup from 'yup'
import { WaybillModel, WaybillItemModel } from '../../../../model/models'

export interface ICreateWaybill extends WaybillModel {

}

const createWaybillSchemas = [
  Yup.object({
    waybill_no: Yup.number()
      .min(1, 'The minimum # is 1')
      .required('The Waybill # is required'),
    shipper_name: Yup.string().min(1).required('Shippers name is required'),
    shipper_address: Yup.string().min(1).required('Shippers address is required'),
    shipper_contact_no: Yup.string().min(1).required('Shippers contact is required'),
    consignee: Yup.string().required('Consignee name is required'),
    address: Yup.string().required('Consignee address is required'),
    mobile: Yup.string().required('Consignee contact no is required'),
    total_freight_amount: Yup.number()
      .min(0, 'Freight charge amount is required')
      .required('Freight charge amount is required'),
    items: Yup.array().of(
      Yup.object().shape({
        description: Yup.string().required('Item description is required')
      })
    )
  }),
]

const waybillItemModel: WaybillItemModel = {
  id: 0,
  line: 0,
  waybill_id: 0,
  description: '',
  freight_amount: 0.0,
  freight_vat: 0.0,
  weight: 0,
  quantity: 1,
  measurement: ''
}

const initItems: WaybillItemModel[] = [waybillItemModel];

const inits: ICreateWaybill = {
  id: 0,
  waybill_no: Number(localStorage.getItem('waybill_no_last')),
  status: 1,
  publishedAt: moment().toDate(),
  consignee: '',
  address: '',
  invoice_no: '',
  date: new Date(),
  paid: false,
  total_freight_amount: 0,
  total_freight_vat: 0,
  total_freight_paid: 0,
  total_weight: 0,
  guid: '',
  charge_to: 1,
  reference: '',
  scpwd_discount: 0,
  received_date: new Date(),
  mobile: '',
  manifest_id: 0,
  manifest_sequence: 0,
  shipper_name: '',
  shipper_address: '',
  shipper_contact_no: '',
  createdAt: moment().toDate(),
  updatedAt: moment().toDate(),
  account_id: null,
  createdby_id: 0,
  updatedby_id: 0,
  received_by: '',
  items: initItems, 
  waybill_id: 0,
  item_description: '',
  branch: '',
  total_quantity: 0
};

export {createWaybillSchemas, inits, waybillItemModel}
