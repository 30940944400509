/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect, ReactNode, useMemo } from 'react'
import noUiSlider from 'nouislider'
import { useLayout } from '../../core'
import { KTSVG } from '../../../helpers'
import { DefaultTitle } from './page-title/DefaultTitle'
import { ThemeModeSwitcher } from '../../../partials'
import Select, { SingleValue, StylesConfig } from 'react-select';
import { defaultTheme } from 'react-select';
import Button from '@atlaskit/button';
import { RootState } from '../../../../setup';
import { UserModel } from '../../../../app/modules/auth';
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { update } from '../../../../app/config/redux/UserConfigCreateSlice'

const { colors } = defaultTheme;
const selectStyles: StylesConfig<BranchOption, false> = {
  control: (provided) => ({
    ...provided,
    minWidth: 240,
    margin: 8,
  }),
  menu: () => ({ boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)' }),
};

const HeaderToolbar = () => {
  const dispatch = useDispatch()
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
  const { classes } = useLayout()
  const [status, setStatus] = useState<string>('1')
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState<BranchOption | null>()
  const [branchDisabled, setBranchDisabled] = useState(false)
  const [branchOptions] = useState<BranchOption[]>(
    [{ value: '1', label: 'Manila' },
    { value: '2', label: 'Calapan' }]
  );

  useEffect(() => {
    const rangeSlider = document.querySelector('#kt_toolbar_slider')
    const rangeSliderValueElement = document.querySelector('#kt_toolbar_slider_value')
    if (!rangeSlider || !rangeSliderValueElement) {
      return
    }

    // @ts-ignore
    noUiSlider.create(rangeSlider, {
      start: [5],
      connect: [true, false],
      step: 1,
      format: {
        to: function (value) {
          const val = +value
          return Math.round(val).toString()
        },
        from: function (value) {
          return value
        },
      },
      range: {
        min: [1],
        max: [10],
      },
    })

    // @ts-ignore
    rangeSlider.noUiSlider.on('update', function (values, handle) {
      rangeSliderValueElement.innerHTML = values[handle]
    })

    const handle = rangeSlider.querySelector('.noUi-handle')
    if (handle) {
      handle.setAttribute('tabindex', '0')
    }

    // @ts-ignore
    handle.addEventListener('click', function () {
      // @ts-ignore
      this.focus()
    })

    // @ts-ignore
    handle.addEventListener('keydown', function (event) {
      // @ts-ignore
      const value = Number(rangeSlider.noUiSlider.get())
      // @ts-ignore
      switch (event.which) {
        case 37:
          // @ts-ignore
          rangeSlider.noUiSlider.set(value - 1)
          break
        case 39:
          // @ts-ignore
          rangeSlider.noUiSlider.set(value + 1)
          break
      }
    })
    return () => {
      // @ts-ignore
      rangeSlider.noUiSlider.destroy()
    }
  }, [value])

  const branchChange = (e: SingleValue<BranchOption>) => {
    setValue(e)
    setBranchDisabled(user.branch !== 0)
    if(e !== null) {
      localStorage.setItem('user_branch', e?.value)
    }
    dispatch(update({branchId: e?.value}))
    
  }
  useMemo(() => {
    const userBranch = branchOptions.find(sel => sel.value === user.branch.toString());
    localStorage.setItem('user_branch', userBranch !== undefined ? user.branch.toString() : '1')
    branchChange(userBranch !== undefined ? userBranch : { value: '1', label: 'Manila'})
  }, [])

  return (
    <div className='toolbar d-flex align-items-stretch'>
      {/* begin::Toolbar container */}
      <div
        className={`${classes.headerContainer.join(
          ' '
        )} py-6 py-lg-0 d-flex flex-column flex-lg-row align-items-lg-stretch justify-content-lg-between`}
      >
        <DefaultTitle />
        <div className='d-flex align-items-stretch pt-3 pt-lg-0'>
          {/* begin::Action wrapper */}
          <div className='d-flex align-items-center mx-3'>
            <Dropdown
              isOpen={isOpen}
              onClose={() => setIsOpen(false)}
              target={
                <Button
                  iconAfter={<ChevronDown />}
                  onClick={() => setIsOpen((prev) => !prev)}
                  isSelected={isOpen}
                >
                  {value ? `Branch: ${value.label}` : 'Select a branch'}
                </Button>
              }
            >
              <Select
                autoFocus
                backspaceRemovesValue={false}
                components={{ DropdownIndicator, IndicatorSeparator: null }}
                controlShouldRenderValue={false}
                hideSelectedOptions={false}
                isClearable={false}
                menuIsOpen
                onChange={(e: SingleValue<BranchOption>) => {
                  branchChange(e)
                  setIsOpen(false);
                }}
                options={branchOptions}
                styles={selectStyles}
                tabSelectsValue={false}
                value={value}
                closeMenuOnSelect
                isDisabled={branchDisabled}
              />
            </Dropdown>
          </div>

          {/* end::Action wrapper */}

          {/* begin::Action wrapper */}

          {/* end::Action wrapper */}

          {/* begin::Action wrapper */}
          <div className='d-flex align-items-center'>
            {/* begin::Label */}
            <span className='fs-7 text-gray-700 fw-bolder pe-3 d-none d-xxl-block'>
              Quick Tools:
            </span>
            {/* end::Label */}

            {/* begin::Actions */}
            <div className='d-flex'>
              {/* begin::Action */}

              {/* begin::Theme mode */}
              <div className='d-flex align-items-center'>
                <ThemeModeSwitcher toggleBtnClass='btn btn-sm btn-icon btn-icon-muted btn-active-icon-primary' />
              </div>
              {/* end::Theme mode */}
            </div>
            {/* end::Actions */}
          </div>
          {/* end::Action wrapper */}
        </div>
        {/* end::Toolbar container */}
      </div>
    </div>
  )
}

export { HeaderToolbar }

export interface BranchOption {
  readonly value: string;
  readonly label: string;
}


const Menu = (props: JSX.IntrinsicElements['div']) => {
  const shadow = 'hsla(218, 50%, 10%, 0.1)';
  return (
    <div
      style={{
        backgroundColor: 'white',
        borderRadius: 4,
        boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
        marginTop: 8,
        position: 'absolute',
        zIndex: 2,
      }}
      {...props}
    />
  );
}

const Blanket = (props: JSX.IntrinsicElements['div']) => (
  <div
    style={{
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      position: 'fixed',
      zIndex: 1,
    }}
    {...props}
  />
)

const Dropdown = ({
  children,
  isOpen,
  target,
  onClose,
}: {
  children?: ReactNode;
  readonly isOpen: boolean;
  readonly target: ReactNode;
  readonly onClose: () => void;
}) => (
  <div style={{ position: 'relative' }}>
    {target}
    {isOpen ? <Menu>{children}</Menu> : null}
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </div>
);
const Svg = (p: JSX.IntrinsicElements['svg']) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    focusable="false"
    role="presentation"
    {...p}
  />
)

const DropdownIndicator = () => (
  <div style={{ color: colors.neutral20, height: 24, width: 32 }}>
    <Svg>
      <path
        d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </Svg>
  </div>
);
const ChevronDown = () => (
  <Svg style={{ marginRight: -6 }}>
    <path
      d="M8.292 10.293a1.009 1.009 0 0 0 0 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 0 0 0-1.419.987.987 0 0 0-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 0 0-1.406 0z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </Svg>
)