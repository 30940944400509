import clsx from 'clsx';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import DataTable, { TableColumn } from 'react-data-table-component';
import { ExpandableRowsComponent } from 'react-data-table-component/dist/src/DataTable/types';
import { NumberFormatValues, NumericFormat } from 'react-number-format';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { RootState } from '../../../../setup';
import { UserModel, WaybillItemsAttributes, WaybillItemsResponseData, WaybillResponseData, WaybillUpdateResponseData } from '../../../model/models';
import { getWaybillItems, getWaybillsByStartsWithNo, getWaybillsByStatus, searchWaybillContains, updateWaybill } from '../redux/WaybillsApi';
import { storeItems } from '../redux/WaybillSlice';
import { useNavigate } from 'react-router-dom';

const caseInsensitiveSort = (rowA: WaybillResponseData, rowB: WaybillResponseData) => {
  const a = rowA.attributes.consignee.toLowerCase();
  const b = rowB.attributes.consignee.toLowerCase();
  if (a > b) {
    return 1;
  }

  if (b > a) {
    return -1;
  }

  return 0;
};

const sortingDate = (rowA: WaybillResponseData, rowB: WaybillResponseData) => {
  const a = rowA.attributes.date
  const b = rowB.attributes.date
  if (a > b) {
    return 1;
  }

  if (b > a) {
    return -1;
  }

  return 0;
};

const customStyles = {
  headCells: {
    style: {
      fontWeight: 'bold'
    },
  }
};

const WaybillLoad: React.FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const [waybillData, setWaybillData] = useState<WaybillResponseData[]>()
  const [valid, setValid] = useState(true)
  const [loading, setLoading] = useState(false)
  const [totalRows, setTotalRows] = useState(0)
  const [pageSize, setPageSize] = useState(25)
  const [currentRow, setCurrentRow] = useState<WaybillResponseData>()
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
  const branch = useSelector<RootState>(({ configs }) => configs.branchId, shallowEqual) as string

  const searchWaybills = (waybill: number, branch_id: any) => {
    setTimeout(() => {
      setLoading(true)
      getWaybillsByStartsWithNo(waybill, 1, branch_id).then((response) => {
        setTotalRows(response.data.meta.pagination.total);
        setPageSize(response.data.meta.pagination.pageSize);
        setWaybillData(response.data.data)
        setLoading(false)
        setValid(response.data.meta.pagination.total > 0)
      });
    }, 3000)
  }

  const loadWaybills = React.useCallback((status: number, page: number, pageSize: number, branch_id: any) => {
    setTimeout(() => {
      getWaybillsByStatus(status, page, pageSize, branch_id).then((response) => {
        setTotalRows(response.data.meta.pagination.total);
        setPageSize(response.data.meta.pagination.pageSize);
        setWaybillData(response.data.data)
        setLoading(false)
      });
    }, 2000)
  }, [])

  useEffect(() => {
    debugger
    setLoading(true);
    loadWaybills(1, 1, pageSize, branch)
    if (user.load === false) {
      navigate(`/error/404`);
    }

  }, [branch])

  const onWaybillNoChange = (e: NumberFormatValues) => {
    if (e.value !== undefined && e.value.length > 2) {
      searchWaybills(e.floatValue!, branch)
    }
  }

  const rowExpandedToggled = (expanded: boolean, row: WaybillResponseData) => {
    if (expanded) {
      getWaybillItems(row.attributes.waybill_id).then((response) => {
        dispatch(storeItems(response.data))
      })
    }
  }

  const searchConsignee = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchText = e.currentTarget.value
    if (searchText.length > 2) {
      setLoading(true)
      setTimeout(() => {
        searchWaybillContains(searchText, 1, branch).then((response) => {
          setTotalRows(response.data.meta.pagination.total);
          setPageSize(response.data.meta.pagination.pageSize);
          setWaybillData(response.data.data)
          setLoading(false)
          setValid(response.data.meta.pagination.total > 0)
        });
      }, 2000)
    }
  }

  const handlePageChange = (page: number) => {
    loadWaybills(1, page, pageSize, branch);
  }

  const handlePerRowsChange = async (page: number, pageSize: number) => {
    setLoading(true);
    loadWaybills(1, page, pageSize, branch);
    setPageSize(pageSize);
    setLoading(false);
  }

  const columns: TableColumn<WaybillResponseData>[] = [
    {
      name: '#',
      selector: row => row.attributes.waybill_no,
      sortable: true,
      width: '100px',
      cell: row => {
        return (
          <span className='fs-7'>{row.attributes.waybill_no}</span>
        )
      }
    },
    {
      name: 'SHIPPER',
      cell: row => {
        return (
          <>
            {/* <div className='d-flex flex-column flex-row-auto w-120px'>
              <div className="d-flex flex-column" style={{textOverflow: 'ellipsis'}}>
                <span className='text-dark fw-bold mb-1 fs-7' >
                  {row.attributes.shipper_name}
                </span>
              </div>

              <div className="d-flex flex-column">
                <span className='text-muted fw-semibold d-block fs-7'>
                  {row.attributes.shipper_address}
                </span>
              </div>
            </div> */}
            <div className='text-dark fw-bold mb-1 fs-7'>
              {row.attributes.shipper_name} <br />
              <span className='text-muted'>{row.attributes.shipper_address}</span>
            </div>
          </>
        )
      },
      hide: 728
    },
    {
      name: 'CONSIGNEE',
      cell: row => {
        return (
          <>
            <div className='text-dark fw-bold mb-1 fs-7'>
              {row.attributes.consignee} <br />
              <span className='text-muted'> {row.attributes.address}</span>
            </div>
          </>
        )
      },
      hide: 600,
      sortable: true,
      sortField: 'consignee',
      sortFunction: caseInsensitiveSort
    },
    {
      name: 'DATE',
      width: '120px',

      selector: row => moment(row.attributes.date).format('MM/DD/YYYY'),
      sortable: true,
      sortField: 'date',
      sortFunction: sortingDate
    },
    {
      name: 'STATUS',
      width: '80px',
      hide: 728,
      selector: row => row.attributes.status,
      cell: row => {
        if (row.attributes.status === 1) {
          return (
            <span className='badge badge-light-primary fs-8 fw-bold my-2'>Warehouse</span>
          )
        } else if (row.attributes.status === 2) {
          return (
            <span className='badge badge-light-warning fs-8 fw-bold my-2'>InTransit</span>
          )
        } else if (row.attributes.status === 3) {
          return <span className='badge badge-light-info fs-8 fw-bold my-2'>Arrived</span>
        } else if (row.attributes.status === 5) {
          return <span className='badge badge-light-danger fs-8 fw-bold my-2'>Loaded</span>
        } else {
          return <span className='badge badge-light-success fs-8 fw-bold my-2'>Delivered</span>
        }
      }
    },
    {
      name: 'LOAD',
      button: true,
      width: '75px',
      cell: row => (
        <div>
          <button
            onClick={(e) => {

              updateWaybill(row.id, { "status": 5 }).then((result) => {
                const data = result.data as WaybillUpdateResponseData;
                if (data.data.attributes.status === 5)
                  loadWaybills(1, 1, pageSize, branch)
                Swal.fire({
                  position: 'top-end',
                  title: `Loaded into the truck successfully. Waybill # ${row.attributes.waybill_no}`,
                  timer: 2000,
                  icon: 'success',
                  showConfirmButton: true
                })
              })
            }}
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            title='Load'>
            <i className="fas fa-truck-fast fs-2x"></i>
          </button>
        </div>
      ),
    }
  ];

  return (
    <div className="card card-custom">
      <div className="card-header">
        <h3 className="card-title">Lodi</h3>
        <div className="card-toolbar">

        </div>
      </div>
      <div className="card-body">
        <div className='d-flex'>
          <div className="col-md-6 m-5">
            <label className="form-label fw-bold">Waybill #</label>
            <NumericFormat
              maxLength={10}
              name='waybill_no'
              placeholder='Waybill #'
              onValueChange={onWaybillNoChange}
              className={clsx(
                'form-control form-control-solid fw-bold',
                { 'is-invalid': !valid },
                {
                  'is-valid': valid,
                }
              )} />
          </div>
          <div className="col-md-6 m-5">
            <label className="form-label fw-bold">Consignee</label>
            <input
              maxLength={60}
              name='consingee'
              placeholder='Consignee'
              onChange={searchConsignee}
              className='form-control form-control-solid fw-bold' />
          </div>
        </div>

        <div>
          <DataTable columns={columns}
            responsive
            data={waybillData!}
            striped
            selectableRowsHighlight
            pointerOnHover
            progressPending={loading}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            pagination
            paginationPerPage={pageSize}
            paginationServer
            paginationTotalRows={totalRows}
            customStyles={customStyles}
            expandableRowsComponent={ExpandendComponent}
            onRowExpandToggled={(e, row) => {
              rowExpandedToggled(e, row)
              setCurrentRow(row)
            }}
            onRowClicked={(row) => setCurrentRow(row)}
            expandableRows={true}
            expandOnRowClicked={false}
            expandOnRowDoubleClicked={false}
            expandableRowsHideExpander={false}
            expandableRowExpanded={(row) => (row === currentRow)}
            className='table-responsive' />
        </div>
      </div>
      <div className="card-footer">
      </div>
    </div>
  )
}

export { WaybillLoad }


const ExpandendComponent: ExpandableRowsComponent<WaybillResponseData> = ({ data }) => {
  const waybillItemData: WaybillItemsResponseData = useSelector<RootState>(({ waybills }) => waybills.items, shallowEqual) as WaybillItemsResponseData
  const [waybillItems, setWaybillItems] = useState<WaybillItemsAttributes[]>()
  useEffect(() => {
    if (waybillItemData !== undefined && waybillItemData.data.length > 0) {
      setWaybillItems(waybillItemData.data)
    }
  }, [waybillItemData])
  return (
    <>
      <div className='d-flex flex-column py-10'>
        <div className="d-flex flex-column flex-row-auto w-240px px-5">
          <div className="d-flex flex-column">FROM</div>
          <div className="d-flex flex-column">
            <span className='text-dark fw-bold text-hover-primary mb-1 fs-7'>
              {data.attributes.shipper_name}
            </span>
          </div>

          <div className="d-flex flex-column">
            <span className='text-muted fw-semibold d-block fs-7'>
              {data.attributes.shipper_address}
            </span>
          </div>
        </div>
        <div className="d-flex flex-column flex-row-auto w-240px px-5">
          <div className="d-flex flex-column">TO</div>
          <div className="d-flex flex-column">
            <span className='text-dark fw-bold text-hover-primary mb-1 fs-7'>
              {data.attributes.consignee}
            </span>
          </div>

          <div className="d-flex flex-column">
            <span className='text-muted fw-semibold d-block fs-7'>
              {data.attributes.address}
            </span>
          </div>
        </div>
        <div className='d-flex flex-colum flex-row-auto w-240 px-5 pt-5'>
          <div className='d-flex flex-column flex-row-auto'>ITEMS</div>
        </div>
        <div className='d-flex flex-colum flex-row-auto w-240 px-5'>
          <div className=' d-flex flex-column flex-row-auto'>
            <table className='table-condesed'>
              <tbody>
                {
                  waybillItems !== undefined &&
                  waybillItems.map((items, index) => {
                    return (
                      <tr key={index}>
                        <td>{items.attributes.quantity}</td>
                        <td>{items.attributes.description}</td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}