import { Page, Document, Font, View, Image, Text } from '@react-pdf/renderer';
import { useEffect, useState } from 'react';
import { AccountData, CustomerData, TruckData, WaybillModel } from '../../../model/models';
import { getAccountById, getCustomerById } from '../redux/AccountsApi';
import { getWaybillsByAccountId } from '../../waybills/redux/WaybillsApi';
import moment from 'moment';
import React from 'react';
import { right } from '@popperjs/core';


Font.register({
    family: 'Libre',
    src: 'https://fonts.gstatic.com/s/librebaskerville/v4/pR0sBQVcY0JZc_ciXjFsKyyZRYCSvpCzQKuMWnP5NDY.ttf'
});


type Props = {
    id: number
}


const SOAPrint: React.FC<Props> = ({ id }) => {
    const [accountData, setAccountData] = useState<AccountData>()
    const [waybills, setWaybills] = useState<WaybillModel[]>([])
    const [customer, setCustomer] = useState<CustomerData>()
    const [totalAmount, setTotalAmount] = useState(0)

    useEffect(() => {
        const getWaybillsByAccount = (accountId: number) => {
            if (waybills.length === 0) {
                getWaybillsByAccountId(accountId).then((result) => {
                    const selectedWaybills = result.data.data.map(item => {
                        const waybill_id = item.id
                        item.attributes.id = waybill_id
                        return item.attributes
                    })
                    setTotalAmount(selectedWaybills.reduce((a, v) => a = a + (v.total_freight_amount === undefined ? 0 : v.total_freight_amount), 0))
                    setWaybills(selectedWaybills)
                })
            }
        }
        const loadSOA = () => {
            getAccountById(id).then((response) => {     
                if (response.status === 200) {
                    const soaData = response.data.data;
                    getCustomerById(soaData.attributes.customer_id).then((result) => {
                        setCustomer(result.data.data[0])
                    })
                    getWaybillsByAccount(soaData.attributes.account_id)
                    setAccountData(response.data.data)
                }
            })
        }
        loadSOA()
        console.log(waybills)
    }, [id])

    return (

        <Document>
            <Page size={[612.0, 792.0]} orientation='portrait'>
                <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                    <Image style={{ width: 200, height: 50, marginTop: 10 }} src='/media/logos/Aguileon_Logo.png' />
                </View>
                <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                    <Text style={{ fontFamily: 'Libre', fontSize: 7 }}>1426 Geliños St. Brgy. 342, Zone 34, District III, Sta. Cruz, Manila</Text>
                </View>
                <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                    <Text style={{ fontFamily: 'Libre', fontSize: 10, marginTop: 10 }}>Statement of Account # {accountData?.attributes.account_no}</Text>
                </View>
                <View style={{ flexDirection: 'row', justifyContent: 'space-evenly' }}>
                    <Text style={{ fontFamily: 'Libre', fontSize: 8, marginTop: 10, width: 250 }}>TO: {customer?.attributes.name}</Text>
                    <Text style={{ fontFamily: 'Libre', fontSize: 8, marginTop: 10, width: 250, textAlign: right, fontWeight: 'extrabold' }}>
                        Statement Date: {moment(accountData?.attributes.statement_date).format('DD MMM yyyy')}
                    </Text>
                </View>
                <View style={{ flexDirection: 'row', justifyContent: 'space-evenly' }}>
                    <Text style={{ fontFamily: 'Libre', fontSize: 8, marginTop: 2, width: 250 }}>{customer?.attributes.contact_person === '' ? 'No contact person' : customer?.attributes.contact_person}</Text>
                    <Text style={{ fontFamily: 'Libre', fontSize: 8, marginTop: 2, width: 250, textAlign: right, fontWeight: 'extrabold' }}>
                        Due Date: {moment(accountData?.attributes.due_date).format('DD MMM yyyy')}
                    </Text>
                </View>
                <View style={{ flexDirection: 'row', justifyContent: 'space-evenly' }}>
                    <Text style={{ fontFamily: 'Libre', fontSize: 8, marginTop: 2, width: 100, }}>{customer?.attributes.billing_address === '' ? 'No billing address' : customer?.attributes.billing_address}</Text>
                    <Text style={{ fontFamily: 'Libre', fontSize: 8, marginTop: 2, width: 400, textAlign: right, fontWeight: 'extrabold' }}>
                        Total Due Amount: {totalAmount?.toLocaleString()}
                    </Text>
                </View>
                <View fixed style={{ flexDirection: 'row', justifyContent: 'space-evenly', marginTop: 5, fontFamily: 'Libre', paddingLeft: 10, borderBottom: .5 }}>
                    <View style={{ flexDirection: 'row', width: 80, margin: 5, paddingTop: 5 }}>
                        <Text style={{ fontSize: 8 }}>Waybill #  </Text>
                    </View>
                    <View style={{ flexDirection: 'row', width: 80, margin: 5, fontFamily: 'Libre', paddingTop: 5 }}>
                        <Text style={{ fontSize: 8 }}>Date </Text>
                    </View>
                    <View style={{ flexDirection: 'row', width: 140, margin: 5, fontFamily: 'Libre', paddingTop: 5 }}>
                        <Text style={{ fontSize: 8 }}>Consignee </Text>
                    </View>
                    <View style={{ flexDirection: 'row', width: 280, margin: 5, fontFamily: 'Libre', paddingTop: 5 }}>
                        <Text style={{ fontSize: 8 }}>Items  </Text>
                    </View>
                    <View style={{ flexDirection: 'row', width: 80, margin: 5, fontFamily: 'Libre', paddingTop: 5 }}>
                        <Text style={{ fontSize: 8 }}>Total Qty</Text>
                    </View>
                    <View style={{ flexDirection: 'row', width: 100, margin: 5, fontFamily: 'Libre', paddingTop: 5 }}>
                        <Text style={{ fontSize: 8 }}>Total Freight (PHP) </Text>
                    </View>
                </View>
                {
                    waybills !== undefined && waybills?.length > 0 &&
                    waybills?.map((waybill, index) => {
                        return <View key={index} style={{ flexDirection: 'row', justifyContent: 'flex-start', marginTop: 5, fontFamily: 'Libre', marginLeft: 10 }}>
                            <View style={{ flexDirection: 'row', width: 80, paddingLeft: 5, paddingTop: 1 }}>
                                <Text style={{ fontSize: 7 }}>{index + 1}.    {waybill.waybill_no}</Text>
                            </View>
                            <View style={{ flexDirection: 'row', width: 80, fontFamily: 'Libre', paddingTop: 1 }}>
                                <Text style={{ fontSize: 7 }}>{moment(waybill.date).format('MM/DD/YYYY')}</Text>
                            </View>
                            <View style={{ flexDirection: 'row', width: 160, fontFamily: 'Libre', paddingTop: 1 }}>
                                <Text style={{ fontSize: 7 }}>{waybill.consignee}</Text>
                            </View>
                            <View wrap style={{ flexDirection: 'row', width: 280, marginHorizontal: 5, fontFamily: 'Libre', paddingTop: 1  }}>
                                <Text style={{ fontSize: 7, textOverflow: 'ellipsis' }}>{waybill.item_description} </Text>
                            </View>
                            <View style={{ flexDirection: 'row', width: 60, marginHorizontal: 5, fontFamily: 'Libre', paddingTop: 1}}>
                                <Text style={{ fontSize: 7, textAlign: 'center', width: 60 }}>{waybill.total_quantity?.toLocaleString()}</Text>
                            </View>
                            <View style={{
                                flexDirection: 'row', justifyContent: 'flex-end',
                                width: 80, marginHorizontal: 5, fontFamily: 'Libre', paddingTop: 1, paddingRight: 25, textAlign: 'right'
                            }}>
                                <Text style={{ fontSize: 7, textAlign: 'right' }}>{waybill.total_freight_amount?.toLocaleString('en-PH', {minimumFractionDigits:2})} </Text>
                            </View>
                        </View>
                    })
                }
                <View style={{ flexDirection: 'row', justifyContent: 'space-evenly', marginTop: 15 }}>
                    <Text style={{ fontFamily: 'Libre', fontSize: 8,  width: 300, }}></Text>
                    <Text style={{ fontFamily: 'Libre', fontSize: 8,  width: 200, textAlign: right, fontWeight: 'extrabold' }}>
                        TOTAL AMOUNT   {totalAmount?.toLocaleString('en-PH', {minimumFractionDigits:2})}
                    </Text>
                </View>
                <View style={{ flexDirection: 'row', justifyContent: 'space-evenly', borderTop: .5 }}>
                    <Text style={{ fontFamily: 'Libre', fontSize: 8, marginTop: 10,  width: 300, }}></Text>
                    <Text style={{ fontFamily: 'Libre', fontSize: 8, marginTop: 10, width: 200, textAlign: right, fontWeight: 'extrabold' }}>
                        TOTAL CURRENT BILL AMOUNT   {totalAmount?.toLocaleString('en-PH', {minimumFractionDigits:2})}
                    </Text>
                </View>

                <View style={{ flexDirection: 'row', justifyContent: 'space-evenly', borderTop: .5, paddingTop: 30}}>
                    <Text style={{ fontFamily: 'Libre', fontSize: 8, marginTop: 10,  width: 250, }}></Text>
                    <Text style={{ fontFamily: 'Libre', fontSize: 8, marginTop: 10, width: 250, textAlign: 'center', fontWeight: 'extrabold', borderBottom: 1 }}> 
                    ARMIN DIMATULAC
                    </Text>
                </View> 
                <View style={{ flexDirection: 'row', justifyContent: 'space-evenly'}}>
                    <Text style={{ fontFamily: 'Libre', fontSize: 8, marginTop: 2,  width: 250, }}></Text>
                    <Text style={{ fontFamily: 'Libre', fontSize: 8, marginTop: 2, width: 250, textAlign: 'center', fontWeight: 'extrabold' }}> 
                    CERTIFIED CORRECT / PAYMENT NOT RECEIVED
                    </Text>
                </View>
            </Page>
        </Document>
    )
}
export { SOAPrint }