/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import { Manifest, TruckData, WaybillIdNo, WaybillResponseData } from '../../../model/models';
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../setup';
import { Formik, Form, Field, ErrorMessage, useFormikContext, FormikHelpers } from 'formik'
import DatePicker from "react-datepicker";
import { NumberFormatValues, NumericFormat } from 'react-number-format'
import { getManifestById, getManifestByNo, getTruckDetails, updateManifest } from '../redux/ManifestApi';
import { useNavigate, useParams } from "react-router-dom";
import clsx from 'clsx';
import { detailsManifestSchemas, IDetailsManifest, inits } from './ManifestDetailsHelper';
import Select, { ActionMeta, InputActionMeta } from 'react-select'
import Swal from 'sweetalert2';
import { UserModel } from '../../auth/core/_models';
import { getWaybillById, getWaybillItems, getWaybillsByManifestId, getWaybillsByStartsWithNo, searchWaybillContains, updateWaybill } from '../../waybills/redux/WaybillsApi';
import DataTable, { TableColumn } from 'react-data-table-component';
import moment from 'moment';
import { KTSVG } from '../../../../_metronic/helpers';
import { fetchTrucksData } from '../redux/TruckDataSlice';
import { SelectComponent } from './SelectComponent';

type Props = {
  className: string
}

const customStyles = {
  headCells: {
    style: {
      fontWeight: 'bold'
    },
  }
};

const ManifestDetails: React.FC<Props> = ({ className }) => {
  const { id } = useParams() as { id: string }
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [currentSchema] = useState(detailsManifestSchemas[0])
  const [invalidManifest, setInvalidManifest] = useState(true)
  const [manifest, setManifest] = useState<Manifest>(inits)
  const [waybillData, setWaybillData] = useState<WaybillIdNo[]>()
  const [waybills, setWaybills] = useState<WaybillResponseData[]>([])
  const truckData: TruckData[] = useSelector<RootState>(({ truckData }) => truckData.data?.data, shallowEqual) as TruckData[]
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
  const branch = useSelector<RootState>(({ configs }) => configs.branchId, shallowEqual) as string
  const [truckOptions, setTruckOptions] = useState<{ value: number, label: string }[]>([])
  const [totalCollect, setTotalCollect] = useState(0)
  const [totalAccount, setTotalAccount] = useState(0)
  const [totalPrepaid, setTotalPrepaid] = useState(0)

  const submit = async (values: IDetailsManifest, { setStatus, setSubmitting }: FormikHelpers<IDetailsManifest>) => {
    values.user_id = user.id
    setLoading(true)
    setSubmitting(true)
    updateManifest(Number(id), values)
      .then((response) => {
        if (response.status === 200) {
          const manifestId = response.data.data.id
          updateManifest(response.data.data.id, { "manifest_id": manifestId })
          const data = [...waybills]
          data.forEach((item) => {
            if (item.attributes.item_description === '' || item.attributes.item_description === null) {
              getWaybillItems(item.attributes.waybill_id).then((itemResponse) => {
                const description = itemResponse.data.data.map(i => i.attributes.description).join(' / ')
                const qty = itemResponse.data.data.reduce((a, v) => a = a + (v.attributes.quantity === undefined ? 0 : v.attributes.quantity), 0)
                updateWaybill(item.id, {
                  manifest_id: manifestId,
                  item_description: description,
                  total_quantity: qty,
                  status: 2
                })
              })
            } else {
              setTimeout(() => {
                updateWaybill(item.id, {
                  manifest_id: manifestId,
                  status: 2
                })
              }, 1000)
            }

          })

          const updateData = data.map((item) => {
            item.attributes.status = 2
            item.attributes.manifest_id = manifestId
            return item
          })
          setWaybills(updateData)

          setTimeout(() => {
            Swal.fire({
              title: 'Successs!',
              text: `Manifest # ${values.manifest_no} has been successfully save!`,
              icon: 'success',
              confirmButtonText: 'Print',
              cancelButtonText: 'Views',
              showCancelButton: true,
              customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-primary'
              }
            }).then((result) => {
              setLoading(false)
              if (result.isConfirmed) {
                navigate(`/section/manifests/print/${response.data.data.id}`);
              } else if (result.dismiss === Swal.DismissReason.cancel) {
                navigate('/section/manifests/view');
              }
            })
          }, 5000)
        }
      })
      .catch((error) => {
        setLoading(false)
        setSubmitting(false)
        setStatus('Problem occured when saving your manifest.')
        Swal.fire({
          title: 'Error!',
          text: 'Problem occured when creating your manifest',
          icon: 'error',
          confirmButtonText: 'OK',
          iconColor: 'primary'
        })
        console.error(error)
      })
  }

  useEffect(() => {
    const loadManifest = () => {
      getManifestById(Number(id)).then((response) => {
        const manifestDataResult = response.data.data.attributes;
        manifestDataResult.departure_date = new Date(response.data.data.attributes.departure_date)
        manifestDataResult.arrival_date = new Date(response.data.data.attributes.arrival_date)
        console.log(manifest)
        setManifest(manifestDataResult)
        getWaybillsByManifest(manifestDataResult)
      })
    }
    const getWaybillsByManifest = (m: Manifest) => {
      if (waybills.length === 0) {
        getWaybillsByManifestId(Number(id)).then((result) => {
          if (result.data.meta.pagination.total > 0) {
            setWaybills(result.data.data)
          } else {
            getWaybillsByManifestId(m.manifest_id).then((r) => {
              if (r.data.meta.pagination.total > 0) {
                setWaybills(r.data.data)
              }
            })
          }
        })
      }
    }
    loadManifest()
    const collectWaybills = waybills.filter(items => items.attributes.charge_to === 1);
    setTotalCollect(collectWaybills.reduce((a, v) => a = a + (v.attributes.total_freight_amount === undefined ? 0 : v.attributes.total_freight_amount), 0))
    const prepaidWaybills = waybills.filter(items => items.attributes.charge_to === 2);
    setTotalPrepaid(prepaidWaybills.reduce((a, v) => a = a + (v.attributes.total_freight_amount === undefined ? 0 : v.attributes.total_freight_amount), 0))
    const accountWaybills = waybills.filter(items => items.attributes.charge_to === 3);
    setTotalAccount(accountWaybills.reduce((a, v) => a = a + (v.attributes.total_freight_amount === undefined ? 0 : v.attributes.total_freight_amount), 0))

    const loadTrucksData = () => {
      getTruckDetails().then((response) => {
        const trucks = response.data.data.map((item) => {
          return { value: item.attributes.truck_id, label: item.attributes.plate_no }
        })
        dispatch(fetchTrucksData(response.data))
        setTruckOptions(trucks)
      })
    }

    if (truckData === undefined) {
      loadTrucksData()
    } else {
      const trucks = truckData.map((item) => {
        return { value: item.attributes.truck_id, label: item.attributes.plate_no }
      })
      console.log(trucks)
      console.log(truckData)
      setTruckOptions(trucks)
    }

  }, [id, waybills.length]);


  const onManifestNoChange = (e: NumberFormatValues) => {
    const data = { ...manifest }
    data.manifest_no = e.value
    setManifest(data);
    getManifestByNo(e.value).then((response) => {
      setInvalidManifest(response.data.meta.pagination.total === 0)
    })
  }

  const onStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.currentTarget;
    const data = { ...manifest }
    data.status_id = Number(value)
    setManifest(data)
  }

  const onCargoSelected = (value: number) => {
    const data = { ...manifest }
    data.truck_id = value
    setManifest(data)
  }

  const onTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    const data = { ...manifest }
    if (e.currentTarget.name === 'description') {
      data.description = value
    } else if (e.currentTarget.name === 'destination') {
      const data = { ...manifest }
      data.destination = value
    } else if (e.currentTarget.name === 'driver') {
      const data = { ...manifest }
      data.driver = value
    }
    setManifest(data)
  }

  const onSelectedWaybill = (input: number, a: ActionMeta<WaybillIdNo>) => {
    if (a.action === 'select-option') {
      getWaybillById(input).then((response) => {
        const waybill = response.data.data.attributes
        if (waybills !== undefined) {
          const data = [...waybills]
          if (!waybills.find(item => item.attributes.waybill_id === waybill.waybill_id)) {
            data.push({ id: response.data.data.id, attributes: waybill })
            setWaybills(data)
          }
        }
      })
    }
  }

  const loadWaybills = (input: string, a: InputActionMeta) => {
    if (a.action === 'input-change') {
      getWaybills(input)
    }
  }

  const getWaybills = (input: string) => {
    if (!Number.isNaN(Number(input))) {
      getWaybillsByStartsWithNo(Number(input), 5, branch).then((response) => {
        const results = response.data.data.map((item) => {
          const label = `${item.attributes.waybill_no} > ${item.attributes.consignee}`;
          return { value: item.id, label: label }
        })
        setWaybillData(results)
      })
    } else {
      searchWaybillContains(input, 5, branch).then((result) => {
        const searchResults = result.data.data.map((item) => {
          const label = `${item.attributes.waybill_no} > ${item.attributes.consignee}`;
          return { value: item.id, label: label }
        })
        setWaybillData(searchResults)
      })
    }
  }

  const deleteWaybill = (waybillNo: number, id: number, manifest_id: number) => {
    Swal.fire({
      title: 'Remove?',
      text: `Are you sure you want to remove this waybill # ${waybillNo}?`,
      icon: 'warning',
      confirmButtonText: 'OK',
      cancelButtonText: 'Cancel',
      showCancelButton: true,
      customClass: {
        confirmButton: 'btn btn-danger',
        cancelButton: 'btn btn-primary'
      }
    }).then((result) => {
      if (result.isConfirmed) {
        const filtered = waybills.filter(item => item.attributes.waybill_no !== waybillNo)
        setWaybills(filtered)
        if (manifest_id > 0) {
          updateWaybill(id, {
            status: 5,
            manifest_id: null
          }).then((response) => {
            if (response.status === 200) {
              Swal.fire({
                title: 'Removed',
                text: `Successfully removed waybill # ${waybillNo}`,
                icon: 'success',
                confirmButtonText: 'OK'
              })
            }
          })
        } else {
          const filtered = waybills.filter(item => item.attributes.waybill_no !== waybillNo)
          setWaybills(filtered)
          Swal.fire({
            title: 'Removed',
            text: `Successfully removed waybill # ${waybillNo}`,
            icon: 'success',
            confirmButtonText: 'OK'
          })
        }
      }
    })
  }

  const setDepartureDateChange = (e: Date) => {
    const data = { ...manifest }
    data.departure_date = e
    data.arrival_date = moment(e).add(1, 'day').toDate()
    setManifest(data)
  }

  const setArrivalDateChange = (e: Date) => {
    const data = { ...manifest }
    data.arrival_date = e
    setManifest(data)
  }

  const onPercentageChange = (e: NumberFormatValues) => {
    const data = { ...manifest }
    data.percentage = e.floatValue === undefined ? 0 : e.floatValue
    setManifest(data)
  }


  const columns: TableColumn<WaybillResponseData>[] = [
    {
      name: 'WAYBILL#',
      cell: row => (
        <a href={`/section/waybills/details/${row.id}`} target="_blank">{row.attributes.waybill_no}</a>
      ),
      sortable: true,
      width: "120px"
    },
    {
      name: 'SHIPPER',
      selector: row => row.attributes.shipper_name,
      cell: row => {
        if (row.attributes.shipper_name === "0") {
          return "";
        } else {
          return row.attributes.shipper_name
        }
      }
    },
    {
      name: 'CONSIGNEE',
      selector: row => row.attributes.consignee,
      cell: row => row.attributes.consignee,
      sortable: true
    },
    {
      name: 'DESTINATION',
      selector: row => row.attributes.address,
      cell: row => row.attributes.address
    },
    {
      name: 'STATUS',
      width: "120px",
      selector: row => row.attributes.status,
      cell: row => {
        if (row.attributes.status === 1) {
          return (
            <span className='badge badge-light-primary fs-8 fw-bold my-2'>Warehouse</span>
          )
        } else if (row.attributes.status === 2) {
          return (
            <span className='badge badge-light-warning fs-8 fw-bold my-2'>InTransit</span>
          )
        } else if (row.attributes.status === 3) {
          return <span className='badge badge-light-info fs-8 fw-bold my-2'>Arrived</span>
        } else if (row.attributes.status === 5) {
          return <span className='badge badge-light-danger fs-8 fw-bold my-2'>Loaded</span>
        } else {
          return <span className='badge badge-light-success fs-8 fw-bold my-2'>Delivered</span>
        }
      }
    },
    {
      name: 'DATE',
      selector: row => moment(row.attributes.date).format('MM/DD/YYYY'),
      sortable: true,
      sortField: 'date',
      width: "120px"
    },
    {
      name: 'AMOUNT',
      selector: row => row.attributes.total_freight_amount,
      format: row => row.attributes.total_freight_amount.toLocaleString('en-US', { minimumFractionDigits: 2 }),
      sortable: true,
      width: "120px"
    },
    {
      name: 'TERMS',
      width: "120px",
      selector: row => row.attributes.charge_to,
      cell: row => {
        if (row.attributes.charge_to === 1) {
          return (
            <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-7'>Collect</span>
          )
        } else if (row.attributes.charge_to === 2) {
          return (
            <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-7'>Prepaid</span>
          )
        } else {
          return (
            <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-7'>Account</span>
          )
        }
      }
    },
    {
      name: 'REMOVE',
      width: '80px',
      button: true,
      cell: row => (
        <a onClick={() => deleteWaybill(row.attributes.waybill_no, row.id, row.attributes.manifest_id)} href='#'
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'>
          <KTSVG
            path='/media/icons/duotune/general/gen027.svg'
            className='svg-icon-3'
          />
        </a>
      )
    }
  ];


  return (
    <>
      <Formik validationSchema={currentSchema}
        initialValues={manifest!}
        onSubmit={submit}
        enableReinitialize={true}
        validateOnChange={true}>
        {(prop) => (
          <div className={`card ${className}`}>
            <SaveWaybill loading={loading} valid={invalidManifest} />
            <div className='car-body'>
              <Form className='row sg-4 p-10' id='kt_create_waybill_form'>
                <div className="row">
                  <div className='col-md-3'>
                    <label className='d-flex align-items-center form-label-sm fw-bold'>
                      <span className='required'>Manifest No</span>
                    </label>
                    <NumericFormat
                      maxLength={10}
                      name='manifest_no'
                      placeholder='Manifest #'
                      value={prop.values.manifest_no}
                      onValueChange={(values) => {
                        onManifestNoChange(values);
                        prop.setFieldValue('manifest_no', values.value)
                      }}
                      className={clsx(
                        'form-control form-control-solid fw-bold',
                        { 'is-invalid': !invalidManifest },
                        {
                          'is-valid': invalidManifest,
                        }
                      )} />
                    <div className='text-danger mtc-2'>
                      <ErrorMessage name='manifest_no' />
                    </div>
                    <div className='form-text'>
                      Manifest identification
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <label className='d-flex align-items-center form-label-sm fw-bold'>
                      <span className='required'>Departure Date</span>
                    </label>
                    <DatePicker name='departure_date'
                      required
                      selected={prop.values.departure_date}
                      onChange={(e: Date) => {
                        if (e === null) { e = new Date() }
                        setDepartureDateChange(e)
                        prop.setFieldValue('departure_date', e)
                      }}
                      dateFormat={'MM/dd/yyy'}
                      className='form-control form-control-solid fw-bold' />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='departure_date' />
                    </div>

                    <div className='form-text'>
                      Departure Date
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <label className='d-flex align-items-center form-label-sm fw-bold'>
                      <span className='required'>Arrival Date</span>
                    </label>
                    <DatePicker name='arrival_date'
                      required
                      selected={prop.values.arrival_date}
                      onChange={(e: Date) => {
                        if (e === null) { e = new Date() }
                        setArrivalDateChange(e)
                        prop.setFieldValue('arrival_date', e)
                      }}
                      dateFormat={'MM/dd/yyy'}
                      className='form-control form-control-solid fw-bold' />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='arrival_date' />
                    </div>

                    <div className='form-text'>
                      Arrival Date
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <label className='d-flex align-items-center form-label-sm fw-bold'>
                      <span className='required'>Status</span>
                    </label>
                    <Field
                      as='select'
                      name='status_id'
                      value={prop.values.status_id}
                      onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                        onStatusChange(e)
                      }}
                      className='form-select form-select-solid fw-bold'>
                      <option value='1'>Warehouse</option>
                      <option value='5'>Loaded</option>
                      <option value='2'>In Transit</option>
                      <option value='3'>Arrived</option>
                      <option value='4'>Delivered</option>
                    </Field>
                    <div className='form-text'>
                      Status of the manifest
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className='col-md-3 mt-5'>
                    <div className=''>
                      <label className='d-flex align-items-center form-label-sm fw-bold'>
                        <span className='required'>Route</span>
                      </label>
                      <Field
                        required
                        maxLength={240}
                        type='text'
                        name='description'
                        value={prop.values.description}
                        className='form-control fw-bold'
                        onChange={onTextChange}
                      />
                      <div className='text-danger mtc-2'>
                        <ErrorMessage name='description' />
                      </div>

                      <div className='form-text'>
                        Route
                      </div>

                    </div>
                  </div>
                  <div className='col-md-3 mt-5'>
                    <div className=''>
                      <label className='d-flex align-items-center form-label-sm fw-bold'>
                        <span className=''>Destination</span>
                      </label>
                      <Field
                        maxLength={120}
                        type='text'
                        name='destination'
                        value={prop.values.destination}
                        className='form-control fw-bold'
                        onChange={onTextChange}
                      />
                      <div className='text-danger mtc-2'>
                        <ErrorMessage name='destination' />
                      </div>
                      <div className='form-text'>
                        Destination
                      </div>

                    </div>
                  </div>
                  <div className='col-md-3 mt-5'>
                    <div className=''>
                      <label className='d-flex align-items-center form-label-sm fw-bold'>
                        <span className=''>Driver's Name</span>
                      </label>
                      <Field
                        maxLength={120}
                        type='text'
                        name='driver'
                        value={prop.values.driver}
                        className='form-control fw-bold'
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          onTextChange(e)
                          prop.setFieldValue(e.currentTarget.name, e.currentTarget.value)
                        }}
                      />
                      <div className='text-danger mtc-2'>
                        <ErrorMessage name='driver' />
                      </div>
                      <div className='form-text'>
                        Driver's name
                      </div>

                    </div>
                  </div>
                  <div className='col-md-3 mt-5'>
                    <label className='d-flex align-items-center form-label-sm fw-bold'>
                      <span className='required'>Cargo Truck</span>
                    </label>
                    <Select
                      value={truckOptions.filter(item => item.value === manifest.truck_id)}
                      name='truck_id'
                      isClearable
                      className='fw-bold'
                      isSearchable
                      onChange={(e) => {
                        onCargoSelected(e?.value!)
                      }}
                      options={truckOptions} />
                    <div className='text-danger mtc-2'>
                      <ErrorMessage name='truck_id' />
                    </div>
                    <div className='form-text'>
                      Cargo trucks from Aguileon and Sub-Trucker
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className='col-md-3 mt-5'>
                    <div className=''>
                      <label className='d-flex align-items-center form-label-sm fw-bold'>
                        <span className='required'>Percentage</span>
                      </label>
                      <NumericFormat
                        maxLength={3}
                        name='percentage'
                        placeholder='Percentage'
                        value={prop.values.percentage}
                        onValueChange={(values) => {
                          onPercentageChange(values);
                          prop.setFieldValue('percentage', values.value)
                        }}
                        className='form-control fw-bold' />
                      <div className='text-danger mtc-2'>
                        <ErrorMessage name='Percentage' />
                      </div>

                      <div className='form-text'>
                        Percentage
                      </div>

                    </div>
                  </div>
                  <div className='col-md-6 mt-5 d-flex'>
                    <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                      <div className="d-flex align-items-center">
                        <div className="fs-2 fw-bold" data-kt-countup="true" data-kt-countup-value="6800" data-kt-countup-prefix="$">{totalCollect.toLocaleString()}</div>
                      </div>
                      <div className="fw-semibold fs-6 ">Total Collect</div>
                    </div>
                    <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                      <div className="d-flex align-items-center">
                        <div className="fs-2 fw-bold" data-kt-countup="true" data-kt-countup-value="6800" data-kt-countup-prefix="$">{totalAccount.toLocaleString()}</div>
                      </div>
                      <div className="fw-semibold fs-6 ">Total Account</div>
                    </div>
                    <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                      <div className="d-flex align-items-center">
                        <div className="fs-2 fw-bold" data-kt-countup="true" data-kt-countup-value="6800" data-kt-countup-prefix="$">{totalPrepaid.toLocaleString()}</div>
                      </div>
                      <div className="fw-semibold fs-6 ">Total Prepaid</div>
                    </div>
                  </div>
                </div>
              </Form>
            </div >
          </div >
        )}
      </Formik >
      <DataTable columns={columns}
        data={waybills}
        striped
        selectableRowsHighlight
        pointerOnHover
        progressPending={loading}
        customStyles={customStyles}
        subHeader
        subHeaderComponent={<SelectComponent waybill={waybillData} load={loadWaybills} select={onSelectedWaybill} />}
        className='table-responsive' />
    </>
  )
}

export { ManifestDetails }

type SaveProps = {
  loading: boolean
  valid: boolean
}

const SaveWaybill: React.FC<SaveProps> = ({ loading, valid }) => {
  const { id } = useParams() as { id: string }
  const { submitForm, validateForm } = useFormikContext();
  const handleSave = () => {
    validateForm().then((result) => {
      console.log(result);
    })
    submitForm();
  }
  return (
    <div className='card-header border-0 pt-5'>
      <h3 className='card-title align-items-start flex-column'>
        <span className='card-label fw-bold fs-3 mb-1'>Manifest Details</span>
        <span className='text-muted mt-1 fw-semibold fs-7'>Help?</span>
      </h3>
      <div className='card-toolbar' style={{ width: 200 }}>
        <div className='d-flex flex-row' style={{ justifyContent: 'space-between' }}>
          <button type='submit' disabled={!valid} onClick={() => handleSave()}
            className='btn btn-success' style={{ marginRight: 10 }}>
            <i className="bi bi-save"> </i>
            {!loading && <span className='indicator-label'>Save</span>}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <a href={`/section/manifests/print/${id}`} className='btn btn-light-primary'>
            <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-3' />
            Print
          </a>
        </div>
      </div>
    </div>
  )
}