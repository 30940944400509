import axios from 'axios'
import {
  ReportMonthlyData,
} from '../../../model/models'

const API_URL = process.env.REACT_APP_API_URL

export function getMonthlyReport(charge: number, status: number, month: number, year: number, branch: any) {
  return axios.get<ReportMonthlyData>(
    `${API_URL}/report/getMonthlyReport/?charge=${charge}&status=${status}&branch=${branch}&month=${month}&year=${year}`
  )
}

