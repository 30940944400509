import { Page, Text, View, Document, StyleSheet, Font } from '@react-pdf/renderer';
import { useEffect, useState } from 'react';
import { getWaybillById, getWaybillItems } from '../redux/WaybillsApi';
import { WaybillItemModel, WaybillModel } from '../../../model/models';
import moment from 'moment';
import { waybill } from './helpers/WaybillDetailsHelper';

Font.register({
    family: 'Libre',
    src: 'https://fonts.gstatic.com/s/librebaskerville/v4/pR0sBQVcY0JZc_ciXjFsKyyZRYCSvpCzQKuMWnP5NDY.ttf'
});

const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
    },
    shipperSection: {
        marginTop: 35,
        marginLeft: 45,
        fontSize: '6px',
        width: 110,
        flex: 3,
        fontFamily: 'Libre'
    },
    consigneeSection: {
        marginTop: 35,
        marginLeft: 10,
        marginRight: 10,
        width: 110,
        fontSize: '6px',
        fontFamily: 'Libre'
    },

    dateSection: {
        marginTop: 35,
        marginRight: 5,
        fontSize: '6px',
        textAlign: 'right',
        width: 170,
        fontFamily: 'Libre'
    },
    viewRowFlexDirection: {
        flexDirection: 'row',
        justifyContent: 'flex-end',

    }

});

type Props = {
    id: number
}

const WaybillPrint: React.FC<Props> = ({ id }) => {
    const [waybillData, setWaybillData] = useState<WaybillModel>(waybill)
    const [waybillItems, setWaybillItems] = useState<WaybillItemModel[]>([])
   

    useEffect(() => {
        const loadWaybill = () => {
            getWaybillById(id).then((response) => {
                const waybill = response.data.data.attributes as WaybillModel;
                setWaybillData(waybill)
                console.log(waybill)
            })
        }
        const loadWaybillItems = () => {
            getWaybillItems(id).then((result) => {
                const items = result.data.data.map((item) => {
                    item.attributes.id = item.id
                    return item.attributes
                })
                setWaybillItems(items)
                console.log(items)
            })
        }
        loadWaybillItems()
        loadWaybill()
    }, [id])
    return (
        <Document>
            <Page size={[460.8, 576]} orientation='portrait'>
                <View style={styles.viewRowFlexDirection}>
                    <View style={styles.shipperSection}>
                        <Text style={{ marginBottom: 1, fontSize: '7px' }}>{waybillData.shipper_name}</Text>
                        <Text style={{ marginBottom: 1 }}>{waybillData.shipper_contact_no}</Text>
                        <Text style={{ marginBottom: 1 }}>{waybillData.shipper_address}</Text>
                    </View>
                    <View style={styles.consigneeSection}>
                        <Text style={{ marginBottom: 1, fontSize: '7px', }}>{waybillData.consignee}</Text>
                        <Text style={{ marginBottom: 1 }}>{waybillData.mobile}</Text>
                        <Text style={{ marginBottom: 1, paddingRight: 20 }}>{waybillData.address}</Text>
                    </View>
                    <View style={styles.dateSection}>

                        <Text style={{ marginBottom: 5 }}>{moment(waybillData.date).format('DD MMM yyyy hh:mm A')}</Text>
                        <Text>{waybillData.reference}</Text>
                    </View>
                </View>

                <View style={{ flexDirection: 'row', justifyContent: 'flex-end', height: 170 }}>
                    <View style={{ marginTop: 25, fontSize: '6px', fontFamily: 'Libre'}}>
                        {
                            waybillItems.map((item, index) => {
                                return (

                                    <View key={index} style={{ flexDirection: 'row', marginBottom: 1, justifyContent: 'space-between'}}>
                                        <View style={{ width: 30, paddingRight: 8, textAlign: 'right'}}><Text>{item.quantity}</Text></View>
                                        <View style={{ marginLeft: 2, paddingLeft: 5, width: 190, textAlign: 'left'}}><Text>{item.description}</Text></View>
                                        <View style={{ marginLeft: 2, width: 15, textAlign: 'left'}}><Text>{item.weight === 0? '' : item.weight}</Text></View>
                                        <View style={{ marginLeft: 2, width: 40, textAlign: 'right'}}><Text>{item.freight_amount?.toLocaleString('en-PH', { minimumFractionDigits: 2 })}</Text></View>
                                    </View>

                                )
                            })

                        }
                    </View>
                    <View style={{
                        fontSize: '10px',
                        fontWeight: 'bold',
                        marginTop: 25,
                        width: 130,
                        paddingTop: 40,
                        paddingRight: 5
                    }}>
                        <Text style={{ textAlign: 'right' }}>{waybillData.total_freight_amount.toLocaleString('en-PH', { minimumFractionDigits: 2 })}</Text>
                    </View>
                </View>

                <View style={{ flexDirection: 'row', marginTop: -8 }} wrap>
                    <View style={{ fontSize: '7px', fontFamily: 'Libre', width: 50 }}>  
                        <Text style={{textAlign: 'right'}}>Total Qty</Text>
                      
                    </View>
                    <View style={{ fontSize: '7px', fontFamily: 'Libre', paddingLeft: 15, width: 60}}>  
                    <Text> {waybillItems.reduce((a, v) => a = a + (v.quantity === undefined ? 0 : v.quantity), 0).toLocaleString()}</Text>
                    </View>
                    <View style={{fontSize: '10px', fontWeight: 'bold', fontFamily: 'Libre', width: 100 }}>
                        {
                            (waybillData.charge_to === 1 && <Text>COLLECT</Text>)
                            ||
                            (waybillData.charge_to === 2 && <Text>PREPAID</Text>)
                            ||
                            (waybillData.charge_to === 3 && <Text>ACCOUNT</Text>)
                        }
                    </View >

                </View>


            </Page>
        </Document>
    )
}
export { WaybillPrint }