import React, {FC} from 'react'
import { WaybillView } from './WaybillView'

const WaybillViewPage: FC = () => {
  return (
    <>
      <WaybillView className='mb-5 mb-xl-8' />
    </>
  )
}

export {WaybillViewPage}
