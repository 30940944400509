import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import { AppUserConfig } from '../../model/type'


const initialConfig: AppUserConfig = {
  branchId: undefined
}

export const callTypes = {
  update: 'update'
}

export const userConfigSlice = createSlice({
  name: 'config',
  initialState: initialConfig,
  reducers: {
    update: (state: AppUserConfig, action: PayloadAction<AppUserConfig>) => {
      const branchId = action.payload.branchId
      return {branchId}
    }
  },
})
  
export const {
  update
} = userConfigSlice.actions


export default userConfigSlice.reducer
