import axios from 'axios'
import {
  AccountData,
  AccountModel,
  AccountResponseData,
  CustomerData,
  Customers,
  Meta,
} from '../../../model/models'

const API_URL = process.env.REACT_APP_API_URL

export function getAccounts(customer_id: number, status: number, page: number, pageSize: number, branch: any) {
  if(customer_id > 0) {
    if(status >= 0) {
      return axios.get<AccountResponseData>(
        `${API_URL}/accounts/?filters[customer_id][$eq]=${customer_id}&filters[status][$eq]=${status}&filters[branch][$eq]=${branch}&populate=*&pagination[page]=${page}&pagination[pagesize]=${pageSize}&sort[0]=createdAt:desc`
      )
    } else {
    return axios.get<AccountResponseData>(
      `${API_URL}/accounts/?filters[customer_id][$eq]=${customer_id}&populate=*&filters[branch][$eq]=${branch}&pagination[page]=${page}&pagination[pagesize]=${pageSize}&sort[0]=createdAt:desc`
    )}
  } else {
    if(status >= 0) {
      return axios.get<AccountResponseData>(
        `${API_URL}/accounts/?filters[status][$eq]=${status}&populate=*&filters[branch][$eq]=${branch}&pagination[page]=${page}&pagination[pagesize]=${pageSize}&sort[0]=createdAt:desc`
      )
    } else {
      return axios.get<AccountResponseData>(
        `${API_URL}/accounts/?populate=*&pagination[page]=${page}&pagination[pagesize]=${pageSize}&filters[branch][$eq]=${branch}&sort[0]=createdAt:desc`
      )
    }
  }

}

export function getAccountById(id: number) {
  return axios.get<{data: AccountData, meta: Meta}>(
    `${API_URL}/accounts/${id}`
  )
}

export function getAccountByNo(accountNo: number) {
  return axios.get<AccountResponseData>(
    `${API_URL}/accounts/?filters[account_no][$eq]=${accountNo}`
  )
}

export function getCustomers() {
  return axios.get<Customers>(
    `${API_URL}/customers?populate=*&pagination[page]=1&pagination[pageSize]=200`
  )
}

export function getCustomerById(id: number) {
  return axios.get<{data: CustomerData[]}>(
    `${API_URL}/customers/?filters[customer_id][$eq]=${id}`
  )
}

export function saveSOA(soa: AccountModel) {
  return axios.post<{data: AccountData, meta: Meta}>(`${API_URL}/accounts`, {data: soa})
}

export function updateSOA(id: number, dataToUpdate: any) {
  return axios.put<{data: AccountData, meta: Meta}>(`${API_URL}/accounts/${id}`, {data: dataToUpdate})
}

