import { PDFViewer, Page, StyleSheet, View, Document, Font, Text, Image } from '@react-pdf/renderer';
import moment from 'moment';
import React, { FC, useEffect, useState } from 'react'
import DatePicker from "react-datepicker";
import { WaybillResponseData, Waybills } from '../../../model/models';
import { getWaybillsByDateRange } from '../../waybills/redux/WaybillsApi';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../../../setup';

Font.register({
  family: 'Libre',
  src: 'https://fonts.gstatic.com/s/librebaskerville/v4/pR0sBQVcY0JZc_ciXjFsKyyZRYCSvpCzQKuMWnP5NDY.ttf'
});

const styles = StyleSheet.create({
  viewer: {
    width: '80%',
    height: '75%'
  }
});

const WaybillReportPage: FC = () => {
  const branch = useSelector<RootState>(({ configs }) => configs.branchId, shallowEqual) as string
  const [startDate, setStartDate] = useState(new Date())
  const [status, setStatus] = useState('0')
  const [payment, setPayment] = useState('0')
  const [waybills, setWaybills] = useState<WaybillResponseData[]>([])
  const [totalCollect, setTotalCollect] = useState(0)
  const [totalAccount, setTotalAccount] = useState(0)
  const [totalPrepaid, setTotalPrepaid] = useState(0)
  useEffect(() => {
    const getWaybills = () => {
      getWaybillsByDateRange(moment(startDate).format('YYYY-MM-DD 00:01'), moment(startDate).format('YYYY-MM-DD 23:59'), branch).then((response) => {
        if (response.status === 200) {
          if (status !== '0') {
            if (payment !== '0') {
              const paymentStatusData = response.data.data.filter((waybill) => {
                return waybill.attributes.charge_to === Number(payment) && waybill.attributes.status === Number(status)
              })
              setWaybills(paymentStatusData)
              compute(paymentStatusData)
              console.log(paymentStatusData)
            } else {
              const statusData = response.data.data.filter((waybill) => {
                return waybill.attributes.status === Number(status)
              })
              setWaybills(statusData)
              compute(statusData)
              console.log(statusData)
            }           
          } else {
            if (payment !== '0') {
              const paymentData = response.data.data.filter((waybill) => {
                return waybill.attributes.charge_to === Number(payment)
              })
              setWaybills(paymentData)
              compute(paymentData)
              console.log(paymentData)
            } else {
              setWaybills(response.data.data)
              compute(response.data.data)
              console.log(response.data.data)
            }
          }
        }
      })
    }
    if (moment(startDate).isValid()) {
      getWaybills()
    }

  }, [payment, status, startDate])

  const onDateChange = (e: Date) => {
    setStartDate(e)
  }

  const onStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setStatus(e.currentTarget.value)
  }

  const onPaymentChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setPayment(e.currentTarget.value)
  }

  const compute = (data: WaybillResponseData[]) => {
    if (waybills.length > 0) {
      const collectWaybills = data.filter(items => items.attributes.charge_to === 1);
      setTotalCollect(collectWaybills.reduce((a, v) => a = a + (v.attributes.total_freight_amount === undefined ? 0 : v.attributes.total_freight_amount), 0))

      const prepaidWaybills = data.filter(items => items.attributes.charge_to === 2);
      setTotalPrepaid(prepaidWaybills.reduce((a, v) => a = a + (v.attributes.total_freight_amount === undefined ? 0 : v.attributes.total_freight_amount), 0))

      const accountWaybills = data.filter(items => items.attributes.charge_to === 3);
      setTotalAccount(accountWaybills.reduce((a, v) => a = a + (v.attributes.total_freight_amount === undefined ? 0 : v.attributes.total_freight_amount), 0))
    }
  }

  return (
    <>
      <div className="card card-custom">
        <div className="card-header">
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Waybills Daily Report</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>Help?</span>
          </h3>
        </div>
        <div className="card-body">
          <form>
            <div className='row'>
              <div className='col-md-3'>
                <label className='d-flex align-items-center form-label-sm fw-bold'>
                  <span className='required'>Date</span>
                </label>
                <DatePicker name='date'
                  value={moment(startDate).format('MM/DD/YYYY')}
                  isClearable
                  required
                  onChange={onDateChange}
                  className='form-control form-control-solid fw-bold' />
                {/* <DatePicker name='date'
                  selectsRange
                  startDate={startDate}
                  endDate={endDate}
                  isClearable
                  required
                  onChange={(e) => {
                    onDateChange(e)
                  }}
                  className='form-control form-control-solid fw-bold' /> */}
                <div className='form-text'>
                  Waybill Date
                </div>
              </div>
              <div className='col-md-3'>
                <label className='d-flex align-items-center form-label-sm fw-bold'>
                  <span className='required'>Charge To</span>
                </label>

                <select
                  name='charge_to'
                  value={payment}
                  onChange={onPaymentChange}
                  className='form-select form-control-solid form-select-solid fw-bold' >
                  <option value='0'>All</option>
                  <option value='1'>Collect</option>
                  <option value='2'>Prepaid</option>
                  <option value='3'>Account</option>
                </select>
                <div className='form-text'>
                  Client payment options
                </div>
              </div>
              <div className='col-md-3'>
                <label className='d-flex align-items-center form-label-sm fw-bold'>
                  <span className='required'>Status</span>
                </label>
                <select

                  name='status'
                  value={status}
                  onChange={onStatusChange}
                  className='form-select form-control-solid form-select-solid fw-bold'>
                  <option value='0'>All</option>
                  <option value='1'>Warehouse</option>
                  <option value='5'>Loaded</option>
                  <option value='2'>In Transit</option>
                  <option value='3'>Arrived</option>
                  <option value='4'>Delivered</option>
                </select>
                <div className='form-text'>
                  Status of the shipment
                </div>
              </div>
              {/* <div className='col-md-3'>
                <a href="#" className="btn btn-primary" onClick={clickPrint}>
                  <span className="svg-icon svg-icon-1"><KTSVG path='/media/icons/duotune/graphs/gra001.svg' />
                  </span>Print</a>
              </div> */}
            </div>
          </form>
        </div>
      </div>
      <PDFViewer style={styles.viewer} >
        <Document pageLayout='twoPageRight'>
          <Page size={[612.0, 792.0]} orientation='portrait' wrap >

            <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
              <Image style={{ width: 200, height: 50, marginTop: 10 }} src='/media/logos/Aguileon_Logo.png' />
            </View>
            <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
              <Text style={{ fontFamily: 'Libre', fontSize: 7 }}>1426 Geliños St. Brgy. 342, Zone 34, District III, Sta. Cruz, Manila</Text>
            </View>
            <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
              <Text style={{ fontFamily: 'Libre', fontSize: 10, marginTop: 10 }}>Waybills Daily Report {moment(startDate).format('MM/DD/YYYY')}</Text>
            </View>
            <View style={{ flexDirection: 'row', justifyContent: 'center', marginTop: 5, fontFamily: 'Libre', borderTop: .5, borderBottom: .5 }}>
              <View style={{ flexDirection: 'row', width: 120, margin: 5, paddingTop: 5 }}>
                <Text style={{ fontSize: 8 }}>Total Collect: PHP  </Text>
                <Text style={{ fontSize: 8, fontWeight: 'ultrabold' }}>{totalCollect.toLocaleString()}</Text>
              </View>
              <View style={{ flexDirection: 'row', width: 120, margin: 5, fontFamily: 'Libre', paddingTop: 5 }}>
                <Text style={{ fontSize: 8 }}>Total Account: PHP  </Text>
                <Text style={{ fontSize: 8, fontWeight: 'ultrabold' }}>{totalAccount.toLocaleString()}</Text>
              </View>
              <View style={{ flexDirection: 'row', width: 120, margin: 5, fontFamily: 'Libre', paddingTop: 5 }}>
                <Text style={{ fontSize: 8 }}>Total Prepaid: PHP  </Text>
                <Text style={{ fontSize: 8, fontWeight: 'ultrabold' }}>{totalPrepaid.toLocaleString()}</Text>
              </View>
            </View>
            <View fixed style={{
              flexDirection: 'row',
              justifyContent: 'space-evenly',
              marginTop: 5,
              marginBottom: 5,
              fontFamily: 'Libre',
              marginHorizontal: 20,
              paddingBottom: 5
            }}>
              <View style={{ flexDirection: 'row', width: 60, paddingTop: 5 }}>
                <Text style={{ fontSize: 8 }}>Waybill #  </Text>
              </View>
              <View style={{ flexDirection: 'row', width: 170, fontFamily: 'Libre', paddingTop: 5 }}>
                <Text style={{ fontSize: 8 }}>Shipper </Text>
              </View>
              <View style={{ flexDirection: 'row', width: 170, fontFamily: 'Libre', paddingTop: 5 }}>
                <Text style={{ fontSize: 8 }}>Consignee </Text>
              </View>
              <View style={{ flexDirection: 'row', width: 50, fontFamily: 'Libre', paddingTop: 5 }}>
                <Text style={{ fontSize: 8 }}>Date  </Text>
              </View>
              <View style={{ flexDirection: 'row', width: 80, fontFamily: 'Libre', paddingTop: 5 }}>
                <Text style={{ fontSize: 8, textAlign: 'center' }}> Freight Amount (PHP) </Text>
              </View>
              <View style={{ flexDirection: 'row', width: 80, fontFamily: 'Libre', paddingTop: 5 }}>
                <Text style={{ fontSize: 8 }}>Payment Terms</Text>
              </View>
            </View>
            <View>
              {
                waybills.map((waybill, index) => {
                  return <View key={index} 
                               style={{ flexDirection: 'row', 
                                        justifyContent: 'flex-start', 
                                        marginTop: 5, 
                                        fontFamily: 'Libre', 
                                        marginHorizontal: 10, 
                                        borderBottom: .5, paddingBottom: 2 }}>
                    <Text style={{ fontSize: 7, width: 60 }}>{index + 1}.    {waybill.attributes.waybill_no}</Text>
                    <Text style={{ fontSize: 7, width: 160, paddingRight: 5 }}>{waybill.attributes.shipper_name}</Text>
                    <Text style={{ fontSize: 7, width: 160, paddingRight: 10 }}>{waybill.attributes.consignee}</Text>
                    <Text style={{ fontSize: 7, width: 60, paddingRight: 5 }}>{moment(waybill.attributes.date).format('MM/DD/YYYY')}</Text>
                    <Text style={{ fontSize: 7, width: 60, paddingRight: 5, marginRight: 25, textAlign: 'right' }}>{waybill.attributes.total_freight_amount?.toLocaleString()} </Text>
                    {
                      (waybill.attributes.charge_to === 1 && <Text style={{ fontSize: 6 }}>COLLECT</Text>)
                      ||
                      (waybill.attributes.charge_to === 2 && <Text style={{ fontSize: 6 }}>PREPAID</Text>)
                      ||
                      (waybill.attributes.charge_to === 3 && <Text style={{ fontSize: 6 }}>ACCOUNT</Text>)
                    }
                  </View>
                })
              }
            </View>
            <View fixed style={{ flexDirection: 'row', justifyContent: 'center', marginVertical: 10 }} >
              <Text style={{ fontSize: 7, paddingVertical: 5 }} render={({ pageNumber, totalPages }) => (
                `Pages ${pageNumber} / ${totalPages}`
              )} />
            </View>

          </Page>
        </Document>
      </PDFViewer>
    </>
  )
}

export { WaybillReportPage }
