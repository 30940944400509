import React from 'react'
import { Navigate, Route, Routes, Outlet } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { WaybillReportPage } from './components/WaybillReportPage'
import { MonthlyReportPage } from './components/MonthlyReportPage'

const reportsBreadCrumbs: Array<PageLink> = [
  {
    title: 'Reports',
    path: '/section/reports/waybills',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ReportPage: React.FC = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>

        <Route
          path='daily'
          element={
            <>
              <PageTitle breadcrumbs={reportsBreadCrumbs}>Waybills Daily</PageTitle>
              <WaybillReportPage />
            </>
          }
        />

        <Route
          path='monthly'
          element={
            <>
              <PageTitle breadcrumbs={reportsBreadCrumbs}>Monthly Report</PageTitle>
              <MonthlyReportPage />
            </>
          }
        />

        <Route index element={<Navigate to='/section/reports/daily' />} />
      </Route>
    </Routes>
  )
}
export default ReportPage
